import { TextField } from "@mui/material";
import RegisterCard from "../../../../../../components/RegisterCard";
import * as C from "../../style";
import * as D from "../../../../../../styles/appComponents";
import { ICaptador } from "../../../../../../models/Captador";

interface IResponsaveisLegais {
  captador: ICaptador;
}

const ResponsaveisLegais: React.FC<IResponsaveisLegais> = ({ captador }) => {
  return (
    <RegisterCard title="Informações do responsavel legal">
      {captador.responsaveisLegais.map((responsavelLegal, index) => (
        <C.DivChild key={`respLeg-${responsavelLegal.id}`}>
          <C.FWStack
            direction={"row"}
            spacing={2}
            marginTop={index > 0 ? "30px" : "none"}
          >
            <TextField
              value={responsavelLegal.nome}
              label="Nome do responsável legal"
              fullWidth
              inputProps={{ readOnly: true }}
            />
            <TextField
              value={responsavelLegal.email}
              label="E-mail"
              fullWidth
              inputProps={{ readOnly: true }}
            />
            <TextField
              value={responsavelLegal.celular}
              label="Celular"
              fullWidth
              inputProps={{ readOnly: true }}
            />
            <TextField
              value={responsavelLegal.cpf}
              label="CPF"
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              value={responsavelLegal.cep}
              label="CEP"
              fullWidth
              inputProps={{ readOnly: true }}
            />
            <TextField
              value={responsavelLegal.uf}
              label="UF"
              sx={{ width: "40%" }}
              inputProps={{ readOnly: true }}
            />
            <TextField
              value={responsavelLegal.cidade}
              label="Cidade"
              fullWidth
              inputProps={{ readOnly: true }}
            />
            <TextField
              value={responsavelLegal.bairro}
              label="Bairro"
              fullWidth
              inputProps={{ readOnly: true }}
            />
            <TextField
              value={responsavelLegal.numero}
              label="Número"
              sx={{ width: "40%" }}
              inputProps={{ readOnly: true }}
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              value={responsavelLegal.complemento}
              label="Complemento"
              fullWidth
              inputProps={{ readOnly: true }}
            />
            <TextField
              value={responsavelLegal.logradouro}
              label="Logradouro"
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </C.FWStack>
        </C.DivChild>
      ))}
      <C.TitleArea>
        <h2>Responsáveis pela comunicação</h2>
      </C.TitleArea>
      <D.FWStack spacing={2} direction={"row"}>
        <TextField
          value={captador.responsavelComunicacao?.nome}
          label="Nome"
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={captador.responsavelComunicacao?.email}
          label="Email"
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={captador.responsavelComunicacao?.celular}
          label="Celular"
          fullWidth
          inputProps={{ readOnly: true }}
        />
      </D.FWStack>
    </RegisterCard>
  );
};

export default ResponsaveisLegais;
