import {
  ICaptadorConfig,
  ICreateCaptadorConfig,
  IUpdateCaptadorConfig,
} from "../../models/CaptadorConfig";
import http from "../common/http-common";

export const GetCaptadorConfigByUrl = (url: string) => {
  return http.get<ICaptadorConfig>(`/CaptadorConfig/${url}`);
};

export const GetCaptadorConfigByLeadId = (leadId: string) => {
  return http.get<ICaptadorConfig>(`/CaptadorConfig/GetConfigByLead/${leadId}`);
};

export const VerifiyCaptadorConfigExists = (idCaptador: string) => {
  return http.get<boolean>(
    `/CaptadorConfig/VerifyCaptadorConfigExists/${idCaptador}`
  );
};

export const GetCaptadorConfigByIdCaptador = (idCaptador: string) => {
  return http.get<ICaptadorConfig>(
    `/CaptadorConfig/GetConfigByIdCaptador/${idCaptador}`
  );
};

export const CreateCaptadorConfig = (
  createCaptadorConfig: ICreateCaptadorConfig
) => {
  const formData = new FormData();

  formData.append("IdCaptador", createCaptadorConfig.idCaptador);
  formData.append("Celular", createCaptadorConfig.celular);
  formData.append("CorPrimaria", createCaptadorConfig.corPrimaria);
  formData.append("CorSecundaria", createCaptadorConfig.corSecundaria);
  formData.append("CorTextoBase", createCaptadorConfig.corTextoBase);
  formData.append("CorTextoDestaque", createCaptadorConfig.corTextoDestaque);

  if (createCaptadorConfig.textoBoasVindas != null) {
    formData.append("TextoBoasVindas", createCaptadorConfig.textoBoasVindas);
  }

  formData.append(
    "TextoAgradecimento",
    createCaptadorConfig.textoAgradecimento
  );

  if (createCaptadorConfig.textoAnexarConta != null) {
    formData.append("TextoAnexarConta", createCaptadorConfig.textoAnexarConta);
  }

  formData.append(
    "TextoAgradecimentoAnexarConta",
    createCaptadorConfig.textoAgradecimentoAnexarConta
  );

  formData.append(
    "TemPixelFacebook",
    createCaptadorConfig.temPixelFacebook.toString()
  );

  if (createCaptadorConfig.pixelFacebook != null) {
    formData.append("PixelFacebook", createCaptadorConfig.pixelFacebook);
  }

  formData.append(
    "TemImagemComoConteudo",
    createCaptadorConfig.temImagemComoConteudo.toString()
  );

  formData.append("Logo", createCaptadorConfig.logo);

  if (createCaptadorConfig.background != null) {
    formData.append("Background", createCaptadorConfig.background);
  }

  if (createCaptadorConfig.contentImage != null) {
    formData.append("ContentImage", createCaptadorConfig.contentImage);
  }

  return http.post<ICreateCaptadorConfig>(`/CaptadorConfig/`, formData);
};

export const UpdateCaptadorConfig = (
  updateCaptadorConfig: IUpdateCaptadorConfig
) => {
  const formData = new FormData();

  formData.append("CaptadorConfigId", updateCaptadorConfig.captadorConfigId);

  if (updateCaptadorConfig.celular != null) {
    formData.append("Celular", updateCaptadorConfig.celular);
  }

  if (updateCaptadorConfig.corPrimaria != null) {
    formData.append("CorPrimaria", updateCaptadorConfig.corPrimaria);
  }

  if (updateCaptadorConfig.corSecundaria != null) {
    formData.append("CorSecundaria", updateCaptadorConfig.corSecundaria);
  }

  if (updateCaptadorConfig.corTextoBase != null) {
    formData.append("CorTextoBase", updateCaptadorConfig.corTextoBase);
  }

  if (updateCaptadorConfig.corTextoDestaque != null) {
    formData.append("CorTextoDestaque", updateCaptadorConfig.corTextoDestaque);
  }

  if (updateCaptadorConfig.textoBoasVindas != null) {
    formData.append("TextoBoasVindas", updateCaptadorConfig.textoBoasVindas);
  }

  if (updateCaptadorConfig.textoAgradecimento != null) {
    formData.append(
      "TextoAgradecimento",
      updateCaptadorConfig.textoAgradecimento
    );
  }

  if (updateCaptadorConfig.textoAnexarConta != null) {
    formData.append("TextoAnexarConta", updateCaptadorConfig.textoAnexarConta);
  }

  if (updateCaptadorConfig.textoAgradecimentoAnexarConta != null) {
    formData.append(
      "TextoAgradecimentoAnexarConta",
      updateCaptadorConfig.textoAgradecimentoAnexarConta
    );
  }

  if (updateCaptadorConfig.temPixelFacebook != null) {
    formData.append(
      "TemPixelFacebook",
      updateCaptadorConfig.temPixelFacebook.toString()
    );
  }

  if (updateCaptadorConfig.pixelFacebook != null) {
    formData.append("PixelFacebook", updateCaptadorConfig.pixelFacebook);
  }

  if (updateCaptadorConfig.temImagemComoConteudo != null) {
    formData.append(
      "TemImagemComoConteudo",
      updateCaptadorConfig.temImagemComoConteudo.toString()
    );
  }

  if (updateCaptadorConfig.logo != null) {
    formData.append("Logo", updateCaptadorConfig.logo);
  }

  if (updateCaptadorConfig.background != null) {
    formData.append("Background", updateCaptadorConfig.background);
  }

  if (updateCaptadorConfig.contentImage != null) {
    formData.append("ContentImage", updateCaptadorConfig.contentImage);
  }

  return http.put<IUpdateCaptadorConfig>(`/CaptadorConfig/`, formData);
};
