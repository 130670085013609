import {MenuItem, TextField} from "@mui/material";
import RegisterCard from "../../../../../../../../components/RegisterCard";
import * as D from "../../../../../../../../styles/appComponents";
import React, {useEffect, useReducer} from "react";
import {EnumTipoConexao} from "../../../../../../../../enums/EnumTipoConexao.enum";

enum formInformacoesProposta {
  PARCEIRO = "PARCEIRO",
  APOIOPARCEIRO = "APOIOPARCEIRO",
  BACKOFFICE = "BACKOFFICE",
  COMISSAOCAPTADOR = "COMISSAOCAPTADOR"
}

export interface IformInformacoesProposta {
  parceiro: String;
  apoioparceiro: String;
  backoffice: String;
  comissaocaptador: String;
}

type PropsInformacoesProposta = {
  type: string;
  data?: IformInformacoesProposta;
  onSave: (data: IformInformacoesProposta) => void;
  onUpdate: (data: IformInformacoesProposta) => void;
  onChange: (data: IformInformacoesProposta) => void;
}

const InformacoesProposta = (props: PropsInformacoesProposta) => {
  const sendOnSave = (): void => props.onSave(state);
  const sendOnUpdate = (): void => props.onUpdate(state);
  const sendOnChange = (update: IformInformacoesProposta): void => props.onChange(update);

  const {type, data} = props;

  const setDataForm = (state: IformInformacoesProposta, action: {
    type: formInformacoesProposta;
    payload: string | number | Date;
  }): IformInformacoesProposta => {
    const change = {...state, [action.type]: action.payload};
    sendOnChange(change);
    return change;
  };

  const setData = (data: IformInformacoesProposta): void => {

  }

  const [state, dispatch] = useReducer(setDataForm, {
    parceiro: "",
    apoioparceiro: "",
    backoffice: "",
    comissaocaptador: "",
  })

  useEffect((): void => {
    if (data) {
      setData(data);
    }
  }, [data]);

  return (
    <RegisterCard title="Informações do contrato">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Parceiro"
          required
          fullWidth
          value={state.parceiro}
          onChange={(e) =>
            dispatch({
              type: formInformacoesProposta.PARCEIRO,
              payload: e.target.value,
            })
          }
        />
        <TextField
          label="Apoio parceiro"
          required
          fullWidth
          value={state.apoioparceiro}
          onChange={(e) =>
            dispatch({
              type: formInformacoesProposta.APOIOPARCEIRO,
              payload: e.target.value,
            })
          }
        />
        <TextField
          label="Backoffice"
          required
          fullWidth
          value={state.backoffice}
          onChange={(e) =>
            dispatch({
              type: formInformacoesProposta.BACKOFFICE,
              payload: e.target.value,
            })
          }
        />
        <TextField
          label="Comissão captador"
          required
          fullWidth
          value={state.comissaocaptador}
          onChange={(e) =>
            dispatch({
              type: formInformacoesProposta.COMISSAOCAPTADOR,
              payload: e.target.value,
            })
          }
        />
      </D.FWStack>
      {props.data !== null && props.data !== undefined ? (
        <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
          <D.ContainedButton onClick={sendOnSave}>Salvar</D.ContainedButton>
        </D.FWStack>
      ) : null}
    </RegisterCard>
  );
};

export default InformacoesProposta;