import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import RegisterCard from "../../../../../components/RegisterCard";
import * as C from "./style";
import * as D from "../../../../../styles/appComponents";
import CircleCheckbox from "../../../../../components/CircleCheckBox";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const CadastroCliente = () => {
  const [extraContacts, setExtraContacts] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const [showLogin, setShowLogin] = useState(false);

  const handleClickShowLogin = () => setShowLogin((show) => !show);

  const handleMouseDownLogin = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  return (
    <>

    </>
  );
};

export default CadastroCliente;
