import {Modal} from "@mui/material";
import * as C from "./style";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

type PropsDefaultModal = {
  children: string | JSX.Element | JSX.Element[]
  openModal: boolean;
  handleCloseModal: () => void;
};

const DefaultModal = (props: PropsDefaultModal) => {
  const navigate = useNavigate();
  const [openDefaultModal, setOpenModal] = useState<boolean>(false);

  let {openModal, handleCloseModal, children} = props;

  useEffect(() => {
    setOpenModal(openModal);
  }, [openModal]);

  return (
    <>
      <Modal
        sx={{ width: "100%", zIndex: 10 }}
        open={openModal}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <C.ModalContainer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <C.ModalArea
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
              padding: "20px",
            }}
          >
            <C.ModalHeader style={{ width: "100%", justifyContent: "flex-end" }}>
              <C.CloseButton
                aria-label="close"
                onClick={() => {
                  handleCloseModal();
                  setOpenModal(false);
                }}
              >
                <CloseIcon />
              </C.CloseButton>
            </C.ModalHeader>
            {children}
          </C.ModalArea>
        </C.ModalContainer>
      </Modal>
    </>
  );
};

export default DefaultModal;
