import styled from "styled-components";

export const Container = styled.div<{ $readonly: boolean }>`
  width: 100%;
  height: auto;
  display: flex;
  padding: 30px;
  cursor: ${(props) => (props.$readonly ? "default" : "pointer")};
`;

export const Left = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: start;
  align-items: center;
`;

export const FolderCard = styled.div`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: #f4f4f4;
  width: 50%;
  height: auto;
  aspect-ratio: 1/1;
  padding: 40px;

  img {
    width: 100%;
  }

  span {
    color: #262626;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
`;

export const Right = styled.div`
  width: 40%;
  border-radius: 10px;
  display: flex;
  width: 100%;
  aspect-ratio: 16/9;
  background-color: #d9d9d9;

  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    border-radius: inherit;
  }
`;

export const VisuallyHiddenInput = styled.input`
  display: none;
`;
