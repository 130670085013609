export enum EnumTipoConexao {
  Monofasico = "Monofasico",
  Bifasico = "Bifasico",
  Trifasico = "Trifasico",
}

export const EnumTipoConexaoNumericMap = {
  [EnumTipoConexao.Monofasico]: 0,
  [EnumTipoConexao.Bifasico]: 1,
  [EnumTipoConexao.Trifasico]: 2,
};

export const EnumTipoConexaoValueMap = {
  0: "30",
  1: "50",
  2: "100",
};
