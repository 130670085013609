import React, { useEffect, useState } from "react";
import { DadosCooperativa, IFormDadosCooperativa } from "./Components/DadosCooperativa";
import { ConselhoFiscal, IFormConselhoFiscal } from "./Components/ConselhoFiscal";
import { IFormResponsavelOemg, ResponsavelOemg } from "./Components/ResponsavelOemg";
import { IFormInformacoesContrato, InformacoesContrato } from "./Components/InformacoesContrato";
import { AnexoDocumentos, IAnexoDocumentos } from "./Components/AnexoDocumentos";
import { DadosBancarios, IFormDadosBancarios } from "./Components/DadosBancarios";
import * as C from "./style";
import Swal from "sweetalert2";
import * as services from "../../../../../services/api/CooperativaService";
import { ICooperativa } from "../../../../../models/Cooperativa";
import {useNavigate} from "react-router-dom";

const CadastroCooperativa: React.FC = () => {
  const [dadosCooperativa, setDadosCooperativa] = useState<IFormDadosCooperativa | undefined>(undefined);
  const [conselhoFiscal, setConselhoFiscal] = useState<IFormConselhoFiscal[]>([]);
  const [responsavelOemg, setResponsavelOemg] = useState<IFormResponsavelOemg | undefined>(undefined);
  const [informacoesContrato, setInformacoesContrato] = useState<IFormInformacoesContrato | undefined>(undefined);
  const [anexoDocumentos, setAnexoDocumentos] = useState<IAnexoDocumentos | undefined>(undefined);
  const [dadosBancarios, setDadosBancarios] = useState<IFormDadosBancarios | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
  }, []);

  const onSaveDadosCooperativa = (data: IFormDadosCooperativa): void => {
    setDadosCooperativa(data);
  };

  const onSaveConselhoFiscal = (data: IFormConselhoFiscal[]): void => {
    setConselhoFiscal(data);
  };

  const onSaveResponsavelOemg = (data: IFormResponsavelOemg): void => {
    setResponsavelOemg(data);
  };

  const onSaveInformacoesContrato = (data: IFormInformacoesContrato): void => {
    setInformacoesContrato(data);
  };

  const onSaveAnexoDocumentos = (data: IAnexoDocumentos): void => {
    setAnexoDocumentos(data);
  };

  const onSaveDadosBancarios = (data: IFormDadosBancarios): void => {
    setDadosBancarios(data);
    console.log(data)
  };

  const handleValidForm = (form?: unknown): boolean => {
    if (!form) return true;

    const formValues: unknown[] = Object.values(form);
    const hasValue: boolean = formValues.some(value => value !== null && value !== undefined && value !== "");
    const allFilled: boolean = formValues.every(value => value !== null && value !== undefined && value !== "");

    return hasValue ? allFilled : true;
  };

  const convertToICooperativa = (
    dadosCooperativa: IFormDadosCooperativa | undefined,
    conselhoFiscal: IFormConselhoFiscal[] | undefined,
    responsavelOemg: IFormResponsavelOemg | undefined,
    informacoesContrato: IFormInformacoesContrato | undefined,
    dadosBancarios: IFormDadosBancarios | undefined
  ): ICooperativa => {
    return {
      nome: dadosCooperativa?.nome || "",
      cnpj: dadosCooperativa?.cnpj || null,
      presidente: dadosCooperativa?.presidente || null,
      cpfPresidente: dadosCooperativa?.cpfpresidente || null,
      diretorAdministrativo: dadosCooperativa?.diretoradministrativo || null,
      cpfDiretorAdministrativo: dadosCooperativa?.cpfdiretoradministrativo || null,
      diretorTecnico: dadosCooperativa?.diretortecnico || null,
      cpfDiretorTecnico: dadosCooperativa?.cpfdiretortecnico || null,
      dataMandatoInicio: dadosCooperativa?.datamandatoinicio || null,
      dataMandatoFim: dadosCooperativa?.datamandatofim || null,
      conselhoFiscal: conselhoFiscal || [],
      responsavelOcemg: responsavelOemg?.responsavelocemg || null,
      emailResponsavelOcemg: responsavelOemg?.emailresponsavelocemg || null,
      celularResponsavelOcemg: responsavelOemg?.celularresponsavelocemg || null,
      limitekWh: informacoesContrato?.limiteKwh || null,
      backofficeId: informacoesContrato?.backofficeid || null,
      agencia: dadosBancarios?.agencia || null,
      banco: dadosBancarios?.banco || null,
      conta: dadosBancarios?.conta || null,
      cnpjFavorecido: dadosBancarios?.cnpjfavorecido || null,
      favorecido: dadosBancarios?.favorecido || null,
      tipoChavePix: dadosBancarios?.tipochavepix || null,
      chavePix: dadosBancarios?.chavepix || null,
    };
  };

  const setCooperativa = async (): Promise<void> => {
    const validFormChecks: boolean[] = [
      handleValidForm(dadosCooperativa),
      handleValidForm(conselhoFiscal),
      handleValidForm(responsavelOemg),
      handleValidForm(informacoesContrato),
      handleValidForm(anexoDocumentos),
      handleValidForm(dadosBancarios),
    ];

    // if (!validFormChecks.every(check => check)) {
    //   Swal.fire({
    //     position: "top-end",
    //     toast: true,
    //     icon: "warning",
    //     title: "Existem campos obrigatórios que não foram preenchidos",
    //     showConfirmButton: false,
    //     showCloseButton: true,
    //     timer: 3000,
    //   });
    //   return;
    // }

    const cooperativa: ICooperativa = convertToICooperativa(
      dadosCooperativa,
      conselhoFiscal,
      responsavelOemg,
      informacoesContrato,
      dadosBancarios
    );

    const response: any = await services.Create(cooperativa);

    if (!response) {
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "error",
        title: "Erro ao cadastrar cooperativa",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
      return;
    }

    Swal.fire({
      position: "top-end",
      toast: true,
      icon: "success",
      title: "Cooperativa cadastrada com sucesso!",
      showConfirmButton: false,
      showCloseButton: true,
      timer: 3000,
    });
    navigate(-1);
  };

  return (
    <C.Container>
      <DadosCooperativa
        onSave={onSaveDadosCooperativa}
        onUpdate={onSaveDadosCooperativa}
        onChange={onSaveDadosCooperativa}
      />
      <ConselhoFiscal
        onSave={onSaveConselhoFiscal}
        onChange={onSaveConselhoFiscal}
        onUpdate={onSaveConselhoFiscal}
      />
      <ResponsavelOemg
        onSave={onSaveResponsavelOemg}
        onChange={onSaveResponsavelOemg}
        onUpdate={onSaveResponsavelOemg}
      />
      <InformacoesContrato
        onSave={onSaveInformacoesContrato}
        onChange={onSaveInformacoesContrato}
        onUpdate={onSaveInformacoesContrato}
      />
      <AnexoDocumentos
        onSave={onSaveAnexoDocumentos}
        onUpdate={onSaveAnexoDocumentos}
        onChange={onSaveAnexoDocumentos}
      />
      <DadosBancarios
        onSave={onSaveDadosBancarios}
        onChange={onSaveDadosBancarios}
        onUpdate={onSaveDadosBancarios}
      />
      <C.FWStack justifyContent={"flex-end"} direction={"row"} spacing={2}>
        <C.ContainedButton $color="lightpink" onClick={setCooperativa}>
          Gravar cooperativa
        </C.ContainedButton>
      </C.FWStack>
    </C.Container>
  );
};

export default CadastroCooperativa;
