export enum EnumTipoClasse {
  Residencial = "Residencial",
  Comercial = "Comercial",
  Outros = "Outros",
}

export const EnumTipoClasseNumericMap = {
  [EnumTipoClasse.Residencial]: 0,
  [EnumTipoClasse.Comercial]: 1,
  [EnumTipoClasse.Outros]: 2,
};
