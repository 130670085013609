import styled from "styled-components";

export const ColorPicker = styled.div<{ $color: string }>`
  border-radius: 5px;
  background-color: ${(props) => props.$color};
  height: 1.5em;
  aspect-ratio: 1/1;
  cursor: pointer;
  position: relative;
  border: 1px solid #e3e3e3;
`;

export const VisuallyHiddenInput = styled.input`
  visibility: hidden;
  position: absolute;
  width: 0;
  top: 10;
  left: 0;
`;
