import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    tertiary: Palette["primary"];
    quaternary: Palette["primary"];
  }
  interface PaletteOptions {
    tertiary?: PaletteOptions["primary"];
    quaternary?: PaletteOptions["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#D24293",
    },
    secondary: {
      main: "#F6A648",
    },
    tertiary: {
      main: "#F36A7B",
    },
    quaternary: {
      main: "#144FE7",
    },
  },
});

export default theme;
