import {Box, Checkbox, MenuItem, TextField, Typography} from "@mui/material";
import RegisterCard from "../../../../../../../../components/RegisterCard";
import * as D from "../../../../../../../../styles/appComponents";
import React, {useReducer} from "react";
import * as G from "../../../../../../Colaborador/Colaborador/Cadastro/style";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {CheckboxGroup, ReportsArea} from '../../../style';
import {IformPreferencia} from "../../../../../../../../models/Contratar";

enum formInformacoesOutrasInformacoes {
  WHATSAPP = "WHATSAPP",
  TRESDIAS = "TRESDIAS",
  SETEDIAS = "SETEDIAS",
  GERACAO = "GERACAO",
  VENCIMENTO = "VENCIMENTO",
  LISTAEMAILPARARECEBER = "LISTAEMAILPARARECEBER",
  PREFERENCIADEDIASEMRECEBIMENTOFATURA = "PREFERENCIADEDIASEMRECEBIMENTOFATURA",
  POSSUIGERACAOENERGIAPROPRIA = "POSSUIGERACAOENERGIAPROPRIA",
  OBEDECEFRANQUIA = "OBEDECEFRANQUIA",
  PREFERENCIATITULARIDADEFATURA = "PREFERENCIATITULARIDADEFATURA"
}

type PropsPreferenciaContratar = {
  type: string;
}

const PreferenciaContratar = (props: PropsPreferenciaContratar) => {
  const setDataForm = (state: IformPreferencia, action: { type: formInformacoesOutrasInformacoes; payload: any; }) => {
    const {type, payload} = action;
    switch (type) {
      case formInformacoesOutrasInformacoes.WHATSAPP:
        return {
          ...state,
          whatsapp: payload,
        }
      case formInformacoesOutrasInformacoes.TRESDIAS:
        return {
          ...state,
          tresdias: payload,
        }
      case formInformacoesOutrasInformacoes.SETEDIAS:
        return {
          ...state,
          setedias: payload,
        }
      case formInformacoesOutrasInformacoes.GERACAO:
        return {
          ...state,
          geracao: payload,
        }
      case formInformacoesOutrasInformacoes.VENCIMENTO:
        return {
          ...state,
          vencimento: payload
        }
      case formInformacoesOutrasInformacoes.LISTAEMAILPARARECEBER:
        return {
          ...state,
          listaemailparareceber: payload
        }
      case formInformacoesOutrasInformacoes.PREFERENCIADEDIASEMRECEBIMENTOFATURA:
        return {
          ...state,
          preferenciadediasemrecebimentofatura: payload
        }
      case formInformacoesOutrasInformacoes.POSSUIGERACAOENERGIAPROPRIA:
        return {
          ...state,
          possuigeracaoenergiapropria: payload
        }
      case formInformacoesOutrasInformacoes.OBEDECEFRANQUIA:
        return {
          ...state,
          obedecefranquia: payload
        }
      case formInformacoesOutrasInformacoes.PREFERENCIATITULARIDADEFATURA:
        return {
          ...state,
          preferenciatitularidadefatura: payload
        }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(setDataForm, {
    whatsapp: "",
    tresdias: "",
    setedias: "",
    geracao: "",
    vencimento: "",
    listaemailparareceber: "",
    preferenciadediasemrecebimentofatura: "",
    possuigeracaoenergiapropria: "",
    obedecefranquia: "",
    preferenciatitularidadefatura: "",
  })

  return (
    <RegisterCard title="Preferência">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField label="Whatsapp" required onChange={(e) => dispatch({
          type: formInformacoesOutrasInformacoes.WHATSAPP,
          payload: e.target.value
        })}/>

        <CheckboxGroup>
          <Box>
            <G.CheckboxWrapper>
              <Checkbox
                onChange={(e) => dispatch({type: formInformacoesOutrasInformacoes.TRESDIAS, payload: e.target.value})}/>
              <p>3 dias</p>
            </G.CheckboxWrapper>
            <G.CheckboxWrapper>
              <Checkbox
                onChange={(e) => dispatch({type: formInformacoesOutrasInformacoes.SETEDIAS, payload: e.target.value})}/>
              <p>7 dias</p>
            </G.CheckboxWrapper>
          </Box>

          <Box>
            <G.CheckboxWrapper>
              <Checkbox
                onChange={(e) => dispatch({type: formInformacoesOutrasInformacoes.GERACAO, payload: e.target.value})}/>
              <p>Geração</p>
            </G.CheckboxWrapper>
            <G.CheckboxWrapper>
              <Checkbox onChange={(e) => dispatch({
                type: formInformacoesOutrasInformacoes.VENCIMENTO,
                payload: e.target.value
              })}/>
              <p>Vencimento</p>
            </G.CheckboxWrapper>
          </Box>
        </CheckboxGroup>
      </D.FWStack>

      <D.FWStack direction={"row"} spacing={2}>
        <TextField label="LIsta de email para recebimento de faturas" required onChange={(e) => dispatch({
          type: formInformacoesOutrasInformacoes.LISTAEMAILPARARECEBER,
          payload: e.target.value
        })}
        sx={{width: '30%'}}
        />
        <D.ContainedButton $color="lightpink"
                           startIcon={<AddCircleOutlineOutlinedIcon/>}>
          Adicionar mais email
        </D.ContainedButton>
      </D.FWStack>

      <D.FWStack direction={"row"} spacing={2}>
        <ReportsArea>
          <Box>
            <Typography
              style={{
                whiteSpace: "normal",
                fontSize: 14,
              }}
            >
              Preferência de dia de recebimento fatura?
            </Typography>
            <G.CheckboxWrapper>
              <Checkbox onChange={(e) => dispatch({
                type: formInformacoesOutrasInformacoes.PREFERENCIADEDIASEMRECEBIMENTOFATURA,
                payload: e.target.value
              })}/>
              <p>Sim</p>
            </G.CheckboxWrapper>
            <G.CheckboxWrapper>
              <Checkbox onChange={(e) => dispatch({
                type: formInformacoesOutrasInformacoes.PREFERENCIADEDIASEMRECEBIMENTOFATURA,
                payload: e.target.value
              })}/>
              <p>Não</p>
            </G.CheckboxWrapper>
          </Box>

          <Box>
            <Typography
              style={{
                whiteSpace: "normal",
                fontSize: 14,
              }}
            >
              Possui geração de energia própria
            </Typography>
            <G.CheckboxWrapper>
              <Checkbox onChange={(e) => dispatch({
                type: formInformacoesOutrasInformacoes.POSSUIGERACAOENERGIAPROPRIA,
                payload: e.target.value
              })}/>
              <p>Sim</p>
            </G.CheckboxWrapper>
            <G.CheckboxWrapper>
              <Checkbox onChange={(e) => dispatch({
                type: formInformacoesOutrasInformacoes.POSSUIGERACAOENERGIAPROPRIA,
                payload: e.target.value
              })}/>
              <p>Não</p>
            </G.CheckboxWrapper>
          </Box>

          <Box>
            <Typography
              style={{
                whiteSpace: "normal",
                fontSize: 14,
              }}
            >
              Obedece a franquia
            </Typography>
            <G.CheckboxWrapper>
              <Checkbox onChange={(e) => dispatch({
                type: formInformacoesOutrasInformacoes.OBEDECEFRANQUIA,
                payload: e.target.value
              })}/>
              <p>Sim</p>
            </G.CheckboxWrapper>
            <G.CheckboxWrapper>
              <Checkbox onChange={(e) => dispatch({
                type: formInformacoesOutrasInformacoes.OBEDECEFRANQUIA,
                payload: e.target.value
              })}/>
              <p>Não</p>
            </G.CheckboxWrapper>
          </Box>

          <Box>
            <Typography
              style={{
                whiteSpace: "normal",
                fontSize: 14,
              }}
            >
              Preferência titularidade fatura
            </Typography>
            <G.CheckboxWrapper>
              <Checkbox onChange={(e) => dispatch({
                type: formInformacoesOutrasInformacoes.PREFERENCIATITULARIDADEFATURA,
                payload: e.target.value
              })}/>
              <p>Sim</p>
            </G.CheckboxWrapper>
            <G.CheckboxWrapper>
              <Checkbox onChange={(e) => dispatch({
                type: formInformacoesOutrasInformacoes.PREFERENCIATITULARIDADEFATURA,
                payload: e.target.value
              })}/>
              <p>Não</p>
            </G.CheckboxWrapper>
          </Box>
        </ReportsArea>
      </D.FWStack>
    </RegisterCard>
  );
};

export default PreferenciaContratar;
