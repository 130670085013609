export enum EnumConvenio {
  wattwise = "WattWise",
}

export const EnumConvenioNumericMap = {
  [EnumConvenio.wattwise]: 0,
};

export const ConvenioNumberMap: {
  [key: number]: EnumConvenio;
} = {
  0: EnumConvenio.wattwise,
};
