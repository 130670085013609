import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import {
  Checkbox,
  InputAdornment,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import {ptBR} from "@mui/x-data-grid/locales";
import React, {useEffect, useState} from "react";
import ExcelIcon from "../../../../assets/Plataforma/excelIcon.svg";
import StatusCard from "../../../../components/StatusCard";
import TotalCard from "../../../../components/TotalCard";
import * as D from "../../../../styles/appComponents";
import * as C from "./style";
import financePaper from "../../../../assets/Plataforma/financePaper.svg";
import faturaIcon from "../../../../assets/Plataforma/faturaIcon.svg";

const ParceiroComissao: React.FC = () => {
  const [page, setPage] = useState(1);
  const [statusOpen, setStatusOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleStatusOpen = () => {
    setStatusOpen(!statusOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      align: "center",
      renderHeader: (params) => (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Checkbox
            inputProps={{'aria-label': 'Select all rows'}}
          />
        </div>
      ),
      renderCell: (params) => (
        <D.GridField>
          <Checkbox
            inputProps={{'aria-label': 'Select all rows'}}
          />
        </D.GridField>
      ),
    },
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "parceiro",
      headerName: "Parceiro",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.Circle color={params.value}/>
        </D.GridField>
      ),
    },
    {field: "totalComissão", headerName: "Total comissão", flex: 1},
    {
      field: "notaFiscal",
      headerName: "Nota fiscal",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.GridImage
            src={financePaper}
            alt="financePaper"
          />
        </D.GridField>
      ),
    },
    {
      field: "faturas",
      headerName: "Faturas",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.GridImage
            src={faturaIcon}
            alt="financePaper"
          />
        </D.GridField>
      ),
    },
    {
      field: "comprovante",
      headerName: "Comprovante",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.GridImage
            src={faturaIcon}
            alt="financePaper"
          />
        </D.GridField>
      ),
    },
  ];

  const rows: any[] = [];

  return (
    <>
      <C.Container>
        <C.Title onClick={() => handleStatusOpen()}>
          Status
          {isMobile && (
            <>
              {!statusOpen ? (
                <KeyboardArrowDownRoundedIcon/>
              ) : (
                <KeyboardArrowUpIcon/>
              )}
            </>
          )}
        </C.Title>

        {(!isMobile || (statusOpen && isMobile)) && (
          <C.StatusArea>
            <C.StatusWrapper >
              <StatusCard
                color="yellow"
                text="Aguardando nota fiscal"
                number={0}
              />
              <TotalCard
                color="yellow"
                title="Saldo comissão"
                text="R$: 200.500,00"
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="yellow"
                title="Data limite para envio da nota fiscal"
                text="00/00/00"
                evolutionNumber="+8%"
                evolution={true}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard color="blue" text="Aguardando pagamento" number={0}/>
              <TotalCard
                color="blue"
                title="Período de apuração das comissões"
                text="00/00/00 00/00/00"
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="blue"
                title="Data de previsão do pagamento"
                text="00/00/00"
                evolutionNumber="+8%"
                evolution={true}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard
                color="green"
                text="Quitado"
                number={0}
              />
              <TotalCard
                style={{
                  flex: "0",
                }}
                color="green"
                title="Data de fechamento prevista das comissões"
                text="00/00/00"
                evolutionNumber="-8%"
                evolution={false}
              />
            </C.StatusWrapper>
          </C.StatusArea>
        )}

        <D.DataArea $align="right">
          <D.DefaultSearchInput
            size="small"
            variant="outlined"
            onChange={() => null}
            placeholder="Pesquisar"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon/>
                </InputAdornment>
              ),
            }}
          />
          {!isMobile ? (
            <D.DefaultTable
              disableColumnResize
              disableRowSelectionOnClick
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={rows}
              columns={columns}
              hideFooter
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
            />
          ) : (
            <C.CardDataAreaMobile>
              {rows.map((row) => (
                <C.CardDataMobile key={row.id}>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile>
                      <label>Data:</label>
                      <p>{row.id}</p>
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile></C.CardDataItemMobile>
                    <C.CardDataItemMobile $center={true}>
                      <label>Status:</label>
                      <D.Circle color={"blue"}/>
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile>
                      <label>Parceiro:</label>
                      <p>{row.titular}</p>
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile>
                      <label>Total comissão:</label>
                      <p>{row.parceiro}</p>
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile>
                      <label>Nota fiscal:</label>
                      <p>{row.instalacao}</p>
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile>
                      <label>Faturas:</label>
                      <p>{row.data}</p>
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile $center={true}>
                      <label>Comprovante:</label>
                      <D.DefaultGridButton>
                        Ver mais
                      </D.DefaultGridButton>
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                </C.CardDataMobile>
              ))}
            </C.CardDataAreaMobile>
          )}

          <D.DefaultPagination
            count={10}
            size={isMobile ? "small" : "medium"}
            siblingCount={isMobile ? 0 : 1}
            showFirstButton
            showLastButton
            onChange={handleChange}
            color="primary"
          />
        </D.DataArea>
      </C.Container>
    </>
  );
};

export default ParceiroComissao;
