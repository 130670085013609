import { ColorType } from "../../types/color";
import * as C from "./style";

interface CircleCheckboxProps {
  id: number;
  selected: boolean;
  label: string;
  onSelect: (id: number) => void;
  color: ColorType;
}

const CircleCheckbox: React.FC<CircleCheckboxProps> = ({
  id,
  selected,
  label,
  onSelect,
  color,
}) => {
  return (
    <C.Wrapper onClick={() => onSelect(id)}>
      <C.Circle color={color} selected={selected} />
      {label}
    </C.Wrapper>
  );
};

export default CircleCheckbox;
