import { Chip, Stack, TextField } from "@mui/material";
import RegisterCard from "../../../../../components/RegisterCard";
import * as C from "./style";
import * as D from "../../../../../styles/appComponents";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import DownloadIcon from "@mui/icons-material/Download";
import { ptBR } from "@mui/x-data-grid/locales";
import CircleCheckbox from "../../../../../components/CircleCheckBox";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import File from "../../../../../assets/Icones/arquivoAnexado.svg";
import EmptyFile from "../../../../../assets/Icones/arquivoVazio.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import QRCode from "../../../../../assets/qrcode.png";

const CadastroParceiro = () => {
  const [extraContacts, setExtraContacts] = useState(1);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
    },
    { field: "comissao", headerName: "% da comissão", width: 150 },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    { field: "telefone", headerName: "Telefone", width: 150 },
    {
      field: "status",
      headerName: "Status do contrato",
      flex: 1,
    },
  ];

  const rows = [
    {
      id: 9594,
      nome: "José Luiz Almeida",
      comissao: "0,5",
      email: "exemplo@exemplo.com",
      telefone: "(37) 984563123",
      status: "Aguardando assinatura do contrato",
    },
    {
      id: 9595,
      nome: "José Luiz Almeida",
      comissao: "0,5",
      email: "exemplo@exemplo.com",
      telefone: "(37) 984563123",
      status: "Aguardando assinatura do contrato",
    },
    {
      id: 9596,
      nome: "José Luiz Almeida",
      comissao: "0,5",
      email: "exemplo@exemplo.com",
      telefone: "(37) 984563123",
      status: "Aguardando assinatura do contrato",
    },
    {
      id: 9597,
      nome: "José Luiz Almeida",
      comissao: "0,5",
      email: "exemplo@exemplo.com",
      telefone: "(37) 984563123",
      status: "Aguardando assinatura do contrato",
    },
    {
      id: 9598,
      nome: "José Luiz Almeida",
      comissao: "0,5",
      email: "exemplo@exemplo.com",
      telefone: "(37) 984563123",
      status: "Aguardando assinatura do contrato",
    },
    {
      id: 9599,
      nome: "José Luiz Almeida",
      comissao: "0,5",
      email: "exemplo@exemplo.com",
      telefone: "(37) 984563123",
      status: "Aguardando assinatura do contrato",
    },
  ];

  const columns2: GridColDef[] = [
    {
      field: "responsavel",
      headerName: "Responsável",
      flex: 1,
    },
    { field: "desc", headerName: "Decrição", flex: 1 },
    {
      field: "data",
      headerName: "Data Upload",
      flex: 1,
    },
    {
      field: "visualizar",
      headerName: "Visualizar",
      // flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.OutlineButton
            sx={{ fontSize: "10px" }}
            startIcon={<VisibilityIcon />}
            $color="pink"
          >
            Abrir
          </D.OutlineButton>
        </D.GridField>
      ),
    },
    {
      field: "anexo",
      headerName: "Anexo",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.OutlineButton
            sx={{ fontSize: "10px" }}
            startIcon={<DownloadIcon />}
            $color="blue"
          >
            Baixar
          </D.OutlineButton>
        </D.GridField>
      ),
    },
    {
      field: "remover",
      headerName: "Remover",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.OutlineButton
            sx={{ fontSize: "10px" }}
            startIcon={<DeleteIcon />}
            $color="red"
          >
            Deletar
          </D.OutlineButton>
        </D.GridField>
      ),
    },
  ];

  const rows2 = [
    {
      id: 1,
      responsavel: "Parceiro",
      desc: "Logo SVG",
      data: "01/01/2024",
    },
    {
      id: 2,
      responsavel: "Parceiro",
      desc: "Logo PNG",
      data: "01/01/2024",
    },
    {
      id: 3,
      responsavel: "Parceiro",
      desc: "Logo PDF",
      data: "01/01/2024",
    },
  ];

  return (
    <>
      <RegisterCard title="Dados do contrato">
        <C.FWStack direction={"row"} spacing={2}>
          <TextField label="Razão social" required fullWidth />
          <TextField label="CNPJ" required fullWidth />
          <TextField label="CEP" required fullWidth />
          <TextField label="UF" required />
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2}>
          <TextField label="Cidade" required fullWidth />
          <TextField label="Bairro" required fullWidth />
          <TextField label="Logradouro" required fullWidth />
          <TextField label="Número" required sx={{ width: "40%" }} />
          <TextField label="Complemento" required fullWidth />
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
          <D.ContainedButton>Salvar</D.ContainedButton>
        </C.FWStack>
      </RegisterCard>
      <RegisterCard title="Informações do responsavel legal">
        {Array.from({ length: extraContacts }).map((_, index) => (
          <>
            <C.FWStack
              direction={"row"}
              spacing={2}
              key={index}
              marginTop={index > 0 ? "30px" : "none"}
            >
              <TextField label="Nome do responsável legal" required fullWidth />
              <TextField label="E-mail" required fullWidth />
              <TextField label="Celular" required fullWidth />
              <TextField label="CPF" required fullWidth />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2} key={index}>
              <TextField label="CEP" required fullWidth />
              <TextField label="UF" required sx={{ width: "40%" }} />
              <TextField label="Cidade" required fullWidth />
              <TextField label="CPF" required fullWidth />
              <TextField label="Bairro" required fullWidth />
              <TextField label="Número" required sx={{ width: "40%" }} />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2} key={index}>
              <TextField label="Complemento" required fullWidth />
              <TextField label="Logradouro" required fullWidth />
            </C.FWStack>
          </>
        ))}
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton
            startIcon={<AddIcon />}
            onClick={() => setExtraContacts(extraContacts + 1)}
            $color="lightpink"
          >
            Adicionar outra forma de contato
          </D.ContainedButton>
        </C.FWStack>
        <C.TitleArea>
          <h2>Responsável pela comunicação</h2>
        </C.TitleArea>
        <C.FWStack direction={"row"} spacing={2}>
          <TextField label="Nome" required fullWidth />
          <TextField label="Email" required fullWidth />
          <TextField label="Celular" required fullWidth />
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
          <D.ContainedButton>Salvar</D.ContainedButton>
        </C.FWStack>
      </RegisterCard>
      <RegisterCard title="Comissão e dados para pagamentos">
        <C.FWStack direction={"row"} spacing={2}>
          <TextField label="Porcentual de comissão" required fullWidth />
          <TextField label="Comissão por extenso" required fullWidth />
          <TextField label="Vigência do contrato" required fullWidth />
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2}>
          <C.Subtitle>Dados para pagamento de comissão*</C.Subtitle>
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={16}>
          <C.MWDiv>
            <C.Subtitle>Dados bancários*</C.Subtitle>
            <C.Text>Insira seus dados bancários</C.Text>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField label="Agência" required fullWidth />
              <TextField label="Conta" required fullWidth />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField label="Número" required fullWidth />
              <TextField label="Banco" required fullWidth />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField label="CNPJ do banco" required fullWidth />
              <C.FWDiv></C.FWDiv>
            </C.FWStack>
          </C.MWDiv>
          <C.MWDiv>
            <C.Subtitle>Pix*</C.Subtitle>
            <C.Text>Insira sua chave pix</C.Text>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField label="Chave PIX" required fullWidth />
            </C.FWStack>
            <C.Text>Qual dado você quer usar?</C.Text>
            <C.FWStack direction={"row"} spacing={2}>
              <Chip label="CPF" />
              <Chip label="CNPJ" />
              <Chip label="Email" />
              <Chip label="Celular" />
              <Chip label="Aleatória" />
            </C.FWStack>
          </C.MWDiv>
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
          <D.ContainedButton>Salvar</D.ContainedButton>
        </C.FWStack>
      </RegisterCard>
      <RegisterCard title="Informações do parceiro">
        <C.FWStack direction={"row"} spacing={2}>
          <TextField label="Tipo de parceiro" select required fullWidth />
          <TextField label="Parceiro master" select required fullWidth />
          <TextField label="Convênio" select required fullWidth />
          <TextField label="Backoffice" select required fullWidth />
        </C.FWStack>
        <C.BorderFWDiv>
          <C.GridTitleArea>
            <C.Subtitle>Associados</C.Subtitle>
          </C.GridTitleArea>
          <D.DefaultTable
            disableColumnResize
            disableRowSelectionOnClick
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            columns={columns}
            hideFooter
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
          />
        </C.BorderFWDiv>
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
          <D.ContainedButton>Salvar</D.ContainedButton>
        </C.FWStack>
      </RegisterCard>
      <RegisterCard title="Status do contrato">
        <C.StatusArea>
          <label>Status atual:</label>
          <Stack direction={"row"} spacing={2}>
            <CircleCheckbox
              color="yellow"
              label="Lead"
              id={1}
              selected={false}
              onSelect={() => {}}
            />
            <CircleCheckbox
              color="yellow"
              label="Aguardando assinatura de contrato"
              id={0}
              selected={false}
              onSelect={() => {}}
            />
            <CircleCheckbox
              color="yellow"
              label="Contrato assinado"
              id={2}
              selected={true}
              onSelect={() => {}}
            />
            <CircleCheckbox
              color="yellow"
              label="Aguardando assinatura de distrato"
              id={3}
              selected={false}
              onSelect={() => {}}
            />
            <CircleCheckbox
              color="yellow"
              label="Cancelado"
              id={4}
              selected={false}
              onSelect={() => {}}
            />
          </Stack>
        </C.StatusArea>
        <C.FWStack direction={"row"} spacing={2}>
          <TextField label="Última alteração de status" required fullWidth />
          <TextField
            label="Data de assinatura do contrato"
            required
            fullWidth
          />
          <TextField label="UUID do contrato" required fullWidth />
        </C.FWStack>
        <C.FWStack direction={"row"} justifyContent={"space-between"}>
          <C.FWStack direction={"row"} spacing={2}>
            <D.OutlineButton $color="blue" startIcon={<DownloadIcon />}>
              Baixar contrato
            </D.OutlineButton>
            <D.ContainedButton $color={"lightpink"}>
              Gerar contrato
            </D.ContainedButton>
            <D.ContainedButton $color={"lightpink"}>
              Gerar distrato
            </D.ContainedButton>
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
            <D.ContainedButton>Salvar</D.ContainedButton>
          </C.FWStack>
        </C.FWStack>
      </RegisterCard>
      <RegisterCard title="Anexo logo do parceiro">
        <C.FWStack direction={"row"} spacing={8}>
          <C.MWDiv>
            <C.Title>
              <FileUploadIcon />
              <h2>Upload de arquivos</h2>
            </C.Title>
            <C.FWStack direction={"row"} spacing={2}>
              <C.FWStack direction={"column"} spacing={2} textAlign={"center"}>
                <C.ArchiveArea>
                  <img src={File} alt="anexado" />
                  <p>SVG</p>
                </C.ArchiveArea>
                <text>Logo anexado</text>
              </C.FWStack>
              <C.FWStack direction={"column"} spacing={2} textAlign={"center"}>
                <C.ArchiveArea>
                  <img src={EmptyFile} alt="anexado" />
                  <p>PNG</p>
                </C.ArchiveArea>
                <text>Anexar logo</text>
              </C.FWStack>
              <C.FWStack direction={"column"} spacing={2} textAlign={"center"}>
                <C.ArchiveArea>
                  <img src={EmptyFile} alt="anexado" />
                  <p>PDF</p>
                </C.ArchiveArea>
                <text>Anexar logo</text>
              </C.FWStack>
            </C.FWStack>
          </C.MWDiv>
          <C.MWDiv>
            <C.LightBWArea>
              <D.DefaultTable
                disableColumnResize
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rows2}
                columns={columns2}
                hideFooter
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
              />
            </C.LightBWArea>
          </C.MWDiv>
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
          <D.ContainedButton>Salvar</D.ContainedButton>
        </C.FWStack>
      </RegisterCard>
      <RegisterCard title="Dados de acesso">
        <C.FWStack direction={"row"} spacing={2}>
          <TextField label="Login" required sx={{ width: "80%" }} />
          <TextField label="Senha" required sx={{ width: "80%" }} />
          <TextField
            select
            label="Cupom de boas vindas destinado para?"
            required
            fullWidth
          />
          <TextField
            select
            label="Emissor de nota fiscal?"
            required
            sx={{ width: "70%" }}
          />
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2}>
          <TextField label="Link do parceiro" required fullWidth />
          <C.FWDiv>
            <img src={QRCode} alt="qrcode" />
          </C.FWDiv>
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
          <D.ContainedButton>Salvar</D.ContainedButton>
        </C.FWStack>
      </RegisterCard>
    </>
  );
};

export default CadastroParceiro;
