import { IAnexoDTO } from "../../models/Anexo";
import {
  IAddAssociadoAnexo,
  IAssociado,
  IAssociadoListItem,
  ICreateAssociado,
} from "../../models/Associado";
import { IPaginatedList } from "../../models/PaginatedList";
import { IAssociadoStats } from "../../models/Stats";
import http from "../common/http-common";

export const GetAll = (pageNumber: number, pageSize: number) => {
  return http.get<IPaginatedList<IAssociadoListItem>>(
    `/Associado?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
};

export const CreateAssociado = (createAssociado: ICreateAssociado) => {
  return http.post("/Associado");
};

export const GetStats = () => {
  return http.get<IAssociadoStats>("/Associado/Stats");
};

export const GetById = (id: string) => {
  return http.get<IAssociado>(`/Associado/${id}`);
};

export const GetAnexosByAssociadoId = (id: string) => {
  return http.get<IAnexoDTO[]>(`/Associado/Anexos/${id}`);
};

export const AddAssociadoAnexo = (addAnexoAssociado: IAddAssociadoAnexo) => {
  const formData = new FormData();

  formData.append("descricao", addAnexoAssociado.descricao);
  formData.append("titulo", addAnexoAssociado.titulo);
  formData.append("tipoArquivo", addAnexoAssociado.tipoArquivo);
  formData.append("idAssociado", addAnexoAssociado.idAssociado);
  formData.append("file", addAnexoAssociado.file);

  return http.post<IAnexoDTO>(`/Associado/Anexos/`, formData);
};
