import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthProvider } from "../hooks/useAuth";
import LoginLayout from "../layouts/loginLayout";
import LoginCliente from "../pages/public/Cliente/LoginCliente";
import RecuperarSenhaCliente from "../pages/public/Cliente/RecuperarSenhaCliente";
import LoginColaborador from "../pages/public/Colaborador/LoginColaborador";
import RecuperarSenhaColaborador from "../pages/public/Colaborador/RecuperarSenhaColaborador";
import LoginGerador from "../pages/public/Gerador/LoginGerador";
import RecuperarSenhaGerador from "../pages/public/Gerador/RecuperarSenhaGerador";
import Home from "../pages/public/Home";
import AnexarArquivo from "../pages/public/Leads/AnexarArquivo";
import GeraLead from "../pages/public/Leads/GeraLead";
import LoginParceiro from "../pages/public/Parceiro/LoginParceiro";
import RecuperarSenhaParceiro from "../pages/public/Parceiro/RecuperarSenhaParceiro";
import ColaboradorLayout from "../layouts/colaboradorLayout";
import Dashboard from "../pages/private/Colaborador/Dashboard";
import Leads from "../pages/private/Colaborador/Leads";
import Cooperativa from "../pages/private/Colaborador/Cooperativa";
import Proposals from "../pages/private/Colaborador/Proposals";
import Contracts from "../pages/private/Colaborador/Contracts";
import Parceiro from "../pages/private/Colaborador/Parceiro";
import Colaborador from "../pages/private/Colaborador/Colaborador";
import Cliente from "../pages/private/Colaborador/Cliente";
import Gerador from "../pages/private/Colaborador/Gerador";
import LayoutNav from "../layouts/layoutNav";
import CadastroCliente from "../pages/private/Colaborador/Cliente/Cadastro";
import CadastroParceiro from "../pages/private/Colaborador/Parceiro/Cadastro";
import CadastroGerador from "../pages/private/Colaborador/Gerador/Cadastro";
import CadastroColaborador from "../pages/private/Colaborador/Colaborador/Cadastro";
import ParceiroLayout from "../layouts/parceiroLayout";
import ParceiroDashboard from "../pages/private/Parceiro/Dashboard";
import ParceiroLeads from "../pages/private/Parceiro/Leads";
import ParceiroPropostas from "../pages/private/Parceiro/Propostas";
import ParceiroContratos from "../pages/private/Parceiro/Contratos";
import ParceiroAssociados from "../pages/private/Parceiro/Associados";
import MeuCadastroParceiro from "../pages/private/Parceiro/MeuCadastro";
import ParceiroLayoutOnlyNav from "../layouts/parceiroLayout/parceiroLayoutOnlyNav";
import NovoAssociado from "../pages/private/Parceiro/Associados/NovoAssociado";
import GerarProposta from "../pages/private/Parceiro/Leads/GerarProposta";
import Contratar from "../pages/private/Parceiro/Propostas/Contratar";
import ColaboradorLayoutOnlyNav from "../layouts/colaboradorLayout/colaboradorLayoutOnlyNav";
import CadastroCooperativa from "../pages/private/Colaborador/Cooperativa/Cadastro";
import { PropostaWattwise } from "../pages/private/Parceiro/Proposta-wattwise";
import ParceiroHome from "../pages/public/Parceiro/Home";
import CadastrarParceiro from "../pages/public/Parceiro/CadastrarParceiro";
import EditarProposta from "../pages/private/Parceiro/Propostas/EditarProposta";
import ParceiroComissao from "../pages/private/Parceiro/Comissao";

const AppRoutes: React.FC = () => (
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        {/* Public */}
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" element={<Home />} />
        <Route path="/Convida/:url" element={<GeraLead />} />
        <Route path="/AnexarConta/:id" element={<AnexarArquivo />} />

        <Route path="/Login/*" element={<LoginLayout />}>
          <Route path="Parceiro" element={<LoginParceiro />} />
          <Route
            path="Parceiro/RecuperarSenha"
            element={<RecuperarSenhaParceiro />}
          />
          <Route path="Cliente" element={<LoginCliente />} />
          <Route
            path="Cliente/RecuperarSenha"
            element={<RecuperarSenhaCliente />}
          />
          <Route path="Colaborador" element={<LoginColaborador />} />
          <Route
            path="Colaborador/RecuperarSenha"
            element={<RecuperarSenhaColaborador />}
          />
          <Route path="Gerador" element={<LoginGerador />} />
          <Route
            path="Gerador/RecuperarSenha"
            element={<RecuperarSenhaGerador />}
          />
        </Route>
        {/*Landing page parceiro */}
        <Route path="/Parceiro" element={<ParceiroHome />} />
        <Route
          path="/Parceiro/CadastrarParceiro"
          element={<CadastrarParceiro />}
        />
        {/* End Public */}

        {/* Private */}
        {/*Parceiro*/}
        <Route path="/Parceiro/*" element={<ParceiroLayout />}>
          <Route path="Dashboard" element={<ParceiroDashboard />} />
          <Route path="Leads" element={<ParceiroLeads />} />
          <Route path="Propostas" element={<ParceiroPropostas />} />
          <Route path="Contratos" element={<ParceiroContratos />} />
          <Route path="Associados" element={<ParceiroAssociados />} />
          <Route path="Comissao" element={<ParceiroComissao />} />
          <Route path="MeuCadastro" element={<MeuCadastroParceiro />} />
        </Route>

        <Route path="/Parceiro/Novo/*" element={<ParceiroLayoutOnlyNav />}>
          <Route path="Associado" element={<NovoAssociado />} />
          <Route path="Proposta/:idLead" element={<GerarProposta />} />
          <Route path="Contrato/:tipo/:idProposta" element={<Contratar />} />
        </Route>

        <Route path="/Parceiro/Editar/*" element={<ParceiroLayoutOnlyNav />}>
          <Route path="Proposta/:idProposta" element={<EditarProposta />} />
        </Route>

        <Route
          path="/Parceiro/Documento/Proposta/:idProposta"
          element={<PropostaWattwise />}
        />
        {/*End Parceiro*/}

        {/* Colaborador */}
        <Route path="/Colaborador/*" element={<ColaboradorLayout />}>
          <Route path="" element={<Navigate to="/Colaborador/Dashboard" />} />
          <Route path="Dashboard" element={<Dashboard />} />
          <Route path="Comercial/GD/Lead" element={<Leads />} />
          <Route path="Comercial/GD/Cooperativa" element={<Cooperativa />} />
          <Route path="Comercial/GD/Proposta" element={<Proposals />} />
          <Route path="Comercial/GD/ContratoCliente" element={<Contracts />} />
          <Route path="Cadastro/GD/Parceiro" element={<Parceiro />} />
          <Route path="Cadastro/GD/Colaborador" element={<Colaborador />} />
          <Route path="Cadastro/GD/Cliente" element={<Cliente />} />
          <Route path="Cadastro/GD/Gerador" element={<Gerador />} />
        </Route>

        <Route
          path="/Colaborador/Comercial/GD/Novo/*"
          element={<ColaboradorLayoutOnlyNav />}
        >
          <Route path="Cooperativa" element={<CadastroCooperativa />} />
        </Route>
        {/* End Colaborador */}

        {/* Cadastro routes */}
        <Route path="/Cadastro/*" element={<LayoutNav />}>
          <Route path="Cliente" element={<CadastroCliente />} />
          <Route path="Parceiro" element={<CadastroParceiro />} />
          <Route path="Gerador" element={<CadastroGerador />} />
          <Route path="Colaborador" element={<CadastroColaborador />} />
        </Route>
        {/* End Cadastro routes */}

        {/* End Private */}
      </Routes>
    </AuthProvider>
  </BrowserRouter>
);

export default AppRoutes;
