import { TextField } from "@mui/material";
import RegisterCard from "../../../../../../components/RegisterCard";
import * as D from "../../../../../../styles/appComponents";
import { IContaDistribuidoraProposta } from "../../../../../../models/GDProposta";
import { useEffect, useState } from "react";
import { moneyFormatter } from "../../../../../../utils/moneyFormatter";

interface IContaDistribuidoraGerarPropostaProps {
  data: IContaDistribuidoraProposta;
  iluPub: string;
}

const ContaDistribuidoraGerarProposta: React.FC<
  IContaDistribuidoraGerarPropostaProps
> = ({ data, iluPub }) => {
  return (
    <RegisterCard title="O que vira na conta da distribuidora após contratação">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Taxa de disponibilidade (Kwh)"
          required
          fullWidth
          value={data.taxaDisponibilidadekWh}
          inputProps={{ readOnly: true }}
          InputProps={{
            endAdornment: data.taxaDisponibilidadekWh ? <>kWh</> : <></>,
          }}
        />
        <TextField
          label="Taxa de disponibilidade (R$)"
          required
          fullWidth
          value={
            data.taxaDisponibilidadeReais
              ? moneyFormatter.format(Number(data.taxaDisponibilidadeReais))
              : ""
          }
          inputProps={{ readOnly: true }}
        />
        <TextField
          label="Iluminação pública"
          required
          fullWidth
          inputProps={{ readOnly: true }}
          value={iluPub ? moneyFormatter.format(Number(iluPub)) : ""}
        />
        <TextField
          label="Imposto total distribuidora"
          required
          fullWidth
          value={
            data.impostoTotalDistribuidora
              ? moneyFormatter.format(Number(data.impostoTotalDistribuidora))
              : ""
          }
          inputProps={{ readOnly: true }}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Total conta distribuidora"
          required
          fullWidth
          value={
            data.totalContaDistribuidora
              ? moneyFormatter.format(Number(data.totalContaDistribuidora))
              : ""
          }
          inputProps={{ readOnly: true }}
        />
        <D.FWStack></D.FWStack>
        <D.FWStack></D.FWStack>
        <D.FWStack></D.FWStack>
      </D.FWStack>
    </RegisterCard>
  );
};

export default ContaDistribuidoraGerarProposta;
