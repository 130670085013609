import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Modal, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import React, { useEffect, useState } from "react";
import Datatable from "../../../../components/Datatable";
import StatusCard from "../../../../components/StatusCard";
import TotalCard from "../../../../components/TotalCard";
import * as D from "../../../../styles/appComponents";
import * as C from "./style";
import * as services from "../../../../services/api/GDContratoService";
import {
  IGDContratoPaged,
  IGDContratoStats,
} from "../../../../models/GDContrato";
import { EnumStatusContratoCliente } from "../../../../enums/EnumStatusContrato.enum";
import { moneyFormatter } from "../../../../utils/moneyFormatter";
import Swal from "sweetalert2";
import { AxiosError } from "axios";

const ParceiroContratos: React.FC = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [openModalSeeMore, setOpenModalSeeMore] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [rows, setRows] = useState<IGDContratoPaged[]>([]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleOpenModalSeeMore = () => {
    setOpenModalSeeMore(true);
  };

  const handleCloseModalSeeMore = () => {
    setOpenModalSeeMore(false);
  };

  const handleStatusOpen = () => {
    setStatusOpen(!statusOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getContratos();
  }, [page, pageSize, openModalSeeMore]);

  const handleSearch = (value: string) => {
    getContratos(value);
  };

  const handleChangePage = (value: number) => {
    setPage(value);
  };

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
  };

  const getContratos = async (search?: string) => {
    await getStats();

    services
      .GetContratos(page, pageSize, search)
      .then((response) => {
        setRows(response.data.data);
        setTotalPages(response.data.totalPages);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getStats = () => {
    services
      .GetStats()
      .then((response: { data: React.SetStateAction<IGDContratoStats[]> }) => {
        setStatsDashboard(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const [statsDashboard, setStatsDashboard] = useState<IGDContratoStats[]>([
    {
      status: EnumStatusContratoCliente.aguardandoAssinatura,
      quantidade: 0,
      totalReais: 0,
      totalkWh: 0,
    },
    {
      status: EnumStatusContratoCliente.processoConexao,
      quantidade: 0,
      totalReais: 0,
      totalkWh: 0,
    },
    {
      status: EnumStatusContratoCliente.conectadoAOperadora,
      quantidade: 0,
      totalReais: 0,
      totalkWh: 0,
    },
    {
      status: EnumStatusContratoCliente.inadimplente,
      quantidade: 0,
      totalReais: 0,
      totalkWh: 0,
    },
    {
      status: EnumStatusContratoCliente.desconexaoSolicitada,
      quantidade: 0,
      totalReais: 0,
      totalkWh: 0,
    },
  ]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    {
      field: "titular",
      headerName: "Titular",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "instalacao",
      headerName: "Instalação",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    { field: "parceiro", headerName: "Parceiro", flex: 1 },
    { field: "data", headerName: "Data", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.Circle color={"green"} />
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.DefaultGridButton onClick={() => handleOpenModalSeeMore()}>
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];

  const columnsModal: GridColDef[] = [
    { field: "id", headerName: "ID" },
    { field: "responsavel", headerName: "Responsavel", flex: 1 },
    { field: "descricao", headerName: "Descrição", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.Circle color={params.value} />
        </D.GridField>
      ),
    },
    { field: "data", headerName: "Data" },
  ];

  const columnsModalDocument: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "descricao", headerName: "Descrição", flex: 1 },
    { field: "data", headerName: "Data Upload", flex: 1 },
    {
      field: "anexo",
      headerName: "Anexo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <C.ModalGridDownloadButton
            startIcon={<DownloadIcon />}
            variant="outlined"
          >
            Baixar
          </C.ModalGridDownloadButton>
        </D.GridField>
      ),
    },
  ];

  const rowsModalDocument = [
    {
      id: 1,
      descricao: "Conta Cemig",
      data: "05/11/2013",
      anexo: 0,
    },
    {
      id: 2,
      descricao: "Documento Pessoal",
      data: "05/11/2013",
      anexo: 0,
    },
  ];

  return (
    <>
      <C.Container>
        <C.Title onClick={() => handleStatusOpen()}>
          Status
          {isMobile && (
            <>
              {!statusOpen ? (
                <KeyboardArrowDownRoundedIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </>
          )}
        </C.Title>

        {(!isMobile || (statusOpen && isMobile)) && (
          <C.StatusArea>
            <C.StatusWrapper>
              <StatusCard
                color="yellow"
                text="Aguardando assinatura"
                number={statsDashboard[0].quantidade}
              />
              <TotalCard
                color="yellow"
                title="Valor total de contratos"
                text={`${moneyFormatter.format(statsDashboard[0].totalReais)}`}
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="yellow"
                title="Valor total de kwh"
                text={`${statsDashboard[0].totalkWh} kwh`}
                evolutionNumber="+8%"
                evolution={false}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard
                color="blue"
                text="Em processo de conexão"
                number={statsDashboard[1].quantidade}
              />
              <TotalCard
                color="blue"
                title="Valor total de contratos"
                text={`${moneyFormatter.format(statsDashboard[1].totalReais)}`}
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="blue"
                title="Valor total de kwh"
                text={`${statsDashboard[1].totalkWh} kwh`}
                evolutionNumber="+8%"
                evolution={true}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard
                color="green"
                text="Conectado a operadora"
                number={statsDashboard[2].quantidade}
              />
              <TotalCard
                color="green"
                title="Valor total de contratos"
                text={`${moneyFormatter.format(statsDashboard[2].totalReais)}`}
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="green"
                title="Valor total de kwh"
                text={`${statsDashboard[2].totalkWh} kwh`}
                evolutionNumber="+8%"
                evolution={true}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard
                color="red"
                text="Inadimplente"
                number={statsDashboard[3].quantidade}
              />
              <TotalCard
                color="red"
                title="Valor total de contratos"
                text={`${moneyFormatter.format(statsDashboard[3].totalReais)}`}
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="red"
                title="Valor total de kwh"
                text={`${statsDashboard[3].totalkWh} kwh`}
                evolutionNumber="+8%"
                evolution={true}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard
                color="purple"
                text="Desconexão solicitada"
                number={statsDashboard[4].quantidade}
              />
              <TotalCard
                color="purple"
                title="Valor total de contratos"
                text={`${moneyFormatter.format(statsDashboard[4].totalReais)}`}
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="purple"
                title="Valor total de kwh"
                text={`${statsDashboard[4].totalkWh} kwh`}
                evolutionNumber="+8%"
                evolution={true}
              />
            </C.StatusWrapper>
          </C.StatusArea>
        )}

        <D.DataArea $align="right">
          <Datatable
            columns={columns}
            rows={rows}
            pageNumber={page}
            pageSize={pageSize}
            onChangePage={(e) => handleChangePage(e)}
            onChangePageSize={(e) => handleChangePageSize(e)}
            totalPages={totalPages}
            onSearch={handleSearch}
          />
          <C.LineBreak />
        </D.DataArea>
      </C.Container>
      <Modal
        sx={{ width: "100%" }}
        open={openModalSeeMore}
        onClose={handleCloseModalSeeMore}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <C.ModalContainer>
          <C.ModalArea>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>
                  Contrato - 9591
                  <C.ModalTextIcon>
                    <D.Circle color={"green"} />
                    <p>Contrato assinado</p>
                  </C.ModalTextIcon>
                </h2>
                <C.CloseButton
                  aria-label="close"
                  onClick={handleCloseModalSeeMore}
                >
                  <CloseIcon />
                </C.CloseButton>
              </C.ModalHeader>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Total de kwh:</label>
                  <p>523,09 kwh</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Valor:</label>
                  <p>R$ 129,40</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Data de assinatura:</label>
                  <p>03/11/2023</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Backoffice:</label>
                  <p>Rebecca de Assis</p>
                </C.ModalItem>
              </C.ModalLine>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Titular:</label>
                  <p>João Carlos Cunha Melo</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>CPF:</label>
                  <p>955.125.426.41</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Telefone:</label>
                  <p>(31) 974357087</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Email:</label>
                  <p>Email@email.com.br</p>
                </C.ModalItem>
              </C.ModalLine>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Parceiro:</label>
                  <p>GCM energia solar</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>CPF:</label>
                  <p>064.144.402.21</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Telefone:</label>
                  <p>(31) 988010912</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Email:</label>
                  <p>Email@email.com.br</p>
                </C.ModalItem>
              </C.ModalLine>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Nome da usina:</label>
                  <p>Usina</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Instalação:</label>
                  <p>3014655778</p>
                </C.ModalItem>
                <C.ModalItem></C.ModalItem>
                <C.ModalItem></C.ModalItem>
              </C.ModalLine>
            </C.ModalCard>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>Download anexo contrato</h2>
              </C.ModalHeader>
              <D.DefaultTable
                disableColumnResize
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rowsModalDocument}
                columns={columnsModalDocument}
                initialState={{
                  pagination: {
                    paginationModel: {
                      page: 0,
                      pageSize: rowsModalDocument.length,
                    },
                  },
                }}
                hideFooter
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
              />
              <C.ModalButtonsArea>
                <C.ModalDownloadContractButton
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                >
                  Download contrato
                </C.ModalDownloadContractButton>
                <C.ModalBillsButton>Ver faturas</C.ModalBillsButton>
              </C.ModalButtonsArea>
            </C.ModalCard>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>CRM</h2>
              </C.ModalHeader>
              <D.DefaultTable
                disableColumnResize
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rows}
                columns={columnsModal}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                hideFooter
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
              />
              <C.ModalPaginationArea>
                <D.DefaultPagination
                  count={10}
                  size={isMobile ? "small" : "medium"}
                  siblingCount={isMobile ? 0 : 1}
                  showFirstButton
                  showLastButton
                  onChange={handleChange}
                  color="primary"
                />
              </C.ModalPaginationArea>
            </C.ModalCard>
          </C.ModalArea>
        </C.ModalContainer>
      </Modal>
    </>
  );
};

export default ParceiroContratos;
