import {MenuItem, TextField} from "@mui/material";
import RegisterCard from "../../../../../../../../components/RegisterCard";
import * as D from "../../../../../../../../styles/appComponents";
import {EnumEstadoCivil} from "../../../../../../../../enums/EnumEstadoCivil.enum";
import React, {useEffect, useReducer} from "react";
import {IFormInformacoesTitular} from "../../../../../../../../models/Contratar";
import {EnumTipoGDContrato} from "../../../../../../../../enums/EnumTipoGDContrato";

enum formInformacoesTitular {
  NOME = "Nome",
  CPF = "CPF",
  ESTADO_CIVIL = "ESTADO_CIVIL",
  NACIONALIDADE = "NACIONALIDADE",
  PROFISSAO = "PROFISSAO",
}

type PropsInformacoesTitularContratar = {
  type: string;
  data?: IFormInformacoesTitular;
  onSave: (data: IFormInformacoesTitular) => void;
  onUpdate: (data: IFormInformacoesTitular) => void;
  onChange: (data: IFormInformacoesTitular) => void;
}

const InformacoesTitularContratar = (props: PropsInformacoesTitularContratar) => {
  const sendOnSave = (): void => props.onSave(state);
  const sendOnUpdate = (): void => props.onUpdate(state);
  const sendOnChange = (update: IFormInformacoesTitular): void => props.onChange(update);

  const {type, data} = props;

  const setDataForm = (state: IFormInformacoesTitular, action: {
    type: formInformacoesTitular;
    payload: string | number;
  }): IFormInformacoesTitular => {
    const change = {...state, [action.type.toLowerCase()]: action.payload};
    sendOnChange(change);
    return change;
  };

  const setData = (data: IFormInformacoesTitular): void => {
    dispatch({type: formInformacoesTitular.NOME, payload: data.nome});
    dispatch({type: formInformacoesTitular.CPF, payload: data.cpf});
  }

  const [state, dispatch] = useReducer(setDataForm, {
    nome: "",
    cpf: "",
    estado_civil: "",
    nacionalidade: "",
    profissao: "",
  })

  useEffect((): void => {
    if(data) {
      setData(data);
    }
  }, [data]);

  return (
    <RegisterCard title="Informações do titular">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField label="Nome" required fullWidth value={state.nome}
                   onChange={(e) => dispatch({type: formInformacoesTitular.NOME, payload: e.target.value})}/>
        {type === "Fisica" ? (
          <>
            <TextField label="CPF" required fullWidth
                       onChange={(e) => dispatch({type: formInformacoesTitular.CPF, payload: e.target.value})}/>
            <TextField label="Estado Civil" required fullWidth select defaultValue={""}
                       onChange={(e) => dispatch({type: formInformacoesTitular.ESTADO_CIVIL, payload: e.target.value})}>
              {Object.values(EnumEstadoCivil).map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
            <TextField label="Nacionalidade" required fullWidth
                       onChange={(e) => dispatch({
                         type: formInformacoesTitular.NACIONALIDADE,
                         payload: e.target.value
                       })}/>
            <TextField label="Profissão" required fullWidth
                       onChange={(e) => dispatch({type: formInformacoesTitular.PROFISSAO, payload: e.target.value})}/>
          </>
        ) : (
          <>
            <TextField label="CNPJ" required fullWidth
                       onChange={(e) => dispatch({type: formInformacoesTitular.CPF, payload: e.target.value})}/>
          </>
        )}
      </D.FWStack>
      {props.data !== null && props.data !== undefined ? (
        <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
          <D.ContainedButton onClick={sendOnSave}>Salvar</D.ContainedButton>
        </D.FWStack>
      ) : null}
    </RegisterCard>
  );
};

export default InformacoesTitularContratar;
