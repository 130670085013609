import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import * as C from "../style";
import { ICaptadorConfig } from "../../../../../../../../models/CaptadorConfig";
import { Dispositivo, LeadPage } from "../Preview";
import StaticLeadLayout from "../StaticLeadLayout";

interface StaticLeadPageProps {
  theme: ICaptadorConfig;
  leadPage: LeadPage;
  dispositivo: Dispositivo;
}

const StaticLeadPage: React.FC<StaticLeadPageProps> = ({
  theme,
  leadPage,
  dispositivo,
}) => {
  const [leadCriado, setLeadCriado] = useState(false);
  const [insideLoading, setInsideLoading] = useState(false);
  const [telefone, setTelefone] = useState<string>("");
  const [valorConta, setValorConta] = useState<string>("");
  const [anexouConta, setAnexouConta] = useState(false);
  const [conta, setConta] = useState<File | null>(null);

  useEffect(() => {
    setAnexouConta(false);
    setLeadCriado(false);
  }, [leadPage]);

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const cleanedValue = value.replace(/\D/g, "");

    let formattedValue = "";
    if (cleanedValue.length <= 10) {
      formattedValue = cleanedValue.replace(
        /(\d{2})(\d{0,4})(\d{0,4})/,
        "($1) $2-$3"
      );
    } else {
      formattedValue = cleanedValue.replace(
        /(\d{2})(\d{0,5})(\d{0,4})/,
        "($1) $2-$3"
      );
    }

    setTelefone(formattedValue);
  };

  const handleValorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const formattedValue = value
      .replace(/\D/g, "")
      .replace(/(\d)(\d{2})$/, "$1,$2");
    setValorConta(formattedValue);
  };

  const handleContaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      setConta(event.target.files[0]);
    }
  };

  const markedTexts = (text: string) => {
    return text.split("*").map((part, index) => {
      if (index % 2 === 0) {
        return part;
      } else {
        return <C.MarkedText key={index}>{part}</C.MarkedText>;
      }
    });
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    await setInsideLoading(true);
    await setAnexouConta(true);
    await setLeadCriado(true);
    await setInsideLoading(false);
  }

  return (
    <StaticLeadLayout theme={theme} dispositivo={dispositivo}>
      {leadPage == "Gera Lead" ? (
        <C.Content $bgUrl={theme.background} $dispositivo={dispositivo}>
          <C.Left
            $temImagemComoConteudo={theme.temImagemComoConteudo}
            $dispositivo={dispositivo}
          >
            {theme.temImagemComoConteudo ? (
              <C.ContentImage src={theme.contentImage!} />
            ) : (
              <C.Text $dispositivo={dispositivo}>
                {markedTexts(theme.textoBoasVindas ?? "")}
              </C.Text>
            )}
          </C.Left>
          <C.Right $dispositivo={dispositivo}>
            <C.FormArea onSubmit={handleSubmit} $dispositivo={dispositivo}>
              {!leadCriado ? (
                <>
                  <C.FormTitle $dispositivo={dispositivo}>
                    CADASTRE-SE PARA GARANTIR MAIS ECONOMIA NA SUA CONTA DE LUZ
                  </C.FormTitle>
                  <C.FormSubtitle $dispositivo={dispositivo}>
                    APROVEITE, ECONOMIZAR É GRÁTIS!
                  </C.FormSubtitle>
                  <C.InputArea>
                    <label>Nome</label>
                    <TextField
                      id="nome"
                      name="nome"
                      placeholder="Nome"
                      variant="outlined"
                      fullWidth
                      color="secondary"
                      required
                    />
                  </C.InputArea>
                  <C.FWStack spacing={2} direction="row">
                    <C.InputArea>
                      <label>Email</label>
                      <TextField
                        id="email"
                        name="email"
                        placeholder="Email"
                        variant="outlined"
                        fullWidth
                        color="secondary"
                        type="email"
                      />
                    </C.InputArea>
                    <C.InputArea>
                      <label>Telefone</label>
                      <TextField
                        id="telefone"
                        name="telefone"
                        placeholder="Telefone"
                        variant="outlined"
                        fullWidth
                        color="secondary"
                        required
                        value={telefone}
                        onChange={handlePhoneChange}
                        inputProps={{
                          maxLength: 15,
                          minLength: 15,
                        }}
                      />
                    </C.InputArea>
                  </C.FWStack>
                  <C.InputArea>
                    <label>Valor médio da conta</label>
                    <TextField
                      id="valor"
                      name="valor"
                      placeholder="Valor médio da conta"
                      variant="outlined"
                      fullWidth
                      color="secondary"
                      required
                      value={valorConta}
                      onChange={handleValorChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                      }}
                      inputProps={{
                        maxLength: 10,
                        minLength: 3,
                      }}
                    />
                  </C.InputArea>
                  <C.InputArea>
                    <label>Anexar conta (opcional)</label>
                  </C.InputArea>
                  <C.BillsArea
                    direction="column"
                    spacing={2}
                    $dispositivo={dispositivo}
                  >
                    <Button
                      color="secondary"
                      component="label"
                      sx={{ padding: "10px" }}
                      role={undefined}
                      variant="contained"
                      fullWidth
                      startIcon={<FileUploadIcon />}
                    >
                      Anexar conta
                      <C.VisuallyHiddenInput
                        type="file"
                        onChange={handleContaChange}
                      />
                    </Button>
                    <h4>{conta ? conta.name : "Nenhum arquivo anexado."}</h4>
                  </C.BillsArea>
                  <Button
                    variant="contained"
                    fullWidth
                    color="secondary"
                    type="submit"
                  >
                    {insideLoading ? (
                      <CircularProgress color="primary" size={22} />
                    ) : (
                      "Solicitar Proposta"
                    )}
                  </Button>
                </>
              ) : (
                <>
                  <C.FormTitle $dispositivo={dispositivo}>
                    {markedTexts(theme.textoAgradecimento)}
                  </C.FormTitle>
                </>
              )}
            </C.FormArea>
          </C.Right>
        </C.Content>
      ) : (
        <C.Content $bgUrl={theme.background} $dispositivo={dispositivo}>
          <C.Left
            $temImagemComoConteudo={theme.temImagemComoConteudo}
            $dispositivo={dispositivo}
          >
            {theme.temImagemComoConteudo ? (
              <C.ContentImage src={theme.contentImage!} />
            ) : (
              <C.Text $dispositivo={dispositivo}>
                {markedTexts(theme.textoAnexarConta ?? "")}
              </C.Text>
            )}
          </C.Left>
          <C.Right $dispositivo={dispositivo}>
            <C.FormArea onSubmit={handleSubmit} $dispositivo={dispositivo}>
              {!anexouConta ? (
                <>
                  <C.FormTitle $dispositivo={dispositivo}>
                    INSIRA SUA CONTA DE LUZ
                  </C.FormTitle>
                  <C.BillsArea
                    direction="column"
                    spacing={2}
                    $dispositivo={dispositivo}
                  >
                    <Button
                      color="secondary"
                      component="label"
                      sx={{ padding: "10px" }}
                      role={undefined}
                      variant="contained"
                      fullWidth
                      startIcon={<FileUploadIcon />}
                    >
                      Anexar conta
                      <C.VisuallyHiddenInput
                        type="file"
                        onChange={handleContaChange}
                      />
                    </Button>
                    <h4>{conta ? conta.name : "Nenhum arquivo anexado."}</h4>
                  </C.BillsArea>
                  <Button
                    variant="contained"
                    fullWidth
                    color="secondary"
                    type="submit"
                  >
                    {insideLoading ? (
                      <CircularProgress color="primary" size={22} />
                    ) : (
                      "Solicitar Proposta"
                    )}
                  </Button>
                </>
              ) : (
                <>
                  <C.FormTitle $dispositivo={dispositivo}>
                    {markedTexts(theme.textoAgradecimentoAnexarConta)}
                  </C.FormTitle>
                </>
              )}
            </C.FormArea>
          </C.Right>
        </C.Content>
      )}
    </StaticLeadLayout>
  );
};

export default StaticLeadPage;
