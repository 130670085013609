import React, { useState } from "react";
import Login from "../../../../components/Login";
import gerador from "../../../../assets/Login/imgGerador.svg";
import * as C from "../../../../components/Login/style";
import { useNavigate } from "react-router-dom";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const topics: string[] = [
  "Seja bem-vindo! Sua energia é vital para o funcionamento desta empresa.",
  "Você é fundamental para o nosso crescimento e prosperidade. Obrigado por fazer parte desta equipe.",
  "Sua presença aqui é mais do que uma entrada. Você é a engrenagem que nos faz avançar.",
];

const LoginGerador: React.FC = () => {
  const navigate = useNavigate();

  const [showLogin, setShowLogin] = useState(false);

  const handleClickShowLogin = () => setShowLogin((show) => !show);

  const handleMouseDownLogin = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  return (
    <>
      <Login
        title="gerador"
        topics={topics}
        imageSrc={gerador}
        formTitle="Que bom ter você com a gente!"
        formSubtitle="Faça seu login abaixo"
      >
        <C.LoginForm>
          <C.LoginInput label="Login" variant="outlined" />
          <C.LoginInput
            label="Senha"
            variant="outlined"
            type={showLogin ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowLogin}
                    onMouseDown={handleMouseDownLogin}
                    edge="end"
                  >
                    {showLogin ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <C.LinksArea>
            <div></div>
            <C.Links to="/Login/Gerador/RecuperarSenha">
              Esqueceu sua senha?
            </C.Links>
          </C.LinksArea>
          <C.LoginButton
            onClick={() => navigate("/Plataforma/Dashboard")}
            variant="contained"
          >
            Acessar
          </C.LoginButton>
        </C.LoginForm>
      </Login>
    </>
  );
};

export default LoginGerador;
