import React, { useRef, useState } from "react";
import * as C from "./style";
import upload from "../../assets/upload.png";
import Swal from "sweetalert2";
import ArquivoAnexado from "../../assets/Icones/arquivoAnexado.svg";
import ArquivoVazio from "../../assets/Icones/arquivoVazio.svg";

interface ConfigImageInputProps {
  onImageChange: (image: File | null) => void;
  defaultSrc: string | null;
  readonly?: boolean;
}

const ConfigImageInput: React.FC<ConfigImageInputProps> = ({
  onImageChange,
  defaultSrc,
  readonly = false,
}) => {
  const [imageUrl, setImageUrl] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const imageMimeType = /image\/(png|jpg|jpeg)/i;

      if (!file.type.match(imageMimeType)) {
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title:
            "O arquivo selecionado não é uma imagem válida. Use imagens .png, .jpg e .jpeg",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = function () {
        setImageUrl(fileReader.result as string);
      };
      fileReader.readAsDataURL(file);
      onImageChange(file);
    }
  };

  const handleClick = () => {
    if (!readonly) {
      inputRef.current?.click();
    }
  };

  return (
    <>
      <C.Container $readonly={readonly} onClick={handleClick}>
        <C.Left>
          <C.FolderCard>
            <img
              src={
                defaultSrc != null || imageUrl != ""
                  ? ArquivoAnexado
                  : ArquivoVazio
              }
            />
            <span>PNG, JPG ou JPEG</span>
          </C.FolderCard>
          {defaultSrc != null || imageUrl != "" ? (
            <>Arquivo anexado</>
          ) : (
            <>Anexar arquivo</>
          )}
        </C.Left>
        <C.Right>
          <img src={imageUrl || defaultSrc || upload} alt="fotoColaborador" />
        </C.Right>
      </C.Container>
      <C.VisuallyHiddenInput
        ref={inputRef}
        type="file"
        accept="image/png, image/jpeg"
        onChange={handleImageUpload}
      />
    </>
  );
};

export default ConfigImageInput;
