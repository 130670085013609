import React, {useEffect, useState} from "react";
import * as D from "../../../../../../styles/appComponents";
import {useNavigate} from "react-router-dom";
import DefaultModal from "../../../../../../components/Modal";
import {Box} from "@mui/material";

type PropsModalContratar = {
  openModalContratar: boolean;
  handleCloseModalContratar: () => void;
  uuid?: number | string;
};

const ModalContratar = (props: PropsModalContratar) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);

  let {openModalContratar, handleCloseModalContratar, uuid} = props;

  const handleClick = (type: string): void => {
    navigate(`/Parceiro/Novo/Contrato/${type}/${uuid}`);
  }

  useEffect(() => {
    setOpenModal(openModalContratar);
  }, [openModalContratar]);

  return (
    <>
      <DefaultModal
        handleCloseModal={handleCloseModalContratar}
        openModal={openModalContratar}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            textAlign: "center",
            width: "100%",
            height: "100%",
            marginTop: "-50px",
          }}
        >
          <h1 style={{fontWeight: 500, fontSize: "1.8rem", margin: "0"}}>
            Quem está contratando o serviço?
          </h1>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "15px",
            }}
          >
            <D.OutlineButton
              $color="lightpink"
              onClick={() => handleClick("Fisica")}
              style={{
                padding: "12px 24px",
                fontSize: "1.2rem",
              }}
            >
              Pessoa física
            </D.OutlineButton>

            <D.ContainedButton
              $color="lightpink"
              onClick={() => handleClick("Juridica")}
              style={{
                padding: "12px 24px",
                fontSize: "1.2rem",
              }}
            >
              Pessoa jurídica
            </D.ContainedButton>
          </Box>
        </Box>
      </DefaultModal>
    </>
  );
};

export default ModalContratar;
