import styled from "styled-components";
import { Dispositivo, MobileModelos, Proporcoes } from ".";

const getWidth = (
  dispositivo: Dispositivo,
  proporcao: Proporcoes,
  modelo: MobileModelos
): string => {
  if (dispositivo === "Desktop") {
    switch (proporcao) {
      case "4/3":
        return "1600px";
      case "16/10":
        return "1920px";
      case "16/9":
        return "1920px";
      default:
        return "100%";
    }
  } else {
    switch (modelo) {
      case "Iphone 15":
      case "Samsung S24":
        return "540px";
      default:
        return "100%";
    }
  }
};

const getHeight = (
  dispositivo: Dispositivo,
  proporcao: Proporcoes,
  modelo: MobileModelos
): string => {
  if (dispositivo === "Desktop") {
    switch (proporcao) {
      case "4/3":
        return "1200px";
      case "16/10":
        return "1200px";
      case "16/9":
        return "1080px";
      default:
        return "100%";
    }
  } else {
    switch (modelo) {
      case "Iphone 15":
        return "1278px";
      case "Samsung S24":
        return "1170px";
      default:
        return "100%";
    }
  }
};

const getScaledDownWidth = (
  dispositivo: Dispositivo,
  proporcao: Proporcoes,
  modelo: MobileModelos
): string => {
  if (dispositivo === "Desktop") {
    switch (proporcao) {
      case "4/3":
        return `${1600 * 0.5}px`;
      case "16/10":
        return `${1920 * 0.5}px`;
      case "16/9":
        return `${1920 * 0.5}px`;
      default:
        return "100%";
    }
  } else {
    switch (modelo) {
      case "Iphone 15":
      case "Samsung S24":
        return `${540 * 0.5}px`;
      default:
        return "100%";
    }
  }
};

const getScaledDownHeight = (
  dispositivo: Dispositivo,
  proporcao: Proporcoes,
  modelo: MobileModelos
): string => {
  if (dispositivo === "Desktop") {
    switch (proporcao) {
      case "4/3":
        return `${1200 * 0.5}px`;
      case "16/10":
        return `${1200 * 0.5}px`;
      case "16/9":
        return `${1080 * 0.5}px`;
      default:
        return "100%";
    }
  } else {
    switch (modelo) {
      case "Iphone 15":
        return `${1278 * 0.5}px`;
      case "Samsung S24":
        return `${1170 * 0.5}px`;
      default:
        return "100%";
    }
  }
};

export const PreviewContainer = styled.div<{
  $proporcao: Proporcoes;
  $dispositivo: Dispositivo;
  $modelo: MobileModelos;
}>`
  width: ${(props) =>
    getScaledDownWidth(props.$dispositivo, props.$proporcao, props.$modelo)};

  height: ${(props) =>
    getScaledDownHeight(props.$dispositivo, props.$proporcao, props.$modelo)};
  overflow: hidden;
`;

export const Container = styled.div<{
  $proporcao: Proporcoes;
  $dispositivo: Dispositivo;
  $modelo: MobileModelos;
}>`
  width: ${(props) =>
    getWidth(props.$dispositivo, props.$proporcao, props.$modelo)};
  height: ${(props) =>
    getHeight(props.$dispositivo, props.$proporcao, props.$modelo)};
  transform: ${(props) =>
    props.$dispositivo == "Desktop" ? "scale(0.5)" : "scale(0.5)"};
  transform-origin: top left;
  position: relative;
`;
