import { Button, IconButton } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Navbar = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  -webkit-box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.38);
  box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.38);
  z-index: 3;
  position: fixed;

  @media (max-width: 1024px) {
    padding: 10px;
  }
`;

export const HamburguerButton = styled.button`
  display: none;

  @media (max-width: 1024px) {
    display: block;
    color: #f36a7b;
    background-color: white;
    border: none;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 1024px) {
    gap: 0;
    display: none;
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DropdownOpen = styled.div`
  display: flex;
  width: 250px;
  flex-direction: column;
  border-radius: 10px;
  background: #ffffff;
  padding: 20px;
  align-items: center;
  position: absolute;
  z-index: 3;
  top: 100%;
  text-align: center;
  gap: 10px;

  h3 {
    color: #222222;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }

  @media (max-width: 1024px) {
    border-radius: 0 0 10px 10px;
    left: 0;
    width: 100%;
  }
`;

export const NotificationOpen = styled.div`
  display: flex;
  width: 350px;
  max-height: 300px;
  overflow-y: auto;
  flex-direction: column;
  border-radius: 10px;
  background: #ffffff;
  position: absolute;
  z-index: 3;
  top: 100%;
  padding-bottom: 30px;

  h3 {
    color: #222222;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }

  @media (max-width: 1024px) {
    border-radius: 0 0 10px 10px;
    left: 0;
    width: 100%;
  }
`;

export const Notification = styled.div`
  padding: 18px 10px;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const NotificationLeft = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    margin: 0;
    color: #222222;
    font-size: 16px;
    font-weight: 400;
  }

  span {
    color: #222222;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const NotificationRight = styled.div`
  display: flex;
  width: 20%;
  justify-content: center;
  padding-top: 10px;
`;

export const Circle = styled.div<{ color: number }>`
  width: 24px;
  height: 24px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: ${({ color }) => {
  switch (color) {
    case 0:
      return "blue";
    case 1:
      return "yellow";
    case 2:
      return "green";
    case 3:
      return "red";
    default:
      return "transparent";
  }
}};
`;

export const NavBadges = styled.div`
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const WppButton = styled(Button)`
  width: auto;
  background-color: #00d752;
  color: white;
  margin-top: 10px;
  border-radius: 10px;

  &:hover {
    background-color: #04c24c;
  }
`;

export const AvatarLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const AvatarLinksWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 5px;
`;

export const AvatarLinks = styled(Link)`
  border-radius: 10px;
  width: 100%;
  color: #222222;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  padding: 7px;
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }
`;
export const AvatarLinksButton = styled.a`
  border-radius: 10px;
  width: 100%;
  color: #222222;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  padding: 7px;
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }
`;

export const WrapperBlock = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: calc(100vh - 70px);
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 2;
  overflow: hidden;

  @media (max-width: 1024px) {
    height: 100%;
  }
`;

export const NameWrapper = styled.div`
  min-width: 150px;
  color: #222222;

  h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
  }

  p {
    margin: 0;
    font-size: 12px;
    font-weight: 300s;
  }
`;

export const Wrapper = styled.div`
  margin-top: 70px;
  width: 100%;
  display: flex;
  height: calc(100% - 70px);
  position: relative;
`;

export const SideBarLink = styled(NavLink)`
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;

  color: #d24293;
  text-decoration: none;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }

  &.active {
    color: white;
    background-color: #d24293;

    &:hover {
      background-color: #bf3d87;
    }
  }
`;

export const SideBarMobile = styled.div`
  width: 250px;
  height: 100%;
  background-color: white;
  padding: 20px;
  display: block;
  gap: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  overflow-y: auto;
`;

export const CloseButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SidebarMobileLine = styled.div`
  margin-top: 20px;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const SideBarMobileLinksArea = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const AvatarSideBarWrapperMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const AvatarNameMobile = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  cursor: pointer;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 24px;
  transition: all ease 0.4s;

  @media (max-width: 768px) {
    padding: 14px;
  }
`;

export const IconButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledIconButton = styled(IconButton)`
  width: 30px;
  height: 30px;
  aspect-ratio: 1/1;

  svg {
    width: 20px;
    color: #d24293;
  }

  &:hover {
    transform: scale(110%);
  }
`;
