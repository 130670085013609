import {MenuItem, TextField} from "@mui/material";
import RegisterCard from "../../../../../../../../components/RegisterCard";
import * as D from "../../../../../../../../styles/appComponents";
import React, {useEffect, useReducer} from "react";
import {EnumTipoConexao} from "../../../../../../../../enums/EnumTipoConexao.enum";
import {EnumTipoGDContrato} from "../../../../../../../../enums/EnumTipoGDContrato";
import {
  IformInformacoesEndereco,
  IformInformacoesInformacoesContato,
  IFormInformacoesTitular
} from "../../../../../../../../models/Contratar";

enum formInformacoesInformacoesContato {
  razaoSocial = "razaoSocial",
  cnpj = "cnpj",
  representanteLegal = "representanteLegal",
  numeroInstalacao = "numeroInstalacao",
  numeroCliente = "numeroCliente",
  dataVencimento = "dataVencimento",
  conexao = "conexao",
  tarifaCemig = "tarifaCemig",
  desconexaoTarifaDistrito = "desconexaoTarifaDistrito",
  taxaDisponibilidade = "taxaDisponibilidade",
  media12meses = "media12meses",
  franquia = "franquia",
  valorAssinatura = "valorAssinatura",
  tarifaUsina = "tarifaUsina",
  potencia = "potencia",
  valorMaximoInjecao = "valorMaximoInjecao",
  lote = "lote",
  economiaMensal = "economiaMensal",
  economiaAnual = "economiaAnual",
}

type PropsInformacoesContratoContratar = {
  type: string;
  data?: IformInformacoesInformacoesContato;
  onSave: (data: IformInformacoesInformacoesContato) => void;
  onUpdate: (data: IformInformacoesInformacoesContato) => void;
  onChange: (data: IformInformacoesInformacoesContato) => void;
}

const InformacoesContratoContratar = (props: PropsInformacoesContratoContratar) => {
  const sendOnSave = (): void => props.onSave(state);
  const sendOnUpdate = (): void => props.onUpdate(state);
  const sendOnChange = (update: IformInformacoesInformacoesContato): void => props.onChange(update);

  const {type, data} = props;

  const setDataForm = (state: IformInformacoesInformacoesContato, action: {
    type: formInformacoesInformacoesContato;
    payload: string | number | Date;
  }): IformInformacoesInformacoesContato => {
    const change = {...state, [action.type]: action.payload};
    sendOnChange(change);
    return change;
  };

  const setData = (data: IformInformacoesInformacoesContato): void => {
    if (type !== "Fisica") {
      dispatch({type: formInformacoesInformacoesContato.razaoSocial, payload: data?.razaoSocial});
      dispatch({type: formInformacoesInformacoesContato.cnpj, payload: data?.cnpj});
      dispatch({type: formInformacoesInformacoesContato.representanteLegal, payload: data?.representanteLegal});
    }

    dispatch({type: formInformacoesInformacoesContato.numeroInstalacao, payload: data?.numeroInstalacao});
    dispatch({type: formInformacoesInformacoesContato.numeroCliente, payload: data?.numeroCliente});
    dispatch({type: formInformacoesInformacoesContato.dataVencimento, payload: data?.dataVencimento});
    dispatch({type: formInformacoesInformacoesContato.conexao, payload: data?.conexao});
    dispatch({type: formInformacoesInformacoesContato.tarifaCemig, payload: data?.tarifaCemig});
    dispatch({
      type: formInformacoesInformacoesContato.desconexaoTarifaDistrito,
      payload: data?.desconexaoTarifaDistrito
    });
    dispatch({type: formInformacoesInformacoesContato.taxaDisponibilidade, payload: data?.taxaDisponibilidade});
    dispatch({type: formInformacoesInformacoesContato.media12meses, payload: data?.media12meses});
    dispatch({type: formInformacoesInformacoesContato.franquia, payload: data?.franquia});
    dispatch({type: formInformacoesInformacoesContato.valorAssinatura, payload: data?.valorAssinatura});
    dispatch({type: formInformacoesInformacoesContato.tarifaUsina, payload: data?.tarifaUsina});
    dispatch({type: formInformacoesInformacoesContato.potencia, payload: data?.potencia});
    dispatch({type: formInformacoesInformacoesContato.valorMaximoInjecao, payload: data?.valorMaximoInjecao});
    dispatch({type: formInformacoesInformacoesContato.lote, payload: data?.lote});
    dispatch({type: formInformacoesInformacoesContato.economiaMensal, payload: data?.economiaMensal});
    dispatch({type: formInformacoesInformacoesContato.economiaAnual, payload: data?.economiaAnual});
  }

  const [state, dispatch] = useReducer(setDataForm, {
    razaoSocial: "",
    cnpj: "",
    representanteLegal: "",
    numeroInstalacao: "",
    numeroCliente: "",
    dataVencimento: new Date(),
    conexao: "",
    tarifaCemig: "",
    desconexaoTarifaDistrito: "",
    taxaDisponibilidade: "",
    media12meses: "",
    franquia: "",
    valorAssinatura: "",
    tarifaUsina: "",
    potencia: "",
    valorMaximoInjecao: "",
    lote: "",
    economiaMensal: "",
    economiaAnual: "",
  })

  useEffect((): void => {
    if (data) {
      setData(data);
    }
  }, [data]);

  return (
    <RegisterCard title="Informações do contrato">

        {type !== "Fisica" ? (
          <>
            <D.FWStack direction={"row"} spacing={2}>
              <TextField
                label="Razão social"
                required
                fullWidth
                value={state.razaoSocial}
                onChange={(e) =>
                  dispatch({
                    type: formInformacoesInformacoesContato.razaoSocial,
                    payload: e.target.value,
                  })
                }
              />
              <TextField
                label="CNPJ"
                required
                fullWidth
                value={state.cnpj}
                onChange={(e) =>
                  dispatch({
                    type: formInformacoesInformacoesContato.cnpj,
                    payload: e.target.value,
                  })
                }
              />
              <TextField
                label="Representante legal"
                required
                fullWidth
                onChange={(e) =>
                  dispatch({
                    type: formInformacoesInformacoesContato.representanteLegal,
                    payload: e.target.value,
                  })
                }
              />
            </D.FWStack>
          </>
        ) : null}

      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Número instalação"
          required
          fullWidth
          value={state.numeroInstalacao}
          onChange={(e) => dispatch({
            type: formInformacoesInformacoesContato.numeroInstalacao,
            payload: e.target.value
          })}/>
        <TextField label="Número cliente" required fullWidth/>
        <D.DateTextfield
          type="date"
          label="Data de vencimento"
          fullWidth
          value={state.dataVencimento}
          onChange={(e) => dispatch({type: formInformacoesInformacoesContato.dataVencimento, payload: e.target.value})}
        />
        <TextField
          label="Conexão"
          select
          required
          fullWidth
          value={state.conexao}
          onChange={(e) => dispatch({type: formInformacoesInformacoesContato.conexao, payload: e.target.value})}>
          {Object.values(EnumTipoConexao).map((value, index) => (
            <MenuItem key={value} value={index}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Tarifa cemig"
          required
          fullWidth
          value={state.tarifaCemig}
          onChange={(e) => dispatch({type: formInformacoesInformacoesContato.tarifaCemig, payload: e.target.value})}/>
      </D.FWStack>

      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Desconexão tarifa distribuidora"
          required
          fullWidth
          value={state.desconexaoTarifaDistrito}
          onChange={(e) => dispatch({
            type: formInformacoesInformacoesContato.desconexaoTarifaDistrito,
            payload: e.target.value
          })}/>
        <TextField
          label="Taxa de disponibilidade"
          required
          fullWidth
          value={state.taxaDisponibilidade}
          onChange={(e) => dispatch({
            type: formInformacoesInformacoesContato.taxaDisponibilidade,
            payload: e.target.value
          })}/>
        <TextField
          label="Média 12 meses"
          required
          fullWidth
          value={state.media12meses}
          onChange={(e) => dispatch({type: formInformacoesInformacoesContato.media12meses, payload: e.target.value})}/>
        <TextField
          label="Franquia"
          required
          fullWidth
          value={state.franquia}
          onChange={(e) => dispatch({type: formInformacoesInformacoesContato.franquia, payload: e.target.value})}/>
        <TextField
          label="Valor assinatura"
          required
          fullWidth
          value={state.valorAssinatura}
          onChange={(e) => dispatch({
            type: formInformacoesInformacoesContato.valorAssinatura,
            payload: e.target.value
          })}/>
        <TextField
          label="Tarifa usina"
          required
          fullWidth
          value={state.tarifaUsina}
          onChange={(e) => dispatch({type: formInformacoesInformacoesContato.tarifaUsina, payload: e.target.value})}/>
        <TextField
          label="Potencia"
          required
          fullWidth
          value={state.potencia}
          onChange={(e) => dispatch({type: formInformacoesInformacoesContato.potencia, payload: e.target.value})}/>
      </D.FWStack>

      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Valor máximo de injeção"
          required
          fullWidth
          value={state.valorMaximoInjecao}
          onChange={(e) => dispatch({
            type: formInformacoesInformacoesContato.valorMaximoInjecao,
            payload: e.target.value
          })}/>
        <TextField
          label="Lote (fração da usina)"
          required
          fullWidth
          value={state.lote}
          onChange={(e) => dispatch({type: formInformacoesInformacoesContato.lote, payload: e.target.value})}/>
        <TextField
          label="Economia mensal"
          required
          fullWidth
          value={state.economiaAnual}
          onChange={(e) => dispatch({
            type: formInformacoesInformacoesContato.economiaMensal,
            payload: e.target.value
          })}/>
        <TextField
          label="Economia anual"
          required
          fullWidth
          value={state.economiaAnual}
          onChange={(e) => dispatch({
            type: formInformacoesInformacoesContato.economiaAnual,
            payload: e.target.value
          })}/>
      </D.FWStack>
      {props.data !== null && props.data !== undefined ? (
        <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
          <D.ContainedButton onClick={sendOnSave}>Salvar</D.ContainedButton>
        </D.FWStack>
      ) : null}
    </RegisterCard>
  );
};

export default InformacoesContratoContratar;
