import { IAssociado, ICreateAssociado } from "../../models/Associado";
import { ICaptador, ICaptadorListItem } from "../../models/Captador";
import http from "../common/http-common";

export const GetCaptadores = () => {
  return http.get<ICaptadorListItem[]>(`/Captador/BuscaTodosCaptadores`);
};

export const GetById = (id: string) => {
  return http.get<ICaptador>(`/Captador/${id}`);
};

export const CreateAssociado = (createAssociado: ICreateAssociado) => {
  return http.post<IAssociado>(`/Captador/Associado`, createAssociado);
};

export const GetLimiteDesconto = (idCaptador: string) => {
  return http.get<string>(`/Captador/BuscaLimiteDesconto/${idCaptador}`);
};
