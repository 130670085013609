import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import SkeletonLoadLeads from "../../../../components/SkeletonLoads/Leads";
import { ICaptadorConfig } from "../../../../models/CaptadorConfig";
import { ICreateLead } from "../../../../models/Lead";
import * as leadServices from "../../../../services/api/LeadService";
import * as captadorConfigService from "../../../../services/api/CaptadorConfigService";
import LeadLayout from "../components/LeadLayout";
import * as C from "../style";

const GeraLead: React.FC = () => {
  const { url } = useParams();
  const navigate = useNavigate();
  const [leadCriado, setLeadCriado] = useState(false);
  const [insideLoading, setInsideLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [nome, setNome] = useState<string>("");
  const [telefone, setTelefone] = useState<string>("");
  const [telefoneSemMascara, setTelefoneSemMascara] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [valorConta, setValorConta] = useState<string>("");
  const [conta, setConta] = useState<File | null>(null);

  const [theme, setTheme] = useState<ICaptadorConfig>({
    id: "",
    celular: "31123412343",
    corPrimaria: "#fc0303",
    corSecundaria: "#1900ff",
    corTextoBase: "#ffffff",
    corTextoDestaque: "#00ff44",
    textoBoasVindas:
      "*A WATTWISE CONVIDA* VOCÊ A ECONOMIZAR ATÉ *25% EM SUA TARIFA DE ENERGIA.*",
    textoAgradecimento:
      "VOCÊ ESTÁ A UM PASSO DE RECEBER ATÉ 25% DE DESCONTO NA SUA CONTA DE LUZ, EM BREVE TE RETORNAREMOS. *FIQUE ATENTO AO SEU EMAIL.*",
    textoAnexarConta:
      "VOCÊ ESTÁ A UM PASSO DE ECONOMIZAR EM ATÉ 25% NA SUA CONTA DE ENERGIA.",
    textoAgradecimentoAnexarConta:
      "OBRIGADO POR ANEXAR SUA CONTA! ENTRAREMOS EM CONTATO EM BREVE COM UMA PROPOSTA.",
    temPixelFacebook: false,
    temImagemComoConteudo: false,
    pixelFacebook: "",
    logo: "",
    background: "",
    contentImage: "",
    nome: "",
  });

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const cleanedValue = value.replace(/\D/g, "");

    let formattedValue = "";
    if (cleanedValue.length <= 10) {
      formattedValue = cleanedValue.replace(
        /(\d{2})(\d{0,4})(\d{0,4})/,
        "($1) $2-$3"
      );
    } else {
      formattedValue = cleanedValue.replace(
        /(\d{2})(\d{0,5})(\d{0,4})/,
        "($1) $2-$3"
      );
    }

    setTelefone(formattedValue);
    setTelefoneSemMascara(cleanedValue);
  };

  const handleValorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const formattedValue = value
      .replace(/\D/g, "")
      .replace(/(\d)(\d{2})$/, "$1,$2");
    setValorConta(formattedValue);
  };

  const handleContaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      setConta(event.target.files[0]);
    }
  };

  const markedTexts = (text: string) => {
    return text.split("*").map((part, index) => {
      if (index % 2 === 0) {
        return part;
      } else {
        return <C.MarkedText key={index}>{part}</C.MarkedText>;
      }
    });
  };

  useEffect(() => {
    captadorConfigService
      .GetCaptadorConfigByUrl(url!)
      .then(
        async (response: { data: React.SetStateAction<ICaptadorConfig> }) => {
          await setTheme(response.data);
          setLoading(false);
        }
      )
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        navigate("/");

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  }, []);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    await setInsideLoading(true);

    var createLeadData: ICreateLead = {
      idCaptador: null,
      urlCaptador: url!,
      nome: nome,
      celular: telefoneSemMascara,
      email: email,
      valor: valorConta.replace(",", "."),
      conta: conta ? conta : null,
    };

    await leadServices
      .CreateLead(createLeadData)
      .then(() => {
        setInsideLoading(false);
        setLeadCriado(true);
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Enviado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  }

  return loading ? (
    <SkeletonLoadLeads></SkeletonLoadLeads>
  ) : (
    <LeadLayout theme={theme}>
      <C.Content $bgUrl={theme.background}>
        <C.Left $temImagemComoConteudo={theme.temImagemComoConteudo}>
          {theme.temImagemComoConteudo ? (
            <C.ContentImage src={theme.contentImage!} />
          ) : (
            <C.Text>{markedTexts(theme.textoBoasVindas ?? "")}</C.Text>
          )}
        </C.Left>
        <C.Right>
          <C.FormArea onSubmit={handleSubmit}>
            {!leadCriado ? (
              <>
                <C.FormTitle>
                  CADASTRE-SE PARA GARANTIR MAIS ECONOMIA NA SUA CONTA DE LUZ
                </C.FormTitle>
                <C.FormSubtitle>APROVEITE, ECONOMIZAR É GRÁTIS!</C.FormSubtitle>
                <C.InputArea>
                  <label>Nome</label>
                  <TextField
                    id="nome"
                    name="nome"
                    placeholder="Nome"
                    variant="outlined"
                    fullWidth
                    color="secondary"
                    required
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                </C.InputArea>
                <C.FWStack spacing={2} direction="row">
                  <C.InputArea>
                    <label>Email</label>
                    <TextField
                      id="email"
                      name="email"
                      placeholder="Email"
                      variant="outlined"
                      fullWidth
                      color="secondary"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </C.InputArea>
                  <C.InputArea>
                    <label>Telefone</label>
                    <TextField
                      id="telefone"
                      name="telefone"
                      placeholder="Telefone"
                      variant="outlined"
                      fullWidth
                      color="secondary"
                      required
                      value={telefone}
                      onChange={handlePhoneChange}
                      inputProps={{
                        maxLength: 15,
                        minLength: 15,
                      }}
                    />
                  </C.InputArea>
                </C.FWStack>
                <C.InputArea>
                  <label>Valor médio da conta</label>
                  <TextField
                    id="valor"
                    name="valor"
                    placeholder="Valor médio da conta"
                    variant="outlined"
                    fullWidth
                    color="secondary"
                    required
                    value={valorConta}
                    onChange={handleValorChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      maxLength: 10,
                      minLength: 3,
                    }}
                  />
                </C.InputArea>
                <C.InputArea>
                  <label>Anexar conta (opcional)</label>
                </C.InputArea>
                <C.BillsArea direction="column" spacing={2}>
                  <Button
                    color="secondary"
                    component="label"
                    sx={{ padding: "10px" }}
                    role={undefined}
                    variant="contained"
                    fullWidth
                    startIcon={<FileUploadIcon />}
                  >
                    Anexar conta
                    <C.VisuallyHiddenInput
                      type="file"
                      onChange={handleContaChange}
                    />
                  </Button>
                  <h4>{conta ? conta.name : "Nenhum arquivo anexado."}</h4>
                </C.BillsArea>
                <Button
                  variant="contained"
                  fullWidth
                  color="secondary"
                  type="submit"
                >
                  {insideLoading ? (
                    <CircularProgress color="primary" size={22} />
                  ) : (
                    "Solicitar Proposta"
                  )}
                </Button>
              </>
            ) : (
              <>
                <C.FormTitle>
                  {markedTexts(theme.textoAgradecimento)}
                </C.FormTitle>
              </>
            )}
          </C.FormArea>
        </C.Right>
      </C.Content>
    </LeadLayout>
  );
};

export default GeraLead;
