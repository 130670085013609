import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Button, CircularProgress } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import SkeletonLoadLeads from "../../../../components/SkeletonLoads/Leads";
import { ICaptadorConfig } from "../../../../models/CaptadorConfig";
import * as captadorConfigService from "../../../../services/api/CaptadorConfigService";
import * as leadServices from "../../../../services/api/LeadService";
import LeadLayout from "../components/LeadLayout";
import * as C from "../style";

const AnexarArquivo: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [anexouConta, setAnexouConta] = useState(false);
  const [insideLoading, setInsideLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [conta, setConta] = useState<File | null>(null);
  const [theme, setTheme] = useState<ICaptadorConfig>({
    id: "",
    celular: "31123412343",
    corPrimaria: "#fc0303",
    corSecundaria: "#1900ff",
    corTextoBase: "#ffffff",
    corTextoDestaque: "#00ff44",
    textoBoasVindas:
      "*A WATTWISE CONVIDA* VOCÊ A ECONOMIZAR ATÉ *25% EM SUA TARIFA DE ENERGIA.*",
    textoAgradecimento:
      "VOCÊ ESTÁ A UM PASSO DE RECEBER ATÉ 25% DE DESCONTO NA SUA CONTA DE LUZ, EM BREVE TE RETORNAREMOS. *FIQUE ATENTO AO SEU EMAIL.*",
    textoAnexarConta:
      "VOCÊ ESTÁ A UM PASSO DE ECONOMIZAR EM ATÉ 25% NA SUA CONTA DE ENERGIA.",
    textoAgradecimentoAnexarConta:
      "OBRIGADO POR ANEXAR SUA CONTA! ENTRAREMOS EM CONTATO EM BREVE COM UMA PROPOSTA.",
    temPixelFacebook: false,
    pixelFacebook: "",
    temImagemComoConteudo: false,
    logo: "",
    background: "",
    contentImage: "",
    nome: "",
  });

  const handleContaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      setConta(event.target.files[0]);
    }
  };

  const markedTexts = (text: string) => {
    return text.split("*").map((part, index) => {
      if (index % 2 === 0) {
        return part;
      } else {
        return <C.MarkedText key={index}>{part}</C.MarkedText>;
      }
    });
  };

  useEffect(() => {
    captadorConfigService
      .GetCaptadorConfigByLeadId(id!)
      .then(
        async (response: { data: React.SetStateAction<ICaptadorConfig> }) => {
          await setTheme(response.data);

          setLoading(false);
        }
      )
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        navigate("/");

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });

    leadServices
      .VerifyUploadedBill(id!)
      .then(async (response) => {
        await setAnexouConta(response.data.anexado);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  }, []);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    await setInsideLoading(true);

    if (conta == null) {
      setInsideLoading(false);
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Conta não pode ser vazia.",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });

      return;
    }

    await leadServices
      .AnexarConta(id!, conta)
      .then(() => {
        setInsideLoading(false);
        setAnexouConta(true);
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Enviado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        setInsideLoading(false);

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  }
  return loading ? (
    <SkeletonLoadLeads />
  ) : (
    <LeadLayout theme={theme}>
      <C.Content $bgUrl={theme.background}>
        <C.Left $temImagemComoConteudo={theme.temImagemComoConteudo}>
          {theme.temImagemComoConteudo ? (
            <C.ContentImage src={theme.contentImage!} />
          ) : (
            <C.Text>{markedTexts(theme.textoAnexarConta ?? "")}</C.Text>
          )}
        </C.Left>
        <C.Right>
          <C.FormArea onSubmit={handleSubmit}>
            {!anexouConta ? (
              <>
                <C.FormTitle>INSIRA SUA CONTA DE LUZ</C.FormTitle>
                <C.BillsArea direction="column" spacing={2}>
                  <Button
                    color="secondary"
                    component="label"
                    sx={{ padding: "10px" }}
                    role={undefined}
                    variant="contained"
                    fullWidth
                    startIcon={<FileUploadIcon />}
                  >
                    Anexar conta
                    <C.VisuallyHiddenInput
                      type="file"
                      onChange={handleContaChange}
                    />
                  </Button>
                  <h4>{conta ? conta.name : "Nenhum arquivo anexado."}</h4>
                </C.BillsArea>
                <Button
                  variant="contained"
                  fullWidth
                  color="secondary"
                  type="submit"
                >
                  {insideLoading ? (
                    <CircularProgress color="primary" size={22} />
                  ) : (
                    "Solicitar Proposta"
                  )}
                </Button>
              </>
            ) : (
              <>
                <C.FormTitle>
                  {markedTexts(theme.textoAgradecimentoAnexarConta)}
                </C.FormTitle>
              </>
            )}
          </C.FormArea>
        </C.Right>
      </C.Content>
    </LeadLayout>
  );
};

export default AnexarArquivo;
