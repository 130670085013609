import { ReactNode, createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { IAcessoDTO } from "../models/Acesso";
import { ILoginCaptadorReturn } from "../models/Usuario";
import { useLocalStorage } from "./useLocalStorage";

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextType {
  user: ILoginCaptadorReturn | null;
  token: string | null;
  acesso: IAcessoDTO | null;
  login: (data: ILoginCaptadorReturn) => Promise<void>;
  logout: () => void;
}

const defaultValue: AuthContextType = {
  user: null,
  token: null,
  acesso: null,
  login: async () => {},
  logout: () => {},
};

const AuthContext = createContext<AuthContextType>(defaultValue);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [token, setToken] = useLocalStorage("token", null);
  const [acesso, setAcesso] = useLocalStorage("acesso", null);
  const navigate = useNavigate();

  const login = async (data: ILoginCaptadorReturn) => {
    setUser(data);
    setToken(data.token);
    // setAcesso(data.acesso);
    navigate("/");
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    setAcesso(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      token,
      acesso,
      login,
      logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
