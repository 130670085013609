import {MenuItem, TextField} from "@mui/material";
import RegisterCard from "../../../../../../../../components/RegisterCard";
import * as D from "../../../../../../../../styles/appComponents";
import React, {useEffect, useReducer, useState} from "react";
import {EnumTipoOperadora} from "../../../../../../../../enums/EnumTipoOperadora.enum";
import {
  IformInformacoesInformacoesContato,
  IformInformacoesOutrasInformacoes, IFormInformacoesTitular
} from "../../../../../../../../models/Contratar";
import * as colaboradorServices from "../../../../../../../../services/api/ColaboradorService";
import axios, {AxiosError, AxiosResponse} from "axios";
import Swal from "sweetalert2";
import {IBackofficeListItem} from "../../../../../../../../models/Colaborador";

enum formInformacoesOutrasInformacoes {
  usina = "usina",
  operadora = "operadora",
  signatario = "signatario",
  comissaoCaptador = "comissaoCaptador",
  gatewayPagamento = "gatewayPagamento",
  limiteInjecao = "limiteInjecao",
  prefRecebido = "prefRecebido",
  parceiro = "parceiro",
  apoioParceiro = "apoioParceiro",
  backofficeId = "backofficeId",
  dataAssinatura = "dataAssinatura",
}

type PropsOutrasInformacoesContratar = {
  type: string;
  data: IformInformacoesOutrasInformacoes;
  onSave: (data: IformInformacoesOutrasInformacoes) => void;
  onUpdate: (data: IformInformacoesOutrasInformacoes) => void;
  onChange: (data: IformInformacoesOutrasInformacoes) => void;
}

const OutrasInformacoesContratar = (props: PropsOutrasInformacoesContratar) => {
  const sendOnSave = (): void => props.onSave(state);
  const sendOnUpdate = (): void => props.onUpdate(state);
  const sendOnChange = (update: IformInformacoesOutrasInformacoes): void => props.onChange(update);
  const [backoffices, setBackoffices] = useState<IBackofficeListItem[]>([]);

  const {data} = props;

  const setDataForm = (state: IformInformacoesOutrasInformacoes, action: {
    type: formInformacoesOutrasInformacoes;
    payload: string | number;
  }): IformInformacoesOutrasInformacoes => {
    const change = {...state, [action.type]: action.payload};
    sendOnChange(change);
    return change;
  };

  const getBackoffices = async (): Promise<void> => {
    try {
      const response: AxiosResponse<IBackofficeListItem[]> = await colaboradorServices.GetBackoffices();
      setBackoffices(response.data);
    } catch (e) {
      var errorMessage: string = "Houve um erro ao pegar os backoffices.";

      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: errorMessage,
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
    }
  };

  const setData = (data: IformInformacoesOutrasInformacoes): void => {
    dispatch({type: formInformacoesOutrasInformacoes.backofficeId, payload: data.backofficeId});
  }

  const [state, dispatch] = useReducer(setDataForm, {
    usina: "",
    operadora: "",
    signatario: "",
    comissaoCaptador: "",
    gatewayPagamento: "",
    limiteInjecao: "",
    prefRecebido: "",
    parceiro: "",
    apoioParceiro: "",
    backofficeId: "",
    dataAssinatura: new Date(),
  })

  useEffect((): void => {
    if (data) {
      getBackoffices().then(_ => {
        setData(data);
      });
    }
  }, [data]);

  return (
    <RegisterCard title="Outras informçaões">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField label="Usina" select required fullWidth
                   onChange={(e) => dispatch({type: formInformacoesOutrasInformacoes.usina, payload: e.target.value})}>
        </TextField>
        <TextField label="Operadora" select required fullWidth
                   onChange={(e) => dispatch({
                     type: formInformacoesOutrasInformacoes.operadora,
                     payload: e.target.value
                   })}>
          {Object.values(EnumTipoOperadora).map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField label="Signatário" required fullWidth
                   onChange={(e) => dispatch({
                     type: formInformacoesOutrasInformacoes.signatario,
                     payload: e.target.value
                   })}/>
        <TextField label="Comissão captador" required fullWidth onChange={(e) => dispatch({
          type: formInformacoesOutrasInformacoes.comissaoCaptador,
          payload: e.target.value
        })}/>
      </D.FWStack>

      <D.FWStack direction={"row"} spacing={2}>
        <TextField label="Gateway pagamento" required fullWidth onChange={(e) => dispatch({
          type: formInformacoesOutrasInformacoes.gatewayPagamento,
          payload: e.target.value
        })}/>
        <TextField label="Limite de injeção" required fullWidth
                   onChange={(e) => dispatch({
                     type: formInformacoesOutrasInformacoes.limiteInjecao,
                     payload: e.target.value
                   })}/>
        <TextField label="Pref recebimento" select required fullWidth
                   onChange={(e) => dispatch({
                     type: formInformacoesOutrasInformacoes.prefRecebido,
                     payload: e.target.value
                   })}>
          {Object.values(EnumTipoOperadora).map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField label="Parceiro" select required fullWidth
                   onChange={(e) => dispatch({
                     type: formInformacoesOutrasInformacoes.parceiro,
                     payload: e.target.value
                   })}>
          {Object.values(EnumTipoOperadora).map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
      </D.FWStack>

      <D.FWStack direction={"row"} spacing={2}>
        <TextField label="Apoio parceiro" required fullWidth
                   onChange={(e) => dispatch({
                     type: formInformacoesOutrasInformacoes.apoioParceiro,
                     payload: e.target.value
                   })}/>
        <TextField label="Backoffice" select required fullWidth
                   value={state.backofficeId}
                   onChange={(e) => dispatch({
                     type: formInformacoesOutrasInformacoes.backofficeId,
                     payload: e.target.value
                   })}>
          {Object.values(backoffices).map((value, index) => (
            <MenuItem key={index} value={value.id}>
              {value.nome}
            </MenuItem>
          ))}
        </TextField>
        <D.DateTextfield
          type="date"
          label="Data de assinatura"
          fullWidth
          onChange={(e) => dispatch({type: formInformacoesOutrasInformacoes.dataAssinatura, payload: e.target.value})}
        />
      </D.FWStack>
      {props.data !== null && props.data !== undefined ? (
        <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
          <D.ContainedButton onClick={sendOnSave}>Salvar</D.ContainedButton>
        </D.FWStack>
      ) : null}
    </RegisterCard>
  );
};

export default OutrasInformacoesContratar;
