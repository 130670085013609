export enum EnumTipoParceiro {
  parceiroMaster = "Parceiro Master",
  associadoMaster = "Associado Master",
  associado = "Associado",
}

export const EnumTipoParceiroNumericMap = {
  [EnumTipoParceiro.parceiroMaster]: 0,
  [EnumTipoParceiro.associadoMaster]: 1,
  [EnumTipoParceiro.associado]: 2,
};
