import { IGDContratoPaged, IGDContratoStats } from "../../models/GDContrato";
import { IPaginatedList } from "../../models/PaginatedList";
import http from "../common/http-common";

export const GetStats = () => {
  return http.get<IGDContratoStats[]>("/GDContrato/Stats");
};

export const GetContratos = (
    pageNumber: number,
    pageSize: number,
    search?: string
  ) => {
    var searchString: string = "";
  
    if (search) {
      searchString = `&Search=${search}`;
    }
  
    return http.get<IPaginatedList<IGDContratoPaged>>(
      `/GDContrato?pageNumber=${pageNumber}&pageSize=${pageSize}${searchString}`
    );
  };