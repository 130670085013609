import React, { useState } from "react";
import * as C from "./style";
import logo from "../../../../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";

interface NavbarProps {
  wattwise: React.RefObject<HTMLInputElement>;
  produtos: React.RefObject<HTMLInputElement>;
  contato: React.RefObject<HTMLInputElement>;
  scroll: (ref: React.RefObject<HTMLInputElement>) => void;
}

const Navbar: React.FC<NavbarProps> = ({
  wattwise,
  produtos,
  contato,
  scroll,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpen = async () => {
    setOpen(!open);
  };

  const handleScroll = async (ref: React.RefObject<HTMLInputElement>) => {
    await handleOpen();
    scroll(ref);
  };

  return (
    <>
      <C.NavbarWrapper>
        <C.Logo
          src={logo}
          alt="logoWattwise"
          onClick={() => (window.location.href = "/")}
        />
        <C.LinksWrapperDesktop>
          <C.Links onClick={() => handleScroll(wattwise)}>Watt Wise</C.Links>
          <C.Links onClick={() => handleScroll(produtos)}>
            Produtos e soluções
          </C.Links>
          <C.Links onClick={() => handleScroll(contato)}>Contato</C.Links>
          <C.LinksButton $outlined onClick={() => navigate("/Login/Gerador")}>
            Gerador
          </C.LinksButton>
          <C.LinksButton
            $outlined
            onClick={() => navigate("/Login/Colaborador")}
          >
            Colaborador
          </C.LinksButton>
          <C.LinksButton $outlined onClick={() => navigate("/Parceiro")}>
            Parceiro
          </C.LinksButton>
          <C.LinksButton onClick={() => navigate("/Login/Cliente")}>
            Cliente
          </C.LinksButton>
        </C.LinksWrapperDesktop>
        <C.HamburguerButton onClick={handleOpen}>
          {open ? (
            <ClearIcon sx={{ fontSize: 30 }} />
          ) : (
            <MenuIcon sx={{ fontSize: 30 }} />
          )}
        </C.HamburguerButton>
      </C.NavbarWrapper>
      {open && (
        <>
          <C.WrapperBlock />
          <C.LinksWrapperMobile>
            <C.LinkButtonWrapper onClick={() => handleScroll(wattwise)}>
              <C.Links>Watt Wise</C.Links>
            </C.LinkButtonWrapper>
            <C.LinkButtonWrapper onClick={() => handleScroll(produtos)}>
              <C.Links>Produtos e soluções</C.Links>
            </C.LinkButtonWrapper>
            <C.LinkButtonWrapper onClick={() => handleScroll(contato)}>
              <C.Links>Contato</C.Links>
            </C.LinkButtonWrapper>
            <C.LinksButton $outlined onClick={() => navigate("/Login/Gerador")}>
              Gerador
            </C.LinksButton>
            <C.LinksButton
              $outlined
              onClick={() => navigate("/Login/Colaborador")}
            >
              Colaborador
            </C.LinksButton>
            <C.LinksButton $outlined onClick={() => navigate("/Parceiro")}>
              Parceiro
            </C.LinksButton>
            <C.LinksButton onClick={() => navigate("/Login/Cliente")}>
              Cliente
            </C.LinksButton>
          </C.LinksWrapperMobile>
        </>
      )}
    </>
  );
};

export default Navbar;
