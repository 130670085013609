import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const CardTitle = styled.h3`
  color: #333;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
`;

export const ReportsArea = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 32px;
    align-items: flex-end;
    flex-wrap: wrap;
`;

export const CheckboxGroup = styled.div`
    width: 100%;
    display: flex;
    gap: 32px;
    align-items: flex-end;
    flex-wrap: wrap;
`;