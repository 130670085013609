import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Autocomplete, Chip, MenuItem, Stack, TextField } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CircleCheckbox from "../../../../../components/CircleCheckBox";
import RegisterCard from "../../../../../components/RegisterCard";
import {
  EnumConvenio,
  EnumConvenioNumericMap,
} from "../../../../../enums/EnumConvenio.enum";
import {
  EnumCupomBoasVindas,
  EnumCupomBoasVindasNumericMap,
} from "../../../../../enums/EnumCupomBoasVindas.enum";
import { EnumTipoChavePix } from "../../../../../enums/EnumTipoChavePix.enum";
import {
  EnumTipoParceiro,
  EnumTipoParceiroNumericMap,
} from "../../../../../enums/EnumTipoParceiro.enum";
import { useAuth } from "../../../../../hooks/useAuth";
import { ICreateResponsavelLegal } from "../../../../../models/ResponsavelLegal";
import * as D from "../../../../../styles/appComponents";
import * as C from "./style";
import { ICreateAssociado } from "../../../../../models/Associado";
import * as service from "../../../../../services/api/CaptadorService";
import * as colaboradorServices from "../../../../../services/api/ColaboradorService";
import { AxiosError } from "axios";
import Swal from "sweetalert2";
import { IBackofficeListItem } from "../../../../../models/Colaborador";

const NovoAssociado = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  //Dados do contrato
  const [razaoSocial, setRazaoSocial] = useState<string>("");
  const [cnpj, setCnpj] = useState<string>("");

  const [empresaCep, setEmpresaCep] = useState<string>("");
  const [empresaUf, setEmpresaUf] = useState<string>("");
  const [empresaCidade, setEmpresaCidade] = useState<string>("");
  const [empresaBairro, setEmpresaBairro] = useState<string>("");
  const [empresaLogradouro, setEmpresaLogradouro] = useState<string>("");
  const [empresaNumero, setEmpresaNumero] = useState<string>("");
  const [empresaComplemento, setEmpresaComplemento] = useState<string>("");

  //Informações do responsável legal
  const [contacts, setContacts] = useState<ICreateResponsavelLegal[]>([
    {
      nome: "",
      email: "",
      celular: "",
      cpf: "",
      cep: "",
      uf: "",
      cidade: "",
      bairro: "",
      numero: "",
      complemento: "",
      logradouro: "",
    },
  ]);

  const [backoffices, setBackoffices] = useState<IBackofficeListItem[]>([]);
  const [selectedBackoffice, setSelectedBackoffice] = useState<
    string | undefined
  >();

  const getBackoffices = () => {
    colaboradorServices
      .GetBackoffices()
      .then((response) => {
        setBackoffices(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os backoffices.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const handleChange = (
    index: number,
    field: keyof ICreateResponsavelLegal,
    value: string
  ) => {
    const updatedContacts = contacts.map((contact, i) =>
      i === index ? { ...contact, [field]: value } : contact
    );
    setContacts(updatedContacts);
  };

  const addContact = () => {
    setContacts([
      ...contacts,
      {
        nome: "",
        email: "",
        celular: "",
        cpf: "",
        cep: "",
        uf: "",
        cidade: "",
        bairro: "",
        numero: "",
        complemento: "",
        logradouro: "",
      },
    ]);
  };

  const removeContact = (index: number) => {
    if (contacts.length > 1) {
      setContacts(contacts.filter((_, i) => i !== index));
    }
  };

  const [nomeResponsavelComunicacao, setNomeResponsavelComunicacao] =
    useState<string>("");
  const [emailResponsavelComunicacao, setEmailResponsavelComunicacao] =
    useState<string>("");
  const [celularResponsavelComunicacao, setCelularResponsavelComunicacao] =
    useState<string>("");

  //Comissao e dados para pagamentos
  const [porcentualComissao, setPorcentualComissao] = useState<string>("");
  const [comissaoPorExtenso, setComissaoPorExtenso] = useState<string>("");
  const [vigenciaContrato, setVigenciaContrato] = useState<string>("");

  const [banco, setBanco] = useState<string>("");
  const [agencia, setAgencia] = useState<string>("");
  const [conta, setConta] = useState<string>("");
  const [cnpjFavorecido, setCnpjFavorecido] = useState<string>("");
  const [favorecido, setFavorecido] = useState<string>("");

  const [chavePix, setChavePix] = useState<string>("");
  const [selectedTipoChavePix, setSelectedTipoChavePix] = useState<number>(0);

  const handleChipClick = (chipId: number) => () => {
    setSelectedTipoChavePix(chipId);
  };

  //Informações do associado
  const [tipoAssociado, setTipoAssociado] = useState<string>("");
  const [convenio, setConvenio] = useState<string>("");

  //Status contrato
  const [selectedStatus, setSelectedStatus] = useState<number>();
  const [ultimaAlteracaoStatus, setUltimaAlteracaoStatus] = useState<string>();
  const [dataAssinaturaContrato, setDataAssinaturaContrato] =
    useState<string>();
  const [uuidContrato, setUuidContrato] = useState<string>("");

  //Dados de acesso
  const [cupomBoasVindas, setCupomBoasVindas] = useState<string>("");
  const [emissorNotaFiscal, setEmissorNotaFiscal] = useState<boolean>();
  const [login, setLogin] = useState<string>("");
  const [senha, setSenha] = useState<string>("");
  const [url, setUrl] = useState<string>("");

  const handleChangeEmissorNotaFiscal = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value == "1";
    setEmissorNotaFiscal(value);
  };

  const createAssociado = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    var createAssociadoData: ICreateAssociado = {
      razaoSocial: razaoSocial,
      cnpj: cnpj,
      cep: empresaCep,
      uf: empresaUf,
      cidade: empresaCidade,
      bairro: empresaBairro,
      logradouro: empresaLogradouro,
      numero: empresaNumero,
      complemento: empresaComplemento,
      createResponsavelLegalCommand: contacts,
      createResponsavelComunicacaoCommand: {
        nome: nomeResponsavelComunicacao,
        email: emailResponsavelComunicacao,
        celular: celularResponsavelComunicacao,
      },
      percentualComissao: porcentualComissao,
      comissaoPorExtenso: comissaoPorExtenso,
      vigenciaContrato: vigenciaContrato
        ? new Date(vigenciaContrato)
        : undefined,
      banco: banco,
      agencia: agencia,
      conta: conta,
      cnpjFavorecido: cnpjFavorecido,
      favorecido: favorecido,
      tipoChavePix: selectedTipoChavePix,
      chavePix: chavePix,
      enumConvenio: convenio !== "" ? Number(convenio) : undefined,
      backofficeId: selectedBackoffice,
      statusContrato: selectedStatus,
      user: login,
      password: senha,
      userEmail: "",
      userCelular: "",
      linkCaptador: url,
      ultimaAlteracaoStatus: ultimaAlteracaoStatus
        ? new Date(ultimaAlteracaoStatus)
        : undefined,
      dataAssinaturaContrato: dataAssinaturaContrato
        ? new Date(dataAssinaturaContrato)
        : undefined,
      uuidContrato: uuidContrato,
      cupomBoasVindas:
        cupomBoasVindas !== "" ? Number(cupomBoasVindas) : undefined,
      isMaster: tipoAssociado
        ? Number(tipoAssociado) ==
          EnumTipoParceiroNumericMap[EnumTipoParceiro.associadoMaster]
        : undefined,
    };

    await service
      .CreateAssociado(createAssociadoData)
      .then(async (response) => {
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Adicionado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
        navigate("/Parceiro/Associados");
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    getBackoffices();
  }, []);

  return (
    <C.Container onSubmit={createAssociado}>
      <RegisterCard title="Dados do contrato">
        <C.FWStack direction={"row"} spacing={2}>
          <TextField
            label="Razão social"
            fullWidth
            value={razaoSocial}
            onChange={(e) => setRazaoSocial(e.target.value)}
          />
          <TextField
            label="CNPJ"
            fullWidth
            value={cnpj}
            onChange={(e) => setCnpj(e.target.value)}
          />
          <TextField
            label="CEP"
            fullWidth
            value={empresaCep}
            onChange={(e) => setEmpresaCep(e.target.value)}
          />
          <TextField
            label="UF"
            value={empresaUf}
            onChange={(e) => setEmpresaUf(e.target.value)}
          />
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2}>
          <TextField
            label="Cidade"
            fullWidth
            value={empresaCidade}
            onChange={(e) => setEmpresaCidade(e.target.value)}
          />
          <TextField
            label="Bairro"
            fullWidth
            value={empresaBairro}
            onChange={(e) => setEmpresaBairro(e.target.value)}
          />
          <TextField
            label="Logradouro"
            fullWidth
            value={empresaLogradouro}
            onChange={(e) => setEmpresaLogradouro(e.target.value)}
          />
          <TextField
            label="Número"
            type="number"
            sx={{ width: "40%" }}
            value={empresaNumero}
            onChange={(e) => setEmpresaNumero(e.target.value)}
          />
          <TextField
            label="Complemento"
            fullWidth
            value={empresaComplemento}
            onChange={(e) => setEmpresaComplemento(e.target.value)}
          />
        </C.FWStack>
      </RegisterCard>
      <RegisterCard title="Informações do responsável legal">
        {contacts.map((contact, index) => (
          <Stack key={index} spacing={2}>
            <Stack
              direction={"row"}
              spacing={2}
              marginTop={index > 0 ? "30px" : "none"}
            >
              <TextField
                label="Nome do responsável legal"
                fullWidth
                value={contact.nome}
                onChange={(e) => handleChange(index, "nome", e.target.value)}
              />
              <TextField
                label="E-mail"
                fullWidth
                value={contact.email}
                onChange={(e) => handleChange(index, "email", e.target.value)}
              />
              <TextField
                label="Celular"
                fullWidth
                value={contact.celular}
                onChange={(e) => handleChange(index, "celular", e.target.value)}
              />
              <TextField
                label="CPF"
                fullWidth
                value={contact.cpf}
                onChange={(e) => handleChange(index, "cpf", e.target.value)}
              />
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <TextField
                label="CEP"
                fullWidth
                value={contact.cep}
                onChange={(e) => handleChange(index, "cep", e.target.value)}
              />
              <TextField
                label="UF"
                sx={{ width: "40%" }}
                value={contact.uf}
                onChange={(e) => handleChange(index, "uf", e.target.value)}
              />
              <TextField
                label="Cidade"
                fullWidth
                value={contact.cidade}
                onChange={(e) => handleChange(index, "cidade", e.target.value)}
              />
              <TextField
                label="Bairro"
                fullWidth
                value={contact.bairro}
                onChange={(e) => handleChange(index, "bairro", e.target.value)}
              />
              <TextField
                label="Número"
                sx={{ width: "40%" }}
                value={contact.numero}
                onChange={(e) => handleChange(index, "numero", e.target.value)}
              />
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <TextField
                label="Complemento"
                fullWidth
                value={contact.complemento}
                onChange={(e) =>
                  handleChange(index, "complemento", e.target.value)
                }
              />
              <TextField
                label="Logradouro"
                fullWidth
                value={contact.logradouro}
                onChange={(e) =>
                  handleChange(index, "logradouro", e.target.value)
                }
              />
            </Stack>
            {contacts.length > 1 && (
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"flex-end"}
                marginTop={2}
              >
                <D.ContainedButton
                  startIcon={<RemoveCircleOutlineIcon />}
                  onClick={() => removeContact(index)}
                  variant="contained"
                  $color="blue"
                >
                  Remover responsável legal
                </D.ContainedButton>
              </Stack>
            )}
          </Stack>
        ))}
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton
            startIcon={<AddIcon />}
            onClick={addContact}
            $color="lightpink"
          >
            Adicionar outro responsável legal
          </D.ContainedButton>
        </C.FWStack>
        <C.TitleArea>
          <h2>Responsável pela comunicação</h2>
        </C.TitleArea>
        <C.FWStack direction={"row"} spacing={2}>
          <TextField
            label="Nome"
            fullWidth
            value={nomeResponsavelComunicacao}
            onChange={(e) => setNomeResponsavelComunicacao(e.target.value)}
          />
          <TextField
            label="Email"
            fullWidth
            value={emailResponsavelComunicacao}
            onChange={(e) => setEmailResponsavelComunicacao(e.target.value)}
          />
          <TextField
            label="Celular"
            fullWidth
            value={celularResponsavelComunicacao}
            onChange={(e) => setCelularResponsavelComunicacao(e.target.value)}
          />
        </C.FWStack>
      </RegisterCard>
      <RegisterCard title="Comissão e dados para pagamentos">
        <C.FWStack direction={"row"} spacing={2}>
          <TextField
            label="Porcentual de comissão"
            fullWidth
            type="number"
            value={porcentualComissao}
            onChange={(e) => setPorcentualComissao(e.target.value)}
          />
          <TextField
            label="Comissão por extenso"
            fullWidth
            value={comissaoPorExtenso}
            onChange={(e) => setComissaoPorExtenso(e.target.value)}
          />
          <D.DateTextfield
            label="Vigência do contrato"
            type="date"
            fullWidth
            value={vigenciaContrato}
            onChange={(e) => setVigenciaContrato(e.target.value)}
          />
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2}>
          <C.Subtitle>Dados para pagamento de comissão*</C.Subtitle>
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={16}>
          <C.MWDiv>
            <C.Subtitle>Dados bancários*</C.Subtitle>
            <C.Text>Insira seus dados bancários</C.Text>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField
                label="Banco"
                fullWidth
                value={banco}
                onChange={(e) => setBanco(e.target.value)}
              />
              <TextField
                label="Agência"
                fullWidth
                value={agencia}
                onChange={(e) => setAgencia(e.target.value)}
              />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField
                label="Conta"
                fullWidth
                value={conta}
                onChange={(e) => setConta(e.target.value)}
              />
              <TextField
                label="CNPJ do favorecido"
                fullWidth
                value={cnpjFavorecido}
                onChange={(e) => setCnpjFavorecido(e.target.value)}
              />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2}>
              <C.FWDiv>
                <TextField
                  label="Favorecido"
                  fullWidth
                  value={favorecido}
                  onChange={(e) => setFavorecido(e.target.value)}
                />
                <C.FWDiv></C.FWDiv>
              </C.FWDiv>
            </C.FWStack>
          </C.MWDiv>
          <C.MWDiv>
            <C.Subtitle>Pix*</C.Subtitle>
            <C.Text>Insira sua chave pix</C.Text>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField
                label="Chave PIX"
                fullWidth
                value={chavePix}
                onChange={(e) => setChavePix(e.target.value)}
              />
            </C.FWStack>
            <C.Text>Qual dado você quer usar?</C.Text>
            <C.FWStack direction="row" spacing={2}>
              {Object.values(EnumTipoChavePix).map((label, index) => (
                <Chip
                  key={label}
                  label={label}
                  onClick={handleChipClick(index)}
                  color={selectedTipoChavePix === index ? "primary" : "default"}
                />
              ))}
            </C.FWStack>
          </C.MWDiv>
        </C.FWStack>
      </RegisterCard>
      <RegisterCard title="Informações do associado">
        <C.FWStack direction={"row"} spacing={2}>
          <TextField
            label="Tipo de associado"
            select
            fullWidth
            value={tipoAssociado}
            onChange={(e) => {
              setTipoAssociado(e.target.value);
            }}
          >
            {Object.values(EnumTipoParceiro)
              .filter((value) => value !== EnumTipoParceiro.parceiroMaster)
              .map((value) => (
                <MenuItem key={value} value={EnumTipoParceiroNumericMap[value]}>
                  {value}
                </MenuItem>
              ))}
          </TextField>
          {/* <TextField label="Parceiro master" select  fullWidth /> */}
          <TextField
            label="Convênio"
            select
            fullWidth
            value={convenio}
            onChange={(e) => {
              setConvenio(e.target.value);
            }}
          >
            {Object.values(EnumConvenio).map((value) => (
              <MenuItem key={value} value={EnumConvenioNumericMap[value]}>
                {value}
              </MenuItem>
            ))}
          </TextField>
          <Autocomplete
            options={backoffices}
            fullWidth
            noOptionsText="Nenhum backoffice encontrado"
            getOptionLabel={(option) => option.nome}
            renderInput={(params) => (
              <TextField {...params} label="Backoffice" />
            )}
            defaultValue={undefined}
            onChange={(event, newValue) => {
              setSelectedBackoffice(newValue?.id);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </C.FWStack>
      </RegisterCard>
      <RegisterCard title="Status do contrato">
        <C.StatusArea>
          <label>Status atual:</label>
          <Stack direction={"row"} spacing={2}>
            <CircleCheckbox
              label="Lead"
              color="yellow"
              id={1}
              selected={selectedStatus === 0}
              onSelect={() => setSelectedStatus(0)}
            />
            <CircleCheckbox
              label="Aguardando assinatura de contrato"
              color="blue"
              id={0}
              selected={selectedStatus === 1}
              onSelect={() => setSelectedStatus(1)}
            />
            <CircleCheckbox
              label="Contrato assinado"
              color="green"
              id={2}
              selected={selectedStatus === 2}
              onSelect={() => setSelectedStatus(2)}
            />
            <CircleCheckbox
              label="Aguardando assinatura de distrato"
              color="red"
              id={3}
              selected={selectedStatus === 3}
              onSelect={() => setSelectedStatus(3)}
            />
            <CircleCheckbox
              label="Cancelado"
              color="purple"
              id={4}
              selected={selectedStatus === 4}
              onSelect={() => setSelectedStatus(4)}
            />
          </Stack>
        </C.StatusArea>
        <C.FWStack direction={"row"} spacing={2}>
          <D.DateTextfield
            label="Última alteração de status"
            type="date"
            fullWidth
            value={ultimaAlteracaoStatus}
            onChange={(e) => setUltimaAlteracaoStatus(e.target.value)}
          />
          <D.DateTextfield
            label="Data de assinatura do contrato"
            type="date"
            fullWidth
            value={dataAssinaturaContrato}
            onChange={(e) => setDataAssinaturaContrato(e.target.value)}
          />
          <TextField
            label="UUID do contrato"
            fullWidth
            value={uuidContrato}
            onChange={(e) => setUuidContrato(e.target.value)}
          />
        </C.FWStack>
        {/* <C.FWStack direction={"row"} justifyContent={"space-between"}>
          <C.FWStack direction={"row"} spacing={2}>
            <D.OutlineButton $color="blue" startIcon={<DownloadIcon />}>
              Baixar contrato
            </D.OutlineButton>
            <D.ContainedButton $color={"lightpink"}>
              Gerar contrato
            </D.ContainedButton>
            <D.ContainedButton $color={"lightpink"}>
              Gerar distrato
            </D.ContainedButton>
          </C.FWStack>
        </C.FWStack> */}
      </RegisterCard>
      {/* <RegisterCard title="Anexo logo do parceiro">
        <C.FWStack direction={"row"} spacing={8}>
          <C.MWDiv>
            <C.Title>
              <FileUploadIcon />
              <h2>Upload de arquivos</h2>
            </C.Title>
            <C.FWStack direction={"row"} spacing={2}>
              <C.FWStack direction={"column"} spacing={2} textAlign={"center"}>
                <C.ArchiveArea>
                  <img src={File} alt="anexado" />
                  <p>SVG</p>
                </C.ArchiveArea>
                <text>Logo anexado</text>
              </C.FWStack>
              <C.FWStack direction={"column"} spacing={2} textAlign={"center"}>
                <C.ArchiveArea>
                  <img src={EmptyFile} alt="anexado" />
                  <p>PNG</p>
                </C.ArchiveArea>
                <text>Anexar logo</text>
              </C.FWStack>
              <C.FWStack direction={"column"} spacing={2} textAlign={"center"}>
                <C.ArchiveArea>
                  <img src={EmptyFile} alt="anexado" />
                  <p>PDF</p>
                </C.ArchiveArea>
                <text>Anexar logo</text>
              </C.FWStack>
            </C.FWStack>
          </C.MWDiv>
          <C.MWDiv>
            <C.LightBWArea>
              <D.DefaultTable
                disableColumnResize
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rows2}
                columns={columns2}
                hideFooter
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
              />
            </C.LightBWArea>
          </C.MWDiv>
        </C.FWStack>
      </RegisterCard> */}
      <RegisterCard title="Dados de acesso">
        <C.FWStack direction={"row"} spacing={2}>
          <TextField
            label="Login"
            sx={{ width: "80%" }}
            value={login}
            onChange={(e) => setLogin(e.target.value)}
          />
          <TextField
            label="Senha"
            sx={{ width: "80%" }}
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
          />
          <TextField
            select
            label="Cupom de boas vindas destinado para?"
            fullWidth
            value={cupomBoasVindas}
            onChange={(e) => setCupomBoasVindas(e.target.value)}
          >
            {Object.values(EnumCupomBoasVindas).map((value) => (
              <MenuItem
                key={value}
                value={EnumCupomBoasVindasNumericMap[value]}
              >
                {value}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Emissor de nota fiscal?"
            sx={{ width: "70%" }}
            value={
              emissorNotaFiscal !== undefined
                ? emissorNotaFiscal
                  ? "1"
                  : "0"
                : ""
            }
            onChange={handleChangeEmissorNotaFiscal}
          >
            <MenuItem key={1} value={1}>
              Sim
            </MenuItem>
            <MenuItem key={0} value={0}>
              Não
            </MenuItem>
          </TextField>
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2}>
          <TextField
            label="Link do parceiro"
            fullWidth
            InputProps={{
              startAdornment: (
                <D.StartTextTextfield>
                  wattwise.app.br/Convida/
                </D.StartTextTextfield>
              ),
            }}
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </C.FWStack>
      </RegisterCard>
      <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton type="submit">Cadastrar</D.ContainedButton>
      </C.FWStack>
    </C.Container>
  );
};

export default NovoAssociado;
