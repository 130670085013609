import {
  Button,
  IconButton,
  Pagination,
  Stack,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import { ColorType } from "../types/color";

export const DefaultTable = styled(DataGrid)`
  border: none;
  width: 100%;

  & .MuiDataGrid-columnHeaders {
    background-color: none;
  }
`;

export const GridField = styled.div<{ $alignCenter?: boolean }>`
  display: flex;
  gap: 10px;
  width: 100%;
  height: 100%;
  text-align: start;
  align-items: center;
  justify-content: ${(props) => (props.$alignCenter ? "center" : "start")};
`;

export const DefaultSearchInput = styled(TextField)`
  & label.Mui-focused {
    color: #6b767f;
  }

  & .MuiInput-underline:after {
    border-bottom-color: green;
  }

  & .MuiOutlinedInput-root {
    & fieldset {
    }

    &:hover fieldset {
      border-color: rgba(210, 66, 147, 0.8);
    }

    &.Mui-focused fieldset {
      border-color: rgba(210, 66, 147, 0.8);
    }
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const DefaultGridButton = styled.button`
  border-radius: 10px;
  border: 2px solid #d24293;
  color: #d24293;
  background-color: white;
  cursor: pointer;
  padding: 8px 12px;
  font-weight: 600;
  font-size: 14px;

  &:hover {
    background-color: #d24293;
    color: white;
  }
`;

export const DefaultPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    border-radius: 8px;
    font-weight: 600;
    font-size: 13px;
    color: #333333;
  }

  .Mui-selected {
    color: white;
    background-color: #d24293;
    border-radius: 8px;

    &:hover {
      background-color: #bf3d87;
    }
  }
`;

export const CardDataItemMobile = styled.div<{ $center?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1 1 48%;
  max-width: 48%;
  gap: 2px;
  align-items: ${(props) => (props.$center ? "center" : "flex-start")};

  label {
    color: #222;
    font-size: 14px;
    font-weight: 300;
  }

  p {
    margin: 0;
    color: #222;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const CardDataAreaMobile = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
`;

export const CardDataMobile = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex: 1 1 48%;
  flex-wrap: wrap;
  max-width: 48%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 3px 3px 6px 6px rgba(0, 0, 0, 0.06);
  padding: 15px 10px;

  @media (max-width: 568px) {
    max-width: 100%;
  }
`;

export const Circle = styled.div<{
  color: ColorType;
}>`
  width: 24px;
  height: 24px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: ${({ color }) => {
    switch (color) {
      case "blue":
        return "blue";
      case "yellow":
        return "yellow";
      case "green":
        return "green";
      case "orange":
        return "#DF7A43";
      case "red":
        return "red";
      case "purple":
        return "purple";
      default:
        return "transparent";
    }
  }};
`;

export const DataArea = styled.div<{ $align: "left" | "right" }>`
  background-color: white;
  box-shadow: 6px 6px 6px #0000000f;
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: ${({ $align }) => {
    switch ($align) {
      case "left":
        return "flex-start";
      case "right":
        return "flex-end";
      default:
        return "transparent";
    }
  }};
  gap: 20px;
`;

export const ContainedButton = styled(Button)<{
  $color?: "blue" | "pink" | "lightpink" | "yellow";
}>`
  background-color: ${({ $color }) => {
    switch ($color) {
      case "blue":
        return "#144FE7";
      case "pink":
        return "#D24293";
      case "lightpink":
        return "#F36A7B";
      case "yellow":
        return "#F6A648";
      default:
        return "#d24293";
    }
  }};
  color: white;
  padding: 5px 20px;
  min-height: 40px;

  &:hover {
    background-color: ${({ $color }) => {
      switch ($color) {
        case "blue":
          return "#144FE7";
        case "pink":
          return "#a33473";
        case "lightpink":
          return "#c95967";
        case "yellow":
          return "#cf8a3a";
        default:
          return "#a33473";
      }
    }};
  }
`;

export const OutlineButton = styled(Button)<{
  $color?: "blue" | "pink" | "lightpink" | "yellow" | "red";
}>`
  border: 1px solid;
  border-color: ${({ $color }) => {
    switch ($color) {
      case "blue":
        return "#144FE7";
      case "pink":
        return "#a33473";
      case "lightpink":
        return "#c95967";
      case "yellow":
        return "#cf8a3a";
      case "red":
        return "#D60D0D";
      default:
        return "#a33473";
    }
  }};
  color: ${({ $color }) => {
    switch ($color) {
      case "blue":
        return "#144FE7";
      case "pink":
        return "#a33473";
      case "lightpink":
        return "#c95967";
      case "yellow":
        return "#cf8a3a";
      case "red":
        return "#D60D0D";
      default:
        return "#a33473";
    }
  }};
  padding: 5px 20px;

  &:hover {
    background-color: #e3e3e3;
  }
`;

export const DateTextfield = styled(TextField)`
  input[type="date"]:not(:focus):in-range::-webkit-datetime-edit-year-field,
  input[type="date"]:not(:focus):in-range::-webkit-datetime-edit-month-field,
  input[type="date"]:not(:focus):in-range::-webkit-datetime-edit-day-field,
  input[type="date"]:not(:focus):in-range::-webkit-datetime-edit-text {
    color: transparent;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;

    &:hover {
      transform: scale(150%);
    }
  }
`;

export const GridImage = styled.img<{ $disable?: boolean }>`
  filter: ${(props) => (props.$disable ? "grayscale(1)" : "none")};
  cursor: ${(props) => (props.$disable ? "default" : "pointer")};
`;

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const ModalArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  height: 80%;
  border-radius: 10px;
  background: #f8f8f8;
  padding: 20px;
  overflow-y: auto;

  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 425px) {
    padding: 10px;
  }
`;

export const ModalCard = styled.div`
  border-radius: 10px;
  background: #fff;
  width: 100%;
  box-shadow: 6px 6px 6px #0000000f;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
`;

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
    color: #222222;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const CloseButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  color: gray;
  aspect-ratio: 1/1;
`;

export const ModalLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
`;

export const ModalItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: 18%;
  gap: 10px;

  label {
    color: #222222;
    font-size: 16px;
    font-weight: 400;
  }

  p {
    margin: 0;
    color: #222222;
    font-size: 14px;
    font-weight: 300;
  }
`;

export const ModalTextIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    margin: 0;
    color: #222222;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
`;

export const ModalStatusArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
`;

export const ModalStatus = styled.div`
  display: flex;
  flex: 1;
  flex-basis: auto;
  gap: 10px;
`;

export const ModalInputsArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
`;

export const ModalInput = styled(TextField)`
  flex: 1;
  flex-basis: 49%;
`;

export const ModalButtonsArea = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
`;

export const ModalBillsButton = styled(Button)`
  background-color: #107c41;
  color: white;
  padding: 0 20px;

  &:hover {
    background-color: #0d6636;
  }

  @media (max-width: 768px) {
    flex: 1;
    flex-basis: 48%;
  }
`;

export const ModalDownloadContractButton = styled(Button)`
  color: #107c41;
  border-color: #107c41;

  &:hover {
    background-color: #107c41;
    color: white;
    border-color: #107c41;
  }

  @media (max-width: 768px) {
    flex: 1;
    flex-basis: 48%;
  }
`;

export const ModalGridDownloadButton = styled(Button)`
  color: #144fe7;
  border-color: #144fe7;

  &:hover {
    background-color: #144fe7;
    color: white;
    border-color: #144fe7;
  }

  @media (max-width: 768px) {
    font-size: 10px;

    span {
      margin-right: 2px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }
`;

export const ModalPaginationArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ModalSaveButton = styled(Button)`
  background-color: #d24293;
  color: white;

  &:hover {
    background-color: #bf3b86;
  }

  @media (max-width: 768px) {
    flex: 1;
    flex-basis: 48%;
  }
`;

export const ModalDocumentButton = styled(Button)`
  background-color: #144fe7;
  color: white;

  &:hover {
    background-color: #1244c4;
  }

  @media (max-width: 768px) {
    flex: 1;
    flex-basis: 48%;
  }
`;

export const VisuallyHiddenInput = styled.input`
  display: none;
`;

export const ModalGridDeleteButton = styled(Button)`
  color: #d60d0d;
  border-color: #d60d0d;

  &:hover {
    background-color: #d60d0d;
    color: white;
    border-color: #d60d0d;
  }

  @media (max-width: 768px) {
    font-size: 10px;

    span {
      margin-right: 2px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }
`;

export const ModalGridEditButton = styled(Button)`
  color: #cf8a3a;
  border-color: #cf8a3a;

  &:hover {
    background-color: #cf8a3a;
    color: white;
    border-color: #cf8a3a;
  }

  @media (max-width: 768px) {
    font-size: 10px;

    span {
      margin-right: 2px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }
`;

export const StartTextTextfield = styled.span`
  color: #b3b3b3;
  margin-right: 10px;
`;

export const FWStack = styled(Stack)`
  width: 100%;
  transition: all ease 0.2s;
`;

export const LinkClick = styled.span`
  font-size: 14px;
  cursor: pointer;

  &:hover {
    transform: scale(105%);
    color: blue;
  }
`;

export const DefaultColorText = styled.span`
  color: #bcbcbc;
`;
