import styled from "styled-components";
import { Skeleton } from "@mui/material";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  padding: 20px;

  @media (max-width: 1024px) {
  }
`;

export const StatusArea = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  flex-wrap: wrap;
`;

export const Content = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FiltersArea = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  flex-wrap: wrap;
`;

export const SkeletonStatusWrapper = styled(Skeleton)`
  display: flex;
  flex: 1;
  flex-basis: 18%;
  height: 70px;

  @media (max-width: 1024px) {
  }
`;

export const SkeletonContentWrapper = styled(Skeleton)`
  display: flex;
  width: 100%;
  height: calc(100% - 70px);

  @media (max-width: 1024px) {
  }
`;

export const SkeletonNewButton = styled(Skeleton)`
  width: 140px;
  height: 40px;

  @media (max-width: 1024px) {
  }
`;

export const SkeletonPaginatorButton = styled(Skeleton)`
  width: 65px;
  height: 40px;

  @media (max-width: 1024px) {
  }
`;

export const SkeletonSearchButton = styled(Skeleton)`
  width: 200px;
  height: 40px;

  @media (max-width: 1024px) {
  }
`;

export const SkeletonGrid = styled(Skeleton)`
  width: 100%;
  height: 50px;
`;
