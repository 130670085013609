import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { AxiosError } from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import parceiro from "../../../../assets/Login/imgParceiro.svg";
import Login from "../../../../components/Login";
import * as C from "../../../../components/Login/style";
import { useAuth } from "../../../../hooks/useAuth";
import { ILoginParams } from "../../../../models/Usuario";
import * as services from "../../../../services/api/UsuarioService";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const topics: string[] = [
  "Processos otimizados para aumentar sua produtividade.",
  "Design moderno e amigável para uma experiência mais fluida.",
  "Navegue com facilidade em nossa  interface intuitiva.",
];

const LoginParceiro: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { login } = useAuth();

  const [showLogin, setShowLogin] = useState(false);

  const handleClickShowLogin = () => setShowLogin((show) => !show);

  const handleMouseDownLogin = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await setLoading(true);

    var loginParams: ILoginParams = {
      user,
      password,
    };

    await services
      .LoginCaptador(loginParams)
      .then(async (response: any) => {
        await login(response.data);

        navigate("/Parceiro/Leads");
        setLoading(false);

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Bem vindo!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao fazer o login.";

        setLoading(false);
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  return (
    <>
      <Login
        title="parceiro"
        topics={topics}
        imageSrc={parceiro}
        formTitle="Que bom ter você com a gente!"
        formSubtitle="Faça seu login abaixo"
      >
        <C.LoginForm onSubmit={handleLogin}>
          <C.LoginInput
            label="Login"
            variant="outlined"
            required
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />
          <C.LoginInput
            label="Senha"
            variant="outlined"
            type={showLogin ? "text" : "password"}
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowLogin}
                    onMouseDown={handleMouseDownLogin}
                    edge="end"
                  >
                    {showLogin ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <C.LinksArea>
            <div></div>
            <C.Links to="/Login/Parceiro/RecuperarSenha">
              Esqueceu sua senha?
            </C.Links>
          </C.LinksArea>
          <C.LoginButton type="submit" variant="contained">
            {loading ? (
              <CircularProgress color="secondary" size={30} />
            ) : (
              "Acessar"
            )}
          </C.LoginButton>
        </C.LoginForm>
      </Login>
    </>
  );
};

export default LoginParceiro;
