export enum EnumTipoDocumento {
  CPF = "CPF",
  CNPJ = "CNPJ",
}

export const EnumTipoDocumentoNumericMap = {
  [EnumTipoDocumento.CPF]: 0,
  [EnumTipoDocumento.CNPJ]: 1,
};

export const TipoDocumentoNumberMap: {
  [key: number]: EnumTipoDocumento;
} = {
  0: EnumTipoDocumento.CPF,
  1: EnumTipoDocumento.CNPJ,
};
