import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { createTheme, ThemeProvider as MuiProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { ThemeProvider as StyledProvider } from "styled-components";
import * as C from "../style";
import { ICaptadorConfig } from "../../../../../../../../models/CaptadorConfig";
import { Dispositivo } from "../Preview";

interface StaticLeadProps {
  theme: ICaptadorConfig;
  dispositivo: Dispositivo;
}

type StaticLeadPropsWithChildren = React.FC<
  React.PropsWithChildren<StaticLeadProps>
>;

const StaticLeadLayout: StaticLeadPropsWithChildren = ({
  theme,
  dispositivo,
  children,
}) => {
  const [cellPhone, setCellphone] = useState({
    ddd: "31",
    telefone: "99999-9999",
  });

  const [muiTheme, setMuiTheme] = useState(
    createTheme({
      palette: {
        primary: {
          main: theme.corPrimaria,
        },
        secondary: {
          main: theme.corSecundaria,
        },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: "700",
              color: theme.corPrimaria,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              color: "#333333",
              backgroundColor: "#ffffff",
            },
          },
        },
      },
    })
  );

  useEffect(() => {
    setMuiTheme(
      createTheme({
        palette: {
          primary: {
            main: theme.corPrimaria,
          },
          secondary: {
            main: theme.corSecundaria,
          },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                fontWeight: "700",
                color: theme.corPrimaria,
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                color: "#333333",
                backgroundColor: "#ffffff",
              },
            },
          },
        },
      })
    );

    const dddPart = theme.celular.slice(0, 2);
    const restOfNumber = theme.celular.slice(2);

    const formattedRest =
      restOfNumber.slice(0, 5) + "-" + restOfNumber.slice(5);

    setCellphone({
      ddd: dddPart,
      telefone: formattedRest,
    });

    // if (theme.temPixelFacebook) {
    //   import("react-facebook-pixel")
    //     .then((x) => x.default)
    //     .then((ReactPixel) => {
    //       ReactPixel.init(theme.cixelFacebook!);
    //       ReactPixel.pageView();
    //     });
    // }
  }, [theme]);

  return (
    <StyledProvider theme={theme}>
      <MuiProvider theme={muiTheme}>
        <C.Container $dispositivo={dispositivo}>
          <C.Navbar $dispositivo={dispositivo}>
            <img src={theme.logo} alt="Logo" />
            <C.Wpp>
              <WhatsAppIcon />
              <C.WppNumber $dispositivo={dispositivo}>
                <C.DDD>{cellPhone.ddd}</C.DDD> {cellPhone.telefone}
              </C.WppNumber>
            </C.Wpp>
          </C.Navbar>

          {children}
        </C.Container>
      </MuiProvider>
    </StyledProvider>
  );
};

export default StaticLeadLayout;
