import * as C from "./style";
import * as D from "../../../styles/appComponents";

const SkeletonDefaultPage = () => {
  return (
    <C.Container>
      <C.StatusArea>
        <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
        <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
        <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
        <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
        <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
      </C.StatusArea>
      <C.Content>
        <D.FWStack direction={"row"} justifyContent={"flex-end"}>
          <C.SkeletonNewButton animation="wave" variant="rounded" />
        </D.FWStack>
        <D.FWStack direction={"row"} justifyContent={"space-between"}>
          <C.SkeletonPaginatorButton animation="wave" variant="rounded" />
          <C.SkeletonSearchButton animation="wave" variant="rounded" />
        </D.FWStack>

        <C.SkeletonGrid animation="wave" variant="rounded" />
        <C.SkeletonGrid animation="wave" variant="rounded" />
        <C.SkeletonGrid animation="wave" variant="rounded" />
        <C.SkeletonGrid animation="wave" variant="rounded" />
        <D.FWStack direction={"row"} justifyContent={"flex-end"}>
          <C.SkeletonPaginatorButton animation="wave" variant="rounded" />
        </D.FWStack>

        <C.FiltersArea>
          <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
          <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
          <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
          <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
          <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
        </C.FiltersArea>
        <D.FWStack direction={"row"} justifyContent={"flex-end"}>
          <C.SkeletonSearchButton animation="wave" variant="rounded" />
        </D.FWStack>
      </C.Content>
    </C.Container>
  );
};

export default SkeletonDefaultPage;
