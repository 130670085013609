import { Button, Checkbox, CircularProgress, TextField } from "@mui/material";
import RegisterCard from "../../../../../../components/RegisterCard";
import { ICaptador } from "../../../../../../models/Captador";
import * as C from "../../style";
import { useEffect, useReducer, useState } from "react";
import ConfigImageInput from "../../../../../../components/ConfigImageInput";
import ColorInput from "../../../../../../components/ColorInput";
import Preview from "./components/Preview";
import {
  ICaptadorConfig,
  ICreateCaptadorConfig,
  IUpdateCaptadorConfig,
} from "../../../../../../models/CaptadorConfig";
import LogoDefault from "../../../../../../assets/logoWhite.png";
import BgDefault from "../../../../../../assets/bgWattWisePadrao.png";
import { useObjectState } from "../../../../../../hooks/useObjectState";
import * as captadorConfigServices from "../../../../../../services/api/CaptadorConfigService";
import Swal from "sweetalert2";
import { AxiosError } from "axios";

interface IConfigCaptadorProps {
  captador: ICaptador;
}

const ConfigCaptadorParceiro: React.FC<IConfigCaptadorProps> = ({
  captador,
}) => {
  const { state, setObject, updateObject } = useObjectState<ICaptadorConfig>({
    id: "",
    celular: "",
    corPrimaria: "#ffffff",
    corSecundaria: "#ffffff",
    corTextoBase: "#ffffff",
    corTextoDestaque: "#ffffff",
    textoBoasVindas: "",
    textoAgradecimento: "",
    textoAnexarConta: "",
    textoAgradecimentoAnexarConta: "",
    temPixelFacebook: false,
    pixelFacebook: "",
    temImagemComoConteudo: false,
    logo: "",
    background: null,
    contentImage: null,
    nome: "",
  });

  const { state: stateCreate, updateObject: updateObjectCreate } =
    useObjectState<ICreateCaptadorConfig>({
      idCaptador: captador.id,
      celular: "",
      corPrimaria: "#ffffff",
      corSecundaria: "#ffffff",
      corTextoBase: "#ffffff",
      corTextoDestaque: "#ffffff",
      textoBoasVindas: "",
      textoAgradecimento: "",
      textoAnexarConta: "",
      textoAgradecimentoAnexarConta: "",
      temPixelFacebook: false,
      pixelFacebook: "",
      temImagemComoConteudo: false,
      logo: new File([], ""),
      background: null,
      contentImage: null,
    });

  const {
    state: stateUpdate,
    setObject: setObjectUpdate,
    updateObject: updateObjectUpdate,
  } = useObjectState<IUpdateCaptadorConfig>({
    captadorConfigId: "",
    celular: null,
    corPrimaria: null,
    corSecundaria: null,
    corTextoBase: null,
    corTextoDestaque: null,
    textoBoasVindas: null,
    textoAgradecimento: null,
    textoAnexarConta: null,
    textoAgradecimentoAnexarConta: null,
    temPixelFacebook: null,
    pixelFacebook: null,
    temImagemComoConteudo: null,
    logo: null,
    background: null,
    contentImage: null,
  });

  const [temConfig, setTemConfig] = useState<boolean>(false);
  const [criandoConfig, setCriandoConfig] = useState<boolean>(false);
  const [editar, setEditar] = useState<boolean>(false);
  const [insideLoading, setInsideLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const [theme, setTheme] = useState<ICaptadorConfig>({
    id: "",
    celular: "31912345678",
    corPrimaria: "#D14294",
    corSecundaria: "#FFC700",
    corTextoBase: "#FFFFFF",
    corTextoDestaque: "#D14294",
    textoBoasVindas:
      "*A WATTWISE CONVIDA VOCÊ A ECONOMIZAR ATÉ 25% EM SUA TARIFA DE ENERGIA.*",
    textoAgradecimento:
      "VOCÊ ESTÁ A UM PASSO DE RECEBER ATÉ 25% DE DESCONTO NA SUA CONTA DE LUZ, EM BREVE TE RETORNAREMOS. *FIQUE ATENTO AO SEU EMAIL.*",
    textoAnexarConta:
      "*VOCÊ ESTÁ A UM PASSO DE ECONOMIZAR EM ATÉ 25% NA SUA CONTA DE ENERGIA.*",
    textoAgradecimentoAnexarConta:
      "OBRIGADO POR ANEXAR SUA CONTA! ENTRAREMOS EM CONTATO EM BREVE COM UMA PROPOSTA.",
    temPixelFacebook: false,
    temImagemComoConteudo: false,
    pixelFacebook: "",
    logo: LogoDefault,
    background: BgDefault,
    contentImage: "",
    nome: "",
  });

  const handleButtonNewConfig = () => {
    setTheme({
      ...stateCreate,
      id: "",
      nome: "",
      logo: "",
      background: "",
      contentImage: "",
    });
    setCriandoConfig(true);
  };

  const handleLogoChange = async (newImage: File | null) => {
    if (newImage) {
      if (criandoConfig) {
        updateObjectCreate({ logo: newImage });
      }

      if (editar) {
        updateObjectUpdate({ logo: newImage });
        var logoData: string = newImage ? await loadFile(newImage) : "";

        setTheme({ ...theme, logo: logoData });
      }
    }
  };

  const handleBgChange = async (newImage: File | null) => {
    if (newImage) {
      if (criandoConfig) {
        updateObjectCreate({ background: newImage });
      }

      if (editar) {
        updateObjectUpdate({ background: newImage });
        var backgroundData: string = newImage ? await loadFile(newImage) : "";
        setTheme({ ...theme, background: backgroundData });
      }
    }
  };

  const handleContentImageChange = async (newImage: File | null) => {
    if (newImage) {
      if (criandoConfig) {
        updateObjectCreate({ contentImage: newImage });
      }

      if (editar) {
        updateObjectUpdate({ contentImage: newImage });
        var contentImageData: string = newImage ? await loadFile(newImage) : "";
        setTheme({ ...theme, contentImage: contentImageData });
      }
    }
  };

  const verifyCaptadorConfigExists = async () => {
    await captadorConfigServices
      .VerifiyCaptadorConfigExists(captador.id)
      .then(async (response) => {
        if (response.data) {
          await getCaptadorConfig();
        }

        setTemConfig(response.data);

        setLoading(false);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getCaptadorConfig = async () => {
    await captadorConfigServices
      .GetCaptadorConfigByIdCaptador(captador.id)
      .then((response) => {
        setTheme(response.data);
        setObject(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    verifyCaptadorConfigExists();
  }, []);

  const loadFile = (file: File | undefined) => {
    return new Promise<string>((resolve) => {
      if (!file) {
        resolve("");
        return;
      }
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.readAsDataURL(file);
    });
  };

  const setThemeRealTimeCreate = async () => {
    var logoData: string = await loadFile(stateCreate.logo);
    var backgroundData: string = stateCreate.background
      ? await loadFile(stateCreate.background)
      : "";
    var contentImageData: string = stateCreate.contentImage
      ? await loadFile(stateCreate.contentImage)
      : "";

    setTheme({
      ...stateCreate,
      logo: logoData,
      background: backgroundData,
      contentImage: contentImageData,
      id: "",
      nome: "",
    });
  };

  const setThemeRealTimeUpdate = async () => {
    setTheme({
      ...state,
      logo: theme.logo,
      background: theme.background,
      contentImage: theme.contentImage,
    });
  };

  useEffect(() => {
    setThemeRealTimeCreate();
  }, [stateCreate]);

  useEffect(() => {
    if (editar) {
      setThemeRealTimeUpdate();
    }
  }, [state]);

  const createNewConfig = async () => {
    await captadorConfigServices
      .CreateCaptadorConfig(stateCreate)
      .then(async (response) => {
        await verifyCaptadorConfigExists();
        setCriandoConfig(false);
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Criado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const updateConfig = async () => {
    await setInsideLoading(true);
    await captadorConfigServices
      .UpdateCaptadorConfig(stateUpdate)
      .then(async () => {
        await verifyCaptadorConfigExists();

        await setInsideLoading(false);
        setEditar(false);
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Editado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch(async (e: AxiosError) => {
        await setInsideLoading(false);

        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    setObjectUpdate({
      ...state,
      logo: stateUpdate.logo,
      background: stateUpdate.background,
      contentImage: stateUpdate.contentImage,
      captadorConfigId: state.id,
    });
  }, [state]);

  return (
    <RegisterCard title="Link captação de leads">
      {loading ? (
        <C.FWStack
          alignItems={"center"}
          justifyContent={"center"}
          padding={"20px"}
        >
          <CircularProgress />
        </C.FWStack>
      ) : temConfig ? (
        <>
          <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setEditar(true)}
              disabled={editar}
            >
              Editar
            </Button>
            {editar && (
              <Button
                color="secondary"
                variant="contained"
                onClick={updateConfig}
                disabled={!editar}
              >
                {insideLoading ? <CircularProgress size={22} /> : <>Salvar</>}
              </Button>
            )}
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              value={state.celular}
              label="Celular"
              fullWidth
              inputProps={{ readOnly: !editar }}
              onChange={(e) => updateObject({ celular: e.target.value })}
            />
            <C.FWStack direction={"row"} alignItems={"center"}>
              <Checkbox
                disabled={!editar}
                checked={state.temPixelFacebook}
                onChange={() =>
                  updateObject({ temPixelFacebook: !state.temPixelFacebook })
                }
              />
              <p>Tem pixel do facebook?</p>
            </C.FWStack>
            <TextField
              value={state.pixelFacebook}
              label="Pixel facebook"
              disabled={!state.temPixelFacebook}
              fullWidth
              inputProps={{ readOnly: !editar }}
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              value={state.textoBoasVindas}
              label="Texto de boas vindas"
              fullWidth
              multiline
              rows={5}
              inputProps={{ readOnly: !editar }}
              onChange={(e) =>
                updateObject({ textoBoasVindas: e.target.value })
              }
            />
            <TextField
              value={state.textoAgradecimento}
              label="Texto de agradecimento"
              fullWidth
              multiline
              rows={5}
              inputProps={{ readOnly: !editar }}
              onChange={(e) =>
                updateObject({ textoAgradecimento: e.target.value })
              }
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              value={state.textoAnexarConta}
              label="Texto principal tela de anexar conta"
              fullWidth
              multiline
              rows={5}
              inputProps={{ readOnly: !editar }}
              onChange={(e) =>
                updateObject({ textoAnexarConta: e.target.value })
              }
            />
            <TextField
              value={state.textoAgradecimentoAnexarConta}
              label="Texto de agradecimento tela de anexar conta"
              fullWidth
              multiline
              rows={5}
              inputProps={{ readOnly: !editar }}
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <ColorInput
              readonly={!editar}
              value={state.corPrimaria}
              label="Cor Primária"
              required={true}
              onChange={(value) => updateObject({ corPrimaria: value })}
            />
            <ColorInput
              readonly={!editar}
              value={state.corSecundaria}
              label="Cor Secundária"
              required={true}
              onChange={(value) => updateObject({ corSecundaria: value })}
            />
            <ColorInput
              readonly={!editar}
              value={state.corTextoBase}
              label="Cor do Texto"
              required={true}
              onChange={(value) => updateObject({ corTextoBase: value })}
            />
            <ColorInput
              readonly={!editar}
              value={state.corTextoDestaque}
              label="Cor de Destaque do Texto"
              required={true}
              onChange={(value) => updateObject({ corTextoDestaque: value })}
            />
          </C.FWStack>
          <C.FWStack direction={"row"}>
            <h3>Logotipo</h3>
          </C.FWStack>
          <ConfigImageInput
            onImageChange={handleLogoChange}
            defaultSrc={theme.logo ?? null}
            readonly={!editar}
          />
          <C.FWStack direction={"row"}>
            <h3>Background</h3>
          </C.FWStack>
          <ConfigImageInput
            onImageChange={handleBgChange}
            defaultSrc={theme.background ?? null}
            readonly={!editar}
          />
          <C.FWStack direction={"row"} justifyContent={"flex-end"}>
            <C.FWStack direction={"row"} alignItems={"center"}>
              <Checkbox
                checked={state.temImagemComoConteudo}
                disabled={!editar}
                onChange={() =>
                  updateObject({
                    temImagemComoConteudo: !state.temImagemComoConteudo,
                  })
                }
              />
              <p>Tem imagem como conteúdo?</p>
            </C.FWStack>
          </C.FWStack>
          {state.temImagemComoConteudo && (
            <>
              <C.FWStack direction={"row"}>
                <h3>Conteudo</h3>
              </C.FWStack>
              <ConfigImageInput
                onImageChange={handleContentImageChange}
                defaultSrc={theme.contentImage ?? null}
                readonly={!editar}
              />
            </>
          )}

          <Preview theme={theme} />
        </>
      ) : (
        <>
          {criandoConfig ? (
            <>
              <C.FWStack direction={"row"} spacing={2}>
                <TextField
                  value={stateCreate.celular}
                  label="Celular"
                  required
                  fullWidth
                  onChange={(e) =>
                    updateObjectCreate({ celular: e.target.value })
                  }
                />
                <C.FWStack direction={"row"} alignItems={"center"}>
                  <Checkbox
                    checked={stateCreate.temPixelFacebook}
                    onChange={() =>
                      updateObjectCreate({
                        temPixelFacebook: !stateCreate.temPixelFacebook,
                      })
                    }
                  />
                  <p>Tem pixel do facebook?</p>
                </C.FWStack>
                <TextField
                  value={stateCreate.pixelFacebook}
                  label="Pixel facebook"
                  disabled={!stateCreate.temPixelFacebook}
                  fullWidth
                  onChange={(e) =>
                    updateObjectCreate({ pixelFacebook: e.target.value })
                  }
                />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={2}>
                <TextField
                  value={stateCreate.textoBoasVindas}
                  label="Texto de boas vindas"
                  fullWidth
                  multiline
                  rows={5}
                  onChange={(e) =>
                    updateObjectCreate({ textoBoasVindas: e.target.value })
                  }
                />
                <TextField
                  value={stateCreate.textoAgradecimento}
                  label="Texto de agradecimento"
                  fullWidth
                  required
                  multiline
                  rows={5}
                  onChange={(e) =>
                    updateObjectCreate({ textoAgradecimento: e.target.value })
                  }
                />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={2}>
                <TextField
                  value={stateCreate.textoAnexarConta}
                  label="Texto principal tela de anexar conta"
                  fullWidth
                  multiline
                  rows={5}
                  onChange={(e) =>
                    updateObjectCreate({ textoAnexarConta: e.target.value })
                  }
                />
                <TextField
                  value={stateCreate.textoAgradecimentoAnexarConta}
                  label="Texto de agradecimento tela de anexar conta"
                  fullWidth
                  required
                  multiline
                  rows={5}
                  onChange={(e) =>
                    updateObjectCreate({
                      textoAgradecimentoAnexarConta: e.target.value,
                    })
                  }
                />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={2}>
                <ColorInput
                  value={stateCreate.corPrimaria}
                  label="Cor Primária"
                  required={true}
                  onChange={(value) =>
                    updateObjectCreate({ corPrimaria: value })
                  }
                />
                <ColorInput
                  value={stateCreate.corSecundaria}
                  label="Cor Secundária"
                  required={true}
                  onChange={(value) =>
                    updateObjectCreate({ corSecundaria: value })
                  }
                />
                <ColorInput
                  value={stateCreate.corTextoBase}
                  label="Cor do Texto"
                  required={true}
                  onChange={(value) =>
                    updateObjectCreate({ corTextoBase: value })
                  }
                />
                <ColorInput
                  value={stateCreate.corTextoDestaque}
                  label="Cor de Destaque do Texto"
                  required={true}
                  onChange={(value) =>
                    updateObjectCreate({ corTextoDestaque: value })
                  }
                />
              </C.FWStack>
              <C.FWStack direction={"row"}>
                <h3>Logotipo</h3>
              </C.FWStack>
              <ConfigImageInput
                onImageChange={handleLogoChange}
                defaultSrc={null}
              />
              <C.FWStack direction={"row"}>
                <h3>Background</h3>
              </C.FWStack>
              <ConfigImageInput
                onImageChange={handleBgChange}
                defaultSrc={null}
              />
              <C.FWStack direction={"row"} justifyContent={"flex-end"}>
                <C.FWStack direction={"row"} alignItems={"center"}>
                  <Checkbox
                    checked={stateCreate.temImagemComoConteudo}
                    onChange={() =>
                      updateObjectCreate({
                        temImagemComoConteudo:
                          !stateCreate.temImagemComoConteudo,
                      })
                    }
                  />
                  <p>Tem imagem como conteúdo?</p>
                </C.FWStack>
              </C.FWStack>
              {stateCreate.temImagemComoConteudo && (
                <>
                  <C.FWStack direction={"row"}>
                    <h3>Conteudo</h3>
                  </C.FWStack>
                  <ConfigImageInput
                    onImageChange={handleContentImageChange}
                    defaultSrc={null}
                  />
                </>
              )}

              <Preview theme={theme} />

              <C.FWStack direction={"row"} justifyContent={"right"}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={createNewConfig}
                >
                  Salvar nova configuração
                </Button>
              </C.FWStack>
            </>
          ) : (
            <C.FWStack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={2}
            >
              <p>
                Você ainda não tem uma configuração de captação de leads
                personalizada. Deseja criar?
              </p>
              <Button variant="contained" onClick={handleButtonNewConfig}>
                Criar nova configuração
              </Button>
            </C.FWStack>
          )}
        </>
      )}
    </RegisterCard>
  );
};

export default ConfigCaptadorParceiro;
