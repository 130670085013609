import {TextField} from "@mui/material";
import RegisterCard from "../../../../../../../../components/RegisterCard";
import * as D from "../../../../../../../../styles/appComponents";
import React, {useReducer} from "react";
import {IformInformacoesEndereco, IFormInformacoesTitular} from "../../../../../../../../models/Contratar";

enum formInformacoesEndereco {
  CEP = "CEP",
  UF = "UF",
  CIDADE = "CIDADE",
  BAIRRO = "BAIRRO",
  LOGRADOURO = "LOGRADOURO",
  NUMERO = "NUMERO",
  COMPLEMENTO = "COMPLEMENTO"
}

type PropsEnderecoContratar = {
  type: string;
  data: IformInformacoesEndereco;
  onSave: (data: IformInformacoesEndereco) => void;
  onUpdate: (data: IformInformacoesEndereco) => void;
  onChange: (data: IformInformacoesEndereco) => void;
}

const EnderecoContratar = (props: PropsEnderecoContratar) => {
  const sendOnSave = (): void => props.onSave(state);
  const sendOnUpdate = (): void => props.onUpdate(state);
  const sendOnChange = (update: IformInformacoesEndereco): void => props.onChange(update);

  const setDataForm = (state: IformInformacoesEndereco, action: {
    type: formInformacoesEndereco;
    payload: string | number;
  }): IformInformacoesEndereco => {
    const change = {...state, [action.type.toLowerCase()]: action.payload};
    sendOnChange(change);
    return change;
  };

  const [state, dispatch] = useReducer(setDataForm, {
    cep: "",
    uf: "",
    cidade: "",
    bairro: "",
    logradouro: "",
    numero: "",
    complemento: "",
  })

  return (
    <RegisterCard title="Endereço">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField label="CEP" required fullWidth
                   onChange={(e) => dispatch({type: formInformacoesEndereco.CEP, payload: e.target.value})}/>
        <TextField label="UF" required fullWidth
                   onChange={(e) => dispatch({type: formInformacoesEndereco.UF, payload: e.target.value})}/>
        <TextField label="Cidade" required fullWidth
                   onChange={(e) => dispatch({type: formInformacoesEndereco.CIDADE, payload: e.target.value})}/>
        <TextField label="Bairro" required fullWidth
                   onChange={(e) => dispatch({type: formInformacoesEndereco.BAIRRO, payload: e.target.value})}/>
        <TextField label="Logradouro" required fullWidth
                   onChange={(e) => dispatch({type: formInformacoesEndereco.LOGRADOURO, payload: e.target.value})}/>
        <TextField label="Número" required fullWidth
                   onChange={(e) => dispatch({type: formInformacoesEndereco.NUMERO, payload: e.target.value})}/>
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2}>
        <TextField label="Complemento" required sx={{width: '50%'}}/>
      </D.FWStack>
      {props.data !== null && props.data !== undefined ? (
        <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
          <D.ContainedButton onClick={sendOnSave}>Salvar</D.ContainedButton>
        </D.FWStack>
      ) : null}
    </RegisterCard>
  );
};

export default EnderecoContratar;
