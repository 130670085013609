import {Select, Stack} from "@mui/material";
import styled from "styled-components";

export const TitleArea = styled.div`
  display: flex;
  gap: 40px;

  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
  }
`;

export const StatusArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const FWDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

export const FWStack = styled(Stack)`
  width: 100%;
`;

export const MWDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 20px;
`;

export const Subtitle = styled.h4`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
`;

export const Text = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
`;

export const BorderFWDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #6b767f;
  margin-top: 50px;
`;

export const GridTitleArea = styled.div`
  padding: 20px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 13px;

  h2 {
    margin: 0;
    font-weight: 400;
  }

  svg {
  }
`;

export const ArchiveArea = styled.div`
  border-radius: 5px;
  background: #f4f4f4;
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;

  img {
    width: 50%;
  }

  p {
    margin: 0;
    color: #262626;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const LightBWArea = styled.div`
  border-radius: 10px;
  background: #f8f8f8;
  width: 100%;
  display: flex;
  padding: 10px;
`;
export const SelectArea = styled.div`
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;

    @media (max-width: 1024px) {
        flex-basis: 48%;
    }
`;

export const ReportsLabel = styled.label`
  color: #3e3c3c;
  font-size: 14px;
  font-weight: 400;
`;


export const ReportsSelect = styled(Select)`
    width: 100%;
    text-align: start;
`;