import { TextField } from "@mui/material";
import RegisterCard from "../../../../../../components/RegisterCard";
import { ICaptador } from "../../../../../../models/Captador";
import * as C from "../../style";

interface IDadosContratoProps {
  captador: ICaptador;
}

const DadosContrato: React.FC<IDadosContratoProps> = ({ captador }) => {
  return (
    <RegisterCard title="Dados do contrato">
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          value={captador.razaoSocial}
          label="Razão social"
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={captador.cnpj}
          label="CNPJ"
          required
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={captador.cep}
          label="CEP"
          required
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={captador.uf}
          label="UF"
          required
          inputProps={{ readOnly: true }}
        />
      </C.FWStack>
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          value={captador.cidade}
          label="Cidade"
          required
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={captador.bairro}
          label="Bairro"
          required
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={captador.logradouro}
          label="Logradouro"
          required
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={captador.numero}
          label="Número"
          required
          sx={{ width: "40%" }}
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={captador.complemento}
          label="Complemento"
          required
          fullWidth
          inputProps={{ readOnly: true }}
        />
      </C.FWStack>
    </RegisterCard>
  );
};

export default DadosContrato;
