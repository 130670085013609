import {NavigateFunction, useNavigate, useParams} from "react-router-dom";
import * as C from "../style";
import * as D from "../../../../../styles/appComponents";
import InformacoesTitularContratar from "./components/cpf/InformacoesTitular";
import EnderecoContratar from "./components/cpf/Endereco";
import InformacoesContratoContratar from "./components/cpf/InformacoesContato";
import OutrasInformacoesContratar from "./components/cpf/OutrasInformacoes";
import PreferenciaContratar from "./components/cpf/preferencia";
import DadosContato from "./components/cpf/DadosContato";
import {useEffect, useState} from "react";
import Swal from "sweetalert2";
import * as services from "../../../../../services/api/PropostaService";
import {
  IformInformacoesEndereco,
  IformInformacoesInformacoesContato, IformInformacoesOutrasInformacoes,
  IFormInformacoesTitular
} from "../../../../../models/Contratar";
import {useAuth} from "../../../../../hooks/useAuth";
import InformacoesResponsavelLegal, {IInformacoesResponsavelLegal} from "./components/cnpj/informacoesResponsavelLegal";
import InformacoesProposta, {IformInformacoesProposta} from "./components/cnpj/informacoesProposta";

const Contratar = () => {
  const {user} = useAuth();
  const navigate: NavigateFunction = useNavigate()
  const {idProposta, tipo} = useParams();
  const [data, setData] = useState<object | undefined>(undefined);

  const [informacoesTitularContratar, setInformacoesTitularContratar] = useState<IFormInformacoesTitular | undefined>(undefined);
  const [enderecoContratar, setEnderecoContratar] = useState<IformInformacoesEndereco | undefined>(undefined);
  const [informacoesContratoContratar, setInformacoesContratoContratar] = useState<IformInformacoesInformacoesContato | undefined>(undefined);
  const [outrasInformacoesContratar, setOutrasInformacoesContratar] = useState<IformInformacoesOutrasInformacoes | undefined>(undefined);
  const [informacoesResponsavelLegal, setInformacoesResponsavelLegal] = useState<IInformacoesResponsavelLegal | undefined>(undefined);
  const [informacoesProposta, setInformacoesProposta] = useState<IformInformacoesProposta | undefined>(undefined);

  const getFullProposta = async (id: string): Promise<void> => {
    try {
      const [response] = await Promise.all([services.GetFullPropostaById(id)])

      setData(response.data)
    } catch (e: unknown) {
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Ocorreu um erro após tentarmos achar a proposta",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
    }
  }

  const onSaveInformacoesTitularContratar = (data: IFormInformacoesTitular): void => {
    setInformacoesTitularContratar(data);
  };

  const onSaveEnderecoContratar = (data: IformInformacoesEndereco): void => {
    setEnderecoContratar(data);
  };

  const onSaveInformacoesContratoContratar = (data: IformInformacoesInformacoesContato): void => {
    setInformacoesContratoContratar(data);
  };

  const onSaveOutrasInformacoesContratar = (data: IformInformacoesOutrasInformacoes): void => {
    setOutrasInformacoesContratar(data);
  };

  const onSaveInformacoesResponsavelLegal = (data: IInformacoesResponsavelLegal): void => {
    setInformacoesResponsavelLegal(data);
  };

  const onSaveInformacoesProposta = (data: IformInformacoesProposta): void => {
    setInformacoesProposta(data);
  };

  const handleValidForm = (form?: unknown): boolean => {
    if (!form) return true;

    const formValues: unknown[] = Object.values(form);
    const hasValue: boolean = formValues.some(value => value !== null && value !== undefined && value !== "");
    const allFilled: boolean = formValues.every(value => value !== null && value !== undefined && value !== "");

    return hasValue ? allFilled : true;
  };

  const setCooperativa = async (): Promise<void> => {
    const validFormChecks: boolean[] = [
      handleValidForm(informacoesTitularContratar),
      handleValidForm(enderecoContratar),
      handleValidForm(informacoesContratoContratar),
      handleValidForm(outrasInformacoesContratar),
      handleValidForm(informacoesResponsavelLegal),
      handleValidForm(informacoesProposta),
    ];

    // if (!validFormChecks.every(check => check)) {
    //   Swal.fire({
    //     position: "top-end",
    //     toast: true,
    //     icon: "warning",
    //     title: "Existem campos obrigatórios que não foram preenchidos",
    //     showConfirmButton: false,
    //     showCloseButton: true,
    //     timer: 3000,
    //   });
    //   return;
    // }

    // const contramo = convertToIContrato(
    //   informacoesTitularContratar,
    //   enderecoContratar,
    //   informacoesContratoContratar,
    //   outrasInformacoesContratar,
    //   informacoesResponsavelLegal,
    //   informacoesProposta,
    // );
    //
    // const response: any = await services.Create(contramo);
    //
    // if (!response) {
    //   Swal.fire({
    //     position: "top-end",
    //     toast: true,
    //     icon: "error",
    //     title: "Erro ao cadastrar cooperativa",
    //     showConfirmButton: false,
    //     showCloseButton: true,
    //     timer: 3000,
    //   });
    //   return;
    // }

    Swal.fire({
      position: "top-end",
      toast: true,
      icon: "success",
      title: "Cooperativa cadastrada com sucesso!",
      showConfirmButton: false,
      showCloseButton: true,
      timer: 3000,
    });
    navigate(-1);
  };

  useEffect((): void => {
    if (!["Fisica", "Juridica"].includes(tipo as string)) {
      navigate(-1)
    }

    getFullProposta(idProposta as string);
  }, [tipo]);

  return (
    <C.Container>
      {
        tipo === "Fisica" ? (
          <>
            <InformacoesTitularContratar
              type={tipo as string}
              data={data as IFormInformacoesTitular}
              onChange={onSaveInformacoesTitularContratar}
              onSave={onSaveInformacoesTitularContratar}
              onUpdate={onSaveInformacoesTitularContratar}
            />
            <EnderecoContratar
              type={tipo as string}
              data={data as IformInformacoesEndereco}
              onChange={onSaveEnderecoContratar}
              onSave={onSaveEnderecoContratar}
              onUpdate={onSaveEnderecoContratar}
            />
            <InformacoesContratoContratar
              type={tipo as string}
              data={data as IformInformacoesInformacoesContato}
              onChange={onSaveInformacoesContratoContratar}
              onSave={onSaveInformacoesContratoContratar}
              onUpdate={onSaveInformacoesContratoContratar}
            />
            <OutrasInformacoesContratar
              type={tipo as string}
              data={data as IformInformacoesOutrasInformacoes}
              onChange={onSaveOutrasInformacoesContratar}
              onSave={onSaveOutrasInformacoesContratar}
              onUpdate={onSaveOutrasInformacoesContratar}
            />
            {
              data != undefined ? (
                <>
                  <PreferenciaContratar type={tipo as string}/>
                  <DadosContato type={tipo as string}/>
                </>
              ) : (null)
            }
          </>
        ) : (
          <>
            <InformacoesContratoContratar
              type={tipo as string}
              data={data as IformInformacoesInformacoesContato}
              onChange={onSaveInformacoesContratoContratar}
              onSave={onSaveInformacoesContratoContratar}
              onUpdate={onSaveInformacoesContratoContratar}
            />
            <EnderecoContratar
              type={tipo as string}
              data={data as IformInformacoesEndereco}
              onChange={onSaveEnderecoContratar}
              onSave={onSaveEnderecoContratar}
              onUpdate={onSaveEnderecoContratar}
            />
            <InformacoesResponsavelLegal
              type={tipo as string}
              data={data as IInformacoesResponsavelLegal}
              onChange={onSaveInformacoesResponsavelLegal}
              onSave={onSaveInformacoesResponsavelLegal}
              onUpdate={onSaveInformacoesResponsavelLegal}
            />
            <InformacoesProposta
              type={tipo as string}
              data={data as IformInformacoesProposta}
              onChange={onSaveInformacoesProposta}
              onSave={onSaveInformacoesProposta}
              onUpdate={onSaveInformacoesProposta}
            />
            {
              data != undefined ? (
                <>
                  <OutrasInformacoesContratar
                    type={tipo as string}
                    data={data as IformInformacoesOutrasInformacoes}
                    onChange={onSaveOutrasInformacoesContratar}
                    onSave={onSaveOutrasInformacoesContratar}
                    onUpdate={onSaveOutrasInformacoesContratar}
                  />
                  <PreferenciaContratar
                    type={tipo as string}
                  />
                </>
              ) : (null)
            }
          </>
        )
      }
      <D.FWStack justifyContent={"flex-end"} direction={"row"} spacing={2}>
        <D.ContainedButton $color="lightpink">
          Gerar contrato
        </D.ContainedButton>
      </D.FWStack>
    </C.Container>
  );
};

export default Contratar;
