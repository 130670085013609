import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GridColDef } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import File from "../../../../../../assets/Icones/arquivoAnexado.svg";
import EmptyFile from "../../../../../../assets/upload.png";
import { ICaptador } from "../../../../../../models/Captador";
import * as D from "../../../../../../styles/appComponents";
import * as C from "../../style";
import RegisterCard from "../../../../../../components/RegisterCard";

interface IAnexoLogoParceiroProps {
  captador: ICaptador;
}

const AnexoLogoParceiro: React.FC<IAnexoLogoParceiroProps> = ({ captador }) => {
  const columns2: GridColDef[] = [
    {
      field: "responsavel",
      headerName: "Responsável",
      flex: 1,
    },
    { field: "desc", headerName: "Decrição", flex: 1 },
    {
      field: "data",
      headerName: "Data Upload",
      flex: 1,
    },
    {
      field: "visualizar",
      headerName: "Visualizar",
      // flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.OutlineButton
            sx={{ fontSize: "10px" }}
            startIcon={<VisibilityIcon />}
            $color="pink"
          >
            Abrir
          </D.OutlineButton>
        </D.GridField>
      ),
    },
    {
      field: "anexo",
      headerName: "Anexo",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.OutlineButton
            sx={{ fontSize: "10px" }}
            startIcon={<DownloadIcon />}
            $color="blue"
          >
            Baixar
          </D.OutlineButton>
        </D.GridField>
      ),
    },
    {
      field: "remover",
      headerName: "Remover",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.OutlineButton
            sx={{ fontSize: "10px" }}
            startIcon={<DeleteIcon />}
            $color="red"
          >
            Deletar
          </D.OutlineButton>
        </D.GridField>
      ),
    },
  ];

  const rows2 = [
    {
      id: 1,
      responsavel: "Parceiro",
      desc: "Logo SVG",
      data: "01/01/2024",
    },
    {
      id: 2,
      responsavel: "Parceiro",
      desc: "Logo PNG",
      data: "01/01/2024",
    },
    {
      id: 3,
      responsavel: "Parceiro",
      desc: "Logo PDF",
      data: "01/01/2024",
    },
  ];
  return (
    <RegisterCard title="Anexo logo do parceiro">
      <C.FWStack direction={"row"} spacing={8}>
        <C.MWDiv>
          <C.Title>
            <FileUploadIcon />
            <h2>Upload de arquivos</h2>
          </C.Title>
          <C.FWStack direction={"row"} spacing={2}>
            <C.FWStack direction={"column"} spacing={2} textAlign={"center"}>
              <C.ArchiveArea>
                <img src={File} alt="anexado" />
                <p>SVG</p>
              </C.ArchiveArea>
              <p>Logo anexado</p>
            </C.FWStack>
            <C.FWStack direction={"column"} spacing={2} textAlign={"center"}>
              <C.ArchiveArea>
                <img src={EmptyFile} alt="anexado" />
                <p>PNG</p>
              </C.ArchiveArea>
              <p>Anexar logo</p>
            </C.FWStack>
            <C.FWStack direction={"column"} spacing={2} textAlign={"center"}>
              <C.ArchiveArea>
                <img src={EmptyFile} alt="anexado" />
                <p>PDF</p>
              </C.ArchiveArea>
              <p>Anexar logo</p>
            </C.FWStack>
          </C.FWStack>
        </C.MWDiv>
        <C.MWDiv>
          <C.LightBWArea>
            <D.DefaultTable
              disableColumnResize
              disableRowSelectionOnClick
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={rows2}
              columns={columns2}
              hideFooter
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
            />
          </C.LightBWArea>
        </C.MWDiv>
      </C.FWStack>
    </RegisterCard>
  );
};

export default AnexoLogoParceiro;
