import styled from "styled-components";
import {IconButton} from "@mui/material";

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const ModalArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  height: 80%;
  border-radius: 10px;
  background: #f8f8f8;
  padding: 20px;
  overflow-y: auto;

  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 425px) {
    padding: 10px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
    color: #222222;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const CloseButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  color: gray;
  aspect-ratio: 1/1;
`;