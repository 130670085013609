import * as C from "./style";

export const SkeletonCreationCards = () => {
  return (
    <C.Container>
      <C.SkeletonCard animation="wave" variant="rounded" />
      <C.SkeletonCard animation="wave" variant="rounded" />
    </C.Container>
  );
};

export const SkeletonCreationCard = () => {
  return <C.SkeletonCard animation="wave" variant="rounded" />;
};
