import {TextField} from "@mui/material";
import RegisterCard from "../../../../../../../../components/RegisterCard";
import * as D from "../../../../../../../../styles/appComponents";
import {useReducer} from "react";
import { IFormInformacoesContato} from "../../../../../../../../models/Contratar";

enum formInformacoesTitular {
  CELULAR = "CELULAR",
  EMAIL = "EMAIL",
  UUID = "UUID",
}

type PropsDadosContato = {
  type: string;
}

const DadosContato = (props: PropsDadosContato) => {

  const setDataForm = (state: IFormInformacoesContato, action: { type: formInformacoesTitular; payload: any; }) => {
    const {type, payload} = action;
    switch (type) {
      case formInformacoesTitular.CELULAR:
        return {
          ...state,
          celular: payload,
        }
      case formInformacoesTitular.EMAIL:
        return {
          ...state,
          email: payload,
        }
      case formInformacoesTitular.UUID:
        return {
          ...state,
          uuid: payload,
        }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(setDataForm, {
    celular: "",
    email: "",
    uuid: "",
  })


  return (
    <RegisterCard title="Dados de contato">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField label="Celular" required fullWidth
                   onChange={(e) => dispatch({type: formInformacoesTitular.CELULAR, payload: e.target.value})}/>
        <TextField label="E-mail" required fullWidth
                   onChange={(e) => dispatch({type: formInformacoesTitular.EMAIL, payload: e.target.value})}/>
        <TextField label="UUID" required fullWidth
                   onChange={(e) => dispatch({type: formInformacoesTitular.UUID, payload: e.target.value})}/>
      </D.FWStack>
    </RegisterCard>
  );
};

export default DadosContato;
