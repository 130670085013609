import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Autocomplete, MenuItem, Modal, TextField } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ContractIcon from "../../../../assets/Plataforma/contractIcon.svg";
import PourposeIcon from "../../../../assets/Plataforma/pourposeIcon.svg";
import CircleCheckbox from "../../../../components/CircleCheckBox";
import Datatable from "../../../../components/Datatable";
import HistoricoCard from "../../../../components/HistoricoCard";
import SkeletonDefaultPage from "../../../../components/SkeletonLoads/DefaultPage";
import StatusCard from "../../../../components/StatusCard";
import TotalCard from "../../../../components/TotalCard";
import * as captadorServices from "../../../../services/api/CaptadorService";
import {
  EnumStatusGDProposta,
  EnumStatusGDPropostaNumericMap,
  StatusPropostaColorMap,
} from "../../../../enums/EnumStatusGDProposta.enum";
import ExcelIcon from "../../../../assets/Plataforma/excelIcon.svg";
import {
  DefaultUpdateGDProposta,
  IGDProposta,
  IGDPropostaPaged,
  IGDPropostaRelatoriosFilters,
  IStatusDashboardProposta,
  IUpdateGDProposta,
} from "../../../../models/GDProposta";
import { ICreateLog, ILog } from "../../../../models/Log";
import * as logServices from "../../../../services/api/LogService";
import * as services from "../../../../services/api/PropostaService";
import * as D from "../../../../styles/appComponents";
import { moneyFormatter } from "../../../../utils/moneyFormatter";
import ModalContratar from "./modais/contratar";
import * as C from "./style";
import { IBackofficeListItem } from "../../../../models/Colaborador";
import { ICaptadorListItem } from "../../../../models/Captador";
import * as colaboradorServices from "../../../../services/api/ColaboradorService";

const ParceiroPropostas: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [nomeGDProposta, setNomeGDProposta] = useState<string | null>(null);
  const [celularGDProposta, setCelularGDProposta] = useState<string | null>(
    null
  );
  const [emailGDProposta, setEmailGDProposta] = useState<string | null>(null);
  const [parceiroGDProposta, setParceiroGDProposta] = useState<string | null>(
    null
  );
  const [backofficeGDProposta, setBackofficeGDProposta] = useState<
    string | null
  >(null);
  const [createdAtGDProposta, setCreatedAtGDProposta] = useState<string | null>(
    null
  );
  const [editar, setEditar] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [openModalSeeMore, setOpenModalSeeMore] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [selectedId, setSelectedId] = React.useState<number>(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  const [historico, setHistorico] = useState<ILog[]>([]);
  const [historicoAssunto, setHistoricoAssunto] = useState<string>("");
  const [historicoDescricao, setHistoricoDescricao] = useState<string>("");
  const [historicoAnexo, setHistoricoAnexo] = useState<File | null>(null);

  const [openModalContratar, setOpenModalContratar] = useState<boolean>(false);
  const [uuidModalContratar, setUuidModalContratar] = useState<
    number | undefined | string
  >(undefined);

  const handleSearch = (value: string) => {
    getPropostas(value);
  };

  //filters
  const [selectedBackoffice, setSelectedBackoffice] = useState<
    string | undefined
  >();
  const [selectedCaptador, setSelectedCaptador] = useState<
    string | undefined
  >();
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>();
  const [dataInicio, setDataInicio] = useState<string | undefined>();
  const [dataFim, setDataFim] = useState<string | undefined>();

  const [backoffices, setBackoffices] = useState<IBackofficeListItem[]>([]);
  const [captadores, setCaptadores] = useState<ICaptadorListItem[]>([]);

  //historico
  const handleNewHistoricoChange = (
    assunto: string,
    descricao: string,
    anexo: File | null
  ) => {
    setHistoricoAssunto(assunto);
    setHistoricoDescricao(descricao);
    setHistoricoAnexo(anexo);
  };

  const createNewLog = async () => {
    if (historicoAssunto === "") {
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Assunto não pode ser vazio.",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });

      return;
    }

    const historicoData: ICreateLog = {
      gdLeadId: null,
      gdPropostaId: GDProposta.id,
      assunto: historicoAssunto,
      descricao: historicoDescricao,
      anexo: historicoAnexo,
    };

    await logServices
      .CreateLog(historicoData)
      .then(() => {
        getGDPropostaModalData(GDProposta.id);
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Criado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getLogsByPropostaId = async (id: string) => {
    await logServices
      .GetLogsByItemId(id)
      .then((response) => {
        setHistorico(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const [statsDashboard, setStatsDashboard] = useState<
    IStatusDashboardProposta[]
  >([
    {
      status: EnumStatusGDProposta.naoTeveInteresse,
      quantidade: 0,
      totalkWh: 0,
      totalReais: 0,
    },
    {
      status: EnumStatusGDProposta.emAnalise,
      quantidade: 0,
      totalkWh: 0,
      totalReais: 0,
    },
    {
      status: EnumStatusGDProposta.contratoGerado,
      quantidade: 0,
      totalkWh: 0,
      totalReais: 0,
    },
    {
      status: EnumStatusGDProposta.reprovada,
      quantidade: 0,
      totalkWh: 0,
      totalReais: 0,
    },
    {
      status: EnumStatusGDProposta.cancelada,
      quantidade: 0,
      totalkWh: 0,
      totalReais: 0,
    },
    {
      status: EnumStatusGDProposta.aprovada,
      quantidade: 0,
      totalkWh: 0,
      totalReais: 0,
    },
  ]);

  const [GDProposta, setGDPropostaModal] = useState<IGDProposta>({
    id: "",
    idReferencial: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    cliente: "",
    celular: "",
    status: EnumStatusGDProposta.contratoGerado,
    nomeCaptador: "",
    celularCaptador: "",
    email: "",
    nomeBackoffice: "",
    valor: "",
    kwh: "",
  });

  const [rows, setRows] = useState<IGDPropostaPaged[]>([]);

  const getStats = () => {
    services
      .GetStats()
      .then(
        (response: {
          data: React.SetStateAction<IStatusDashboardProposta[]>;
        }) => {
          setStatsDashboard(response.data);
        }
      )
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getPropostas = async (search?: string) => {
    await getStats();

    services
      .GetPropostas(page, pageSize, search)
      .then((response) => {
        setRows(response.data.data);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    getPropostas();
  }, [page, pageSize, openModalSeeMore]);

  useEffect(() => {
    setNomeGDProposta(GDProposta.cliente);
    setCelularGDProposta(GDProposta.celular);
    setEmailGDProposta(GDProposta.email);
    setParceiroGDProposta(GDProposta.nomeCaptador);
    setBackofficeGDProposta(GDProposta.nomeBackoffice);
    setCreatedAtGDProposta(new Date(GDProposta.createdAt).toLocaleDateString());
    setSelectedId(Number(GDProposta.status));
  }, [GDProposta]);

  const handleChangePage = (value: number) => {
    setPage(value);
  };

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
  };

  const handleOpenModalSeeMore = async (id: string) => {
    await getGDPropostaModalData(id);
    setOpenModalSeeMore(true);
  };

  const getGDPropostaModalData = async (id: string) => {
    await services
      .GetPropostaById(id)
      .then((response) => {
        setGDPropostaModal(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
    await getLogsByPropostaId(id);
  };

  const handleCloseModalSeeMore = () => {
    setOpenModalSeeMore(false);
  };

  const handleCloseModalContratar = () => {
    setOpenModalContratar(false);
  };

  const handleSelect = (id: number) => {
    if (editar) {
      setSelectedId(id);
    }
  };

  const handleUpdateGDProposta = async () => {
    var uploadGDPropostaData: IUpdateGDProposta = {
      ...DefaultUpdateGDProposta,
      id: GDProposta.id,
      nome: nomeGDProposta,
      celular: celularGDProposta,
      email: emailGDProposta,
      status: selectedId,
    };

    await services
      .UpdateGDProposta(uploadGDPropostaData)
      .then(() => {
        getGDPropostaModalData(GDProposta.id);
        setEditar(false);
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Editado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const handleStatusOpen = () => {
    setStatusOpen(!statusOpen);
  };

  const handleOpenViewProposta = (id: string, disabled: boolean): void => {
    if (disabled) return;
    window.open("/Parceiro/Documento/Proposta/" + id, "_blank");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getBackoffices();
    getCaptadores();
  }, []);

  const getBackoffices = () => {
    colaboradorServices
      .GetBackoffices()
      .then((response) => {
        setBackoffices(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os backoffices.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getCaptadores = () => {
    captadorServices
      .GetCaptadores()
      .then((response) => {
        setCaptadores(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os backoffices.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const geraRelatorio = () => {
    var filters: IGDPropostaRelatoriosFilters = {
      status: selectedStatus,
      backofficeId: selectedBackoffice,
      captadorId: selectedCaptador,
      dataInicio: dataInicio,
      dataFim: dataFim,
    };
    services
      .GeraRelatorio(filters)
      .then(async (response) => {
        const blob = response.data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `propostas_${new Date()
          .toISOString()
          .slice(0, 19)
          .replace(/[-:T]/g, "")}.xlsx`;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os backoffices.";
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const columns: GridColDef[] = [
    {
      field: "idReferencial",
      headerName: "Id",
      renderCell: (params: GridCellParams<IGDProposta>) => (
        <D.GridField
          onClick={() => navigate(`/Parceiro/Editar/Proposta/${params.row.id}`)}
        >
          <D.LinkClick
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.row.idReferencial}
          </D.LinkClick>
        </D.GridField>
      ),
    },
    {
      field: "cliente",
      headerName: "Cliente",
      flex: 1,
      align: "center",
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "instalacao",
      headerName: "Instalação",
      flex: 1,
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "parceiro",
      headerName: "Parceiro",
      flex: 1,
      align: "center",
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.Circle color={StatusPropostaColorMap[params.value]} />
        </D.GridField>
      ),
    },
    {
      field: "proposta",
      headerName: "Proposta",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <D.GridField $alignCenter>
            <D.GridImage
              $disable={!params.row.temProposta}
              onClick={(): void =>
                handleOpenViewProposta(params.row.id, !params.row.temProposta)
              }
              src={PourposeIcon}
              alt="pourposeIcon"
            />
          </D.GridField>
        );
      },
    },
    {
      field: "contratar",
      headerName: "Contratar",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.GridImage
            $disable
            src={ContractIcon}
            alt="contractIcon"
            onClick={(): void => {
              setUuidModalContratar(params.row.id);
              setOpenModalContratar(true);
            }}
          />
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.DefaultGridButton
            onClick={() => handleOpenModalSeeMore(params.row.id)}
          >
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];

  return (
    <>
      {loading ? (
        <SkeletonDefaultPage />
      ) : (
        <C.Container>
          <C.Title onClick={() => handleStatusOpen()}>
            Status
            {isMobile && (
              <>
                {!statusOpen ? (
                  <KeyboardArrowDownRoundedIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </>
            )}
          </C.Title>

          {(!isMobile || (statusOpen && isMobile)) && (
            <C.StatusArea>
              <C.StatusWrapper>
                <StatusCard
                  color="yellow"
                  text="Não teve interesse"
                  number={statsDashboard[0].quantidade}
                />
                <TotalCard
                  color="yellow"
                  title="Valor total das propostas"
                  text={`${moneyFormatter.format(
                    statsDashboard[0].totalReais
                  )}`}
                  evolutionNumber="-8%"
                  evolution={false}
                />
                <TotalCard
                  color="yellow"
                  title="Valor total de kwh"
                  text={`${statsDashboard[0].totalkWh} kwh`}
                  evolutionNumber="+8%"
                  evolution={true}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="blue"
                  text="Em análise"
                  number={statsDashboard[1].quantidade}
                />
                <TotalCard
                  color="blue"
                  title="Valor total das propostas"
                  text={`${moneyFormatter.format(
                    statsDashboard[1].totalReais
                  )}`}
                  evolutionNumber="-8%"
                  evolution={false}
                />
                <TotalCard
                  color="blue"
                  title="Valor total de kwh"
                  text={`${statsDashboard[1].totalkWh} kwh`}
                  evolutionNumber="+8%"
                  evolution={true}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="purple"
                  text="Aprovado"
                  number={statsDashboard[5].quantidade}
                />
                <TotalCard
                  color="purple"
                  title="Valor total das propostas"
                  text={`${moneyFormatter.format(
                    statsDashboard[5].totalReais
                  )}`}
                  evolutionNumber="-8%"
                  evolution={false}
                />
                <TotalCard
                  color="purple"
                  title="Valor total de kwh"
                  text={`${statsDashboard[5].totalkWh} kwh`}
                  evolutionNumber="-8%"
                  evolution={true}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="green"
                  text="Contrato Gerado"
                  number={statsDashboard[2].quantidade}
                />
                <TotalCard
                  color="green"
                  title="Valor total das propostas"
                  text={`${moneyFormatter.format(
                    statsDashboard[2].totalReais
                  )}`}
                  evolutionNumber="-8%"
                  evolution={false}
                />
                <TotalCard
                  color="green"
                  title="Valor total de kwh"
                  text={`${statsDashboard[2].totalkWh} kwh`}
                  evolutionNumber="+8%"
                  evolution={true}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="red"
                  text="Reprovado"
                  number={statsDashboard[3].quantidade}
                />
                <TotalCard
                  color="red"
                  title="Valor total das propostas"
                  text={`${moneyFormatter.format(
                    statsDashboard[3].totalReais
                  )}`}
                  evolutionNumber="-8%"
                  evolution={false}
                />
                <TotalCard
                  color="red"
                  title="Valor total de kwh"
                  text={`${statsDashboard[3].totalkWh} kwh`}
                  evolutionNumber="+8%"
                  evolution={true}
                />
              </C.StatusWrapper>
            </C.StatusArea>
          )}

          <D.DataArea $align="right">
            <Datatable
              columns={columns}
              rows={rows}
              pageNumber={page}
              pageSize={pageSize}
              onChangePage={(e) => handleChangePage(e)}
              onChangePageSize={(e) => handleChangePageSize(e)}
              totalPages={totalPages}
              onSearch={handleSearch}
            />

            <C.LineBreak />

            <C.ReportsArea>
              <C.SelectArea>
                <C.ReportsLabel>Status de propostas</C.ReportsLabel>
                <C.ReportsSelect
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  defaultValue={""}
                  onChange={(e) => setSelectedStatus(e.target.value as string)}
                >
                  <MenuItem value="">
                    <D.DefaultColorText>Todos os status</D.DefaultColorText>
                  </MenuItem>
                  {Object.keys(EnumStatusGDProposta).map((key, index) => (
                    <MenuItem
                      key={key}
                      value={
                        EnumStatusGDPropostaNumericMap[
                          EnumStatusGDProposta[
                            key as keyof typeof EnumStatusGDProposta
                          ]
                        ]
                      }
                    >
                      {
                        EnumStatusGDProposta[
                          key as keyof typeof EnumStatusGDProposta
                        ]
                      }
                    </MenuItem>
                  ))}
                </C.ReportsSelect>
              </C.SelectArea>
              <C.SelectArea>
                <C.ReportsLabel>Selecione um captador</C.ReportsLabel>
                <Autocomplete
                  options={captadores}
                  fullWidth
                  noOptionsText="Nenhum captador encontrado"
                  getOptionLabel={(option) => option.nome}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Todos os captadores"
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Without label",
                      }}
                    />
                  )}
                  defaultValue={null}
                  onChange={(event, newValue) => {
                    setSelectedCaptador(newValue?.id);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </C.SelectArea>
              <C.SelectArea>
                <C.ReportsLabel>Selecione o backoffice</C.ReportsLabel>
                <Autocomplete
                  options={backoffices}
                  fullWidth
                  noOptionsText="Nenhum backoffice encontrado"
                  getOptionLabel={(option) => option.nome}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Todos os backoffices"
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Without label",
                      }}
                    />
                  )}
                  defaultValue={null}
                  onChange={(event, newValue) => {
                    setSelectedBackoffice(newValue?.id);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </C.SelectArea>
              <C.SelectArea>
                <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
                <D.DateTextfield
                  type="date"
                  label="Data início"
                  fullWidth
                  value={dataInicio}
                  onChange={(e) => setDataInicio(e.target.value)}
                />
              </C.SelectArea>
              <C.SelectArea>
                <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
                <D.DateTextfield
                  type="date"
                  label="Data fim"
                  fullWidth
                  value={dataFim}
                  onChange={(e) => setDataFim(e.target.value)}
                />
              </C.SelectArea>
            </C.ReportsArea>
            <C.ExportButton
              onClick={geraRelatorio}
              variant="contained"
              startIcon={
                <img
                  style={{ width: "20px", color: "white", fill: "white" }}
                  src={ExcelIcon}
                  alt="excelIcon"
                />
              }
            >
              Gerar relatório
            </C.ExportButton>
          </D.DataArea>
        </C.Container>
      )}

      <ModalContratar
        handleCloseModalContratar={handleCloseModalContratar}
        openModalContratar={openModalContratar}
        uuid={uuidModalContratar}
      />

      <Modal
        sx={{ width: "100%", zIndex: 10 }}
        open={openModalSeeMore}
        onClose={handleCloseModalSeeMore}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <C.ModalContainer>
          <C.ModalArea>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>
                  {GDProposta.cliente} - {GDProposta.idReferencial}
                </h2>
                <C.CloseButton
                  aria-label="close"
                  onClick={handleCloseModalSeeMore}
                >
                  <CloseIcon />
                </C.CloseButton>
              </C.ModalHeader>
              <h4>Status atual:</h4>
              <C.ModalStatusArea>
                <CircleCheckbox
                  id={
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.naoTeveInteresse
                    ]
                  }
                  color="yellow"
                  label="Não teve interesse"
                  selected={
                    selectedId ===
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.naoTeveInteresse
                    ]
                  }
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.emAnalise
                    ]
                  }
                  color="blue"
                  label="Em análise"
                  selected={
                    selectedId ===
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.emAnalise
                    ]
                  }
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.aprovada
                    ]
                  }
                  color="orange"
                  label="Aprovado"
                  selected={
                    selectedId ===
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.aprovada
                    ]
                  }
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.contratoGerado
                    ]
                  }
                  color="green"
                  label="Contrato Gerado"
                  selected={
                    selectedId ===
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.contratoGerado
                    ]
                  }
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.reprovada
                    ]
                  }
                  color="red"
                  label="Reprovado"
                  selected={
                    selectedId ===
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.reprovada
                    ]
                  }
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.cancelada
                    ]
                  }
                  color="purple"
                  label="Cancelado"
                  selected={
                    selectedId ===
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.cancelada
                    ]
                  }
                  onSelect={handleSelect}
                />
              </C.ModalStatusArea>
              <D.FWStack direction={"column"} spacing={2}>
                <D.FWStack direction={"row"} spacing={2}>
                  <TextField
                    label="Nome"
                    fullWidth
                    value={nomeGDProposta}
                    onChange={(e) => {
                      setNomeGDProposta(e.target.value);
                    }}
                    inputProps={{ readOnly: !editar }}
                  />
                  <TextField
                    label="Celular"
                    fullWidth
                    value={celularGDProposta}
                    onChange={(e) => {
                      setCelularGDProposta(e.target.value);
                    }}
                    inputProps={{ readOnly: !editar }}
                  />
                </D.FWStack>

                <D.FWStack direction={"row"} spacing={2}>
                  <TextField
                    label="Email"
                    fullWidth
                    value={emailGDProposta}
                    onChange={(e) => {
                      setEmailGDProposta(e.target.value);
                    }}
                    inputProps={{ readOnly: !editar }}
                  />
                  <TextField
                    fullWidth
                    label="Parceiro"
                    value={parceiroGDProposta}
                    onChange={(e) => {
                      setParceiroGDProposta(e.target.value);
                    }}
                    inputProps={{ readOnly: true }}
                  />
                </D.FWStack>

                <D.FWStack direction={"row"} spacing={2}>
                  <TextField
                    label="Backoffice"
                    fullWidth
                    value={backofficeGDProposta}
                    onChange={(e) => {
                      setBackofficeGDProposta(e.target.value);
                    }}
                    inputProps={{ readOnly: true }}
                  />
                  <TextField
                    label="Data"
                    fullWidth
                    value={createdAtGDProposta}
                    onChange={(e) => {
                      setCreatedAtGDProposta(
                        new Date(e.target.value).toLocaleDateString()
                      );
                    }}
                    inputProps={{ readOnly: true }}
                  />
                </D.FWStack>

                <D.FWStack direction={"row"} spacing={2}>
                  <TextField
                    label="Valor"
                    fullWidth
                    value={
                      GDProposta.valor
                        ? moneyFormatter.format(Number(GDProposta.valor))
                        : ""
                    }
                    inputProps={{ readOnly: true }}
                  />
                  <TextField
                    label="kWh"
                    fullWidth
                    value={GDProposta.kwh ?? ""}
                    inputProps={{ readOnly: true }}
                    InputProps={{
                      endAdornment: <>kWh</>,
                    }}
                  />
                  <D.FWStack></D.FWStack>
                  <D.FWStack></D.FWStack>
                </D.FWStack>
              </D.FWStack>
              <C.ModalButtonsArea>
                <C.ModalSaveButton
                  variant="contained"
                  disabled={editar}
                  onClick={() => setEditar(true)}
                >
                  Editar
                </C.ModalSaveButton>
                <C.ModalSaveButton
                  variant="contained"
                  disabled={!editar}
                  onClick={() => handleUpdateGDProposta()}
                >
                  Salvar
                </C.ModalSaveButton>
              </C.ModalButtonsArea>
            </C.ModalCard>
            <HistoricoCard
              tableData={historico}
              getNewHistoricData={handleNewHistoricoChange}
              saveFunction={createNewLog}
              reloadTable={() => getGDPropostaModalData(GDProposta.id)}
            />
          </C.ModalArea>
        </C.ModalContainer>
      </Modal>
    </>
  );
};

export default ParceiroPropostas;
