import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PublishIcon from "@mui/icons-material/Publish";
import {
  Button,
  CircularProgress,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {ptBR} from "@mui/x-data-grid/locales";
import {AxiosError} from "axios";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import ExcelIcon from "../../../../assets/Plataforma/excelIcon.svg";
import DocumentIcon from "../../../../assets/Plataforma/folderIcon.svg";
import Datatable from "../../../../components/Datatable";
import StatusCard from "../../../../components/StatusCard";
import {ConvenioNumberMap} from "../../../../enums/EnumConvenio.enum";
import {
  StatusContratoParceiroColorMap,
  StatusContratoParceiroNumberMap,
} from "../../../../enums/EnumStatusContratoParceiro.enum";
import {EnumTipoArquivo} from "../../../../enums/EnumTipoArquivo.enum";
import {TipoChavePixNumberMap} from "../../../../enums/EnumTipoChavePix.enum";
import {TipoDocumentoNumberMap} from "../../../../enums/EnumTipoDocumento.enum";
import {IAnexoDTO} from "../../../../models/Anexo";
import {
  IAddAssociadoAnexo,
  IAssociado,
  IAssociadoListItem,
} from "../../../../models/Associado";
import {IAssociadoStats} from "../../../../models/Stats";
import * as anexoServices from "../../../../services/api/AnexoService";
import * as services from "../../../../services/api/AssociadoService";
import * as D from "../../../../styles/appComponents";
import * as C from "./style";

const ParceiroAssociados: React.FC = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [open, setOpen] = useState(false);
  const [openDocuments, setOpenDocuments] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [stats, setStats] = useState<IAssociadoStats>({
    leads: 0,
    aguardandoAssinaturaContrato: 0,
    contratoAssinado: 0,
    aguardandoAssinaturaDistrato: 0,
    cancelado: 0,
  });
  const [associadosRows, setAssociadosRows] = useState<IAssociadoListItem[]>(
    []
  );

  const [documentDescricao, setDocumentDescricao] = useState("");
  const [documentTitulo, setDocumentTitulo] = useState("");
  const [documentTipoArquivo, setDocumentTipoArquivo] = useState("");
  const [documentIdAssociado, setDocumentIdAssociado] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [documentSave, setDocumentSave] = useState(false);

  const [rowsModal, setRowsModal] = useState<IAnexoDTO[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      documentDescricao !== "" &&
      documentTitulo !== "" &&
      documentTipoArquivo !== "" &&
      documentIdAssociado !== "" &&
      file != null
    ) {
      setDocumentSave(true);
    } else {
      setDocumentSave(false);
    }
  }, [
    documentDescricao,
    documentTitulo,
    documentTipoArquivo,
    documentIdAssociado,
    file,
  ]);

  const clearDocuments = () => {
    setDocumentDescricao("");
    setDocumentTitulo("");
    setDocumentTipoArquivo("");
    setFile(null);
  };

  const handleAnexarArquivo = async () => {
    await setDocumentLoading(true);

    var payload: IAddAssociadoAnexo = {
      descricao: documentDescricao,
      file: file!,
      idAssociado: documentIdAssociado,
      tipoArquivo: documentTipoArquivo!,
      titulo: documentTitulo,
    };

    await services
      .AddAssociadoAnexo(payload)
      .then(async (response) => {
        await getAnexosAssociados(documentIdAssociado);
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Adicionado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
        clearDocuments();
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });

    setDocumentLoading(false);
  };

  const [associadoModal, setAssociadoModal] = useState<IAssociado | null>(null);

  const handleChangePage = (value: number) => {
    setPage(value);
  };

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
  };

  const handleChangeModal = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
  };

  const handleOpen = async (id: string) => {
    await getModalData(id);
    setOpen(true);
  };

  const getAnexosAssociados = async (id: string) => {
    await services
      .GetAnexosByAssociadoId(id)
      .then((response: { data: React.SetStateAction<IAnexoDTO[]> }) => {
        setRowsModal(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const handleOpenDocuments = async (id: string) => {
    await getAnexosAssociados(id);
    setDocumentIdAssociado(id);
    await getModalData(id);
    setOpenDocuments(true);
  };

  const getModalData = async (id: string) => {
    await services
      .GetById(id)
      .then((response) => {
        setAssociadoModal(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const handleClose = async () => {
    await setAssociadoModal(null);
    setOpen(false);
  };

  const handleCloseDocuments = async () => {
    clearDocuments();
    setDocumentIdAssociado("");
    setRowsModal([]);
    await setAssociadoModal(null);
    setOpenDocuments(false);
  };

  const handleContaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      setFile(event.target.files[0]);
    }
  };

  const handleStatusOpen = () => {
    setStatusOpen(!statusOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    services
      .GetStats()
      .then((response: { data: React.SetStateAction<IAssociadoStats> }) => {
        setStats(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });

    services
      .GetAll(page, pageSize)
      .then((response) => {
        setAssociadosRows(response.data.data);
        setTotalPages(response.data.totalPages);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  }, [page, pageSize]);

  useEffect(() => {
  }, [associadoModal]);

  const downloadAnexo = async (id: string) => {
    await anexoServices
      .GetUrl(id)
      .then((response) => {
        const link = document.createElement("a");
        link.href = response.data;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const disableAnexo = async (id: string) => {
    await anexoServices
      .Disable(id)
      .then((response) => {
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const confirmaDelete = async (id: string) => {
    Swal.fire({
      title: "Tem certeza?",
      text: "Você não poderá reverter esta ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await disableAnexo(id);
        await getAnexosAssociados(documentIdAssociado);
        await Swal.fire({
          title: "Deletado!",
          text: "Seu anexo foi deletado com sucesso.",
          icon: "success",
        });
      }
    });
  };

  const columns: GridColDef[] = [
    {
      field: "responsavelLegal",
      headerName: "Responsável legal",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {field: "celular", headerName: "Celular", flex: 1},
    {
      field: "prazoContratual",
      headerName: "Prazo contratual",
      flex: 1,
      renderCell: (params) => (
        <D.GridField>
          {params.value
            ? new Date(String(params.value)).toLocaleDateString()
            : "Indeterminado"}
        </D.GridField>
      ),
    },
    {
      field: "backoffice",
      headerName: "Backoffice",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "documentos",
      headerName: "Documentos",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField
          onClick={() => handleOpenDocuments(params.row.id)}
          $alignCenter
        >
          <D.GridImage
            src={DocumentIcon}
            alt="documentoIcone"
            $disable={false}
          />
        </D.GridField>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.Circle color={StatusContratoParceiroColorMap[params.value]}/>
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.DefaultGridButton onClick={() => handleOpen(params.row.id)}>
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];

  const columnsModal: GridColDef[] = [
    {field: "nomeResponsavel", headerName: "Responsavel", flex: 1},
    {field: "titulo", headerName: "Titulo", flex: 1},
    {
      field: "createdAt",
      headerName: "Data Upload",
      flex: 1,
      renderCell: (params) => (
        <>{new Date(params.value).toLocaleDateString()}</>
      ),
    },
    {
      field: "anexo",
      headerName: "Anexo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.ModalGridDownloadButton
            startIcon={<DownloadIcon/>}
            variant="outlined"
            onClick={() => downloadAnexo(params.row.id)}
          >
            Baixar
          </D.ModalGridDownloadButton>
        </D.GridField>
      ),
    },
    {
      field: "remover",
      headerName: "Remover",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.ModalGridDeleteButton
            variant="outlined"
            startIcon={<DeleteIcon/>}
            onClick={() => confirmaDelete(params.row.id)}
          >
            Deletar
          </D.ModalGridDeleteButton>
        </D.GridField>
      ),
    },
  ];

  return (
    <>
      <C.Container>
        <C.Title onClick={() => handleStatusOpen()}>
          Status
          {isMobile && (
            <>
              {!statusOpen ? (
                <KeyboardArrowDownRoundedIcon/>
              ) : (
                <KeyboardArrowUpIcon/>
              )}
            </>
          )}
        </C.Title>

        {(!isMobile || (statusOpen && isMobile)) && (
          <C.StatusArea>
            <C.StatusWrapper>
              <StatusCard color="yellow" text="Lead" number={stats.leads}/>
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard
                color="blue"
                text="Aguardando assinatura do contrato"
                number={stats.aguardandoAssinaturaContrato}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard
                color="green"
                text="Contrato assinado"
                number={stats.contratoAssinado}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard
                color="red"
                text="Aguardando assinatura de distrato"
                number={stats.aguardandoAssinaturaDistrato}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard
                color="purple"
                text="Cancelado"
                number={stats.cancelado}
              />
            </C.StatusWrapper>
          </C.StatusArea>
        )}
        <D.DataArea $align="right">
          <D.ContainedButton
            startIcon={<AddCircleOutlineOutlinedIcon/>}
            onClick={() => navigate("/Parceiro/Novo/Associado")}
          >
            Novo associado
          </D.ContainedButton>
          <Datatable
            columns={columns}
            rows={associadosRows}
            onSearch={() => {
            }}
            pageNumber={page}
            pageSize={pageSize}
            onChangePage={(e) => handleChangePage(e)}
            onChangePageSize={(e) => handleChangePageSize(e)}
            totalPages={totalPages}
          />
          <C.LineBreak/>
          <C.ExportButton
            variant="contained"
            startIcon={
              <img
                style={{width: "20px", color: "white", fill: "white"}}
                src={ExcelIcon}
                alt="excelIcon"
              />
            }
          >
            Gerar relatório
          </C.ExportButton>
        </D.DataArea>
      </C.Container>
      <Modal
        sx={{width: "100%", zIndex: 10}}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <D.ModalContainer>
          <D.ModalArea>
            {associadoModal == null ? (
              <></>
            ) : (
              <>
                <D.ModalCard>
                  <D.ModalHeader>
                    <h2>{associadoModal.nomeAssociado}</h2>
                    <D.CloseButton aria-label="close" onClick={handleClose}>
                      <CloseIcon/>
                    </D.CloseButton>
                  </D.ModalHeader>
                  <D.ModalLine>
                    <D.ModalTextIcon>
                      <D.Circle
                        color={
                          StatusContratoParceiroColorMap[
                            Number(associadoModal.status)
                            ]
                        }
                      />
                      <p>
                        {
                          StatusContratoParceiroNumberMap[
                            Number(associadoModal.status)
                            ]
                        }
                      </p>
                    </D.ModalTextIcon>
                  </D.ModalLine>
                  <D.ModalLine>
                    <D.ModalItem>
                      <label>Nome do associado:</label>
                      <p>{associadoModal.nomeAssociado}</p>
                    </D.ModalItem>
                    <D.ModalItem>
                      <label>Celular do associado:</label>
                      <p>{associadoModal.celularAssociado}</p>
                    </D.ModalItem>
                    <D.ModalItem>
                      <label>Nome do master:</label>
                      <p>{associadoModal.nomeMaster}</p>
                    </D.ModalItem>
                    <D.ModalItem>
                      <label>Celular do master:</label>
                      <p>{associadoModal.celularMaster}</p>
                    </D.ModalItem>
                  </D.ModalLine>
                  <D.ModalLine>
                    <D.ModalItem>
                      <label>Backoffice:</label>
                      <p>{associadoModal.backoffice}</p>
                    </D.ModalItem>
                    <D.ModalItem>
                      <label>Convênio:</label>
                      <p>
                        {ConvenioNumberMap[Number(associadoModal.convenio)]}
                      </p>
                    </D.ModalItem>
                    <D.ModalItem>
                      <label>Vencimento do contrato:</label>
                      <p>
                        {associadoModal.vigenciaContrato
                          ? new Date(
                            String(associadoModal.vigenciaContrato)
                          ).toLocaleDateString()
                          : "Indeterminado"}
                      </p>
                    </D.ModalItem>
                    <D.ModalItem></D.ModalItem>
                  </D.ModalLine>
                  <D.ModalLine>
                    <D.ModalItem>
                      <label>Dados para pagamento de comissão</label>
                    </D.ModalItem>
                  </D.ModalLine>
                  {associadoModal.dadosBancario.dadosDeposito.map(
                    (item, index) => (
                      <D.ModalLine key={index}>
                        <D.ModalItem>
                          <label>Banco:</label>
                          <p>{item.banco}</p>
                        </D.ModalItem>
                        <D.ModalItem>
                          <label>Agência:</label>
                          <p>{item.agencia}</p>
                        </D.ModalItem>
                        <D.ModalItem>
                          <label>Conta:</label>
                          <p>{item.conta}</p>
                        </D.ModalItem>
                        <D.ModalItem>
                          <label>
                            {
                              TipoDocumentoNumberMap[
                                Number(item.documentoFavorecido.tipoDocumento)
                                ]
                            }
                            :
                          </label>
                          <p>{item.documentoFavorecido.numero}</p>
                        </D.ModalItem>
                        <D.ModalItem>
                          <label>Favorecido:</label>
                          <p>{item.favorecido}</p>
                        </D.ModalItem>
                      </D.ModalLine>
                    )
                  )}
                  {associadoModal.dadosBancario.dadosPix.map((item, index) => (
                    <D.ModalLine key={index}>
                      <D.ModalItem>
                        <label>Pix:</label>
                        <p>
                          {TipoChavePixNumberMap[Number(item.tipoChavePix)]}
                        </p>
                      </D.ModalItem>
                      <D.ModalItem>
                        <label>Chave:</label>
                        <p>{item.chavePix}</p>
                      </D.ModalItem>
                      <D.ModalItem></D.ModalItem>
                      <D.ModalItem></D.ModalItem>
                      <D.ModalItem></D.ModalItem>
                    </D.ModalLine>
                  ))}
                </D.ModalCard>
              </>
            )}
          </D.ModalArea>
        </D.ModalContainer>
      </Modal>
      <Modal
        sx={{width: "100%", zIndex: 10}}
        open={openDocuments}
        onClose={handleCloseDocuments}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <D.ModalContainer>
          <D.ModalArea>
            {associadoModal == null ? (
              <></>
            ) : (
              <>
                <D.ModalCard>
                  <D.ModalHeader>
                    <h2>{associadoModal.nomeAssociado}</h2>
                    <D.CloseButton
                      aria-label="close"
                      onClick={handleCloseDocuments}
                    >
                      <CloseIcon/>
                    </D.CloseButton>
                  </D.ModalHeader>
                  <D.ModalLine>
                    <D.ModalItem>
                      <label>Nome do associado:</label>
                      <p>{associadoModal.nomeAssociado}</p>
                    </D.ModalItem>
                    <D.ModalItem>
                      <label>Celular do associado:</label>
                      <p>{associadoModal.celularAssociado}</p>
                    </D.ModalItem>
                    <D.ModalItem>
                      <label>Status:</label>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={2}
                      >
                        <D.Circle
                          color={
                            StatusContratoParceiroColorMap[
                              Number(associadoModal.status)
                              ]
                          }
                        />
                        <p>
                          {
                            StatusContratoParceiroNumberMap[
                              Number(associadoModal.status)
                              ]
                          }
                        </p>
                      </Stack>
                    </D.ModalItem>
                  </D.ModalLine>
                  <D.ModalLine>
                    <D.ModalItem>
                      <label>Nome do master:</label>
                      <p>{associadoModal.nomeMaster}</p>
                    </D.ModalItem>
                    <D.ModalItem>
                      <label>Celular do master:</label>
                      <p>{associadoModal.celularMaster}</p>
                    </D.ModalItem>
                    <D.ModalItem></D.ModalItem>
                  </D.ModalLine>
                  <D.ModalLine></D.ModalLine>
                </D.ModalCard>
                <D.ModalCard>
                  <D.ModalHeader>
                    <Stack alignItems={"center"} direction={"row"} spacing={2}>
                      <PublishIcon/>
                      <h2>Upload de Arquivos</h2>
                    </Stack>
                  </D.ModalHeader>
                  <TextField
                    label="Assunto"
                    fullWidth
                    onChange={(e) => setDocumentTitulo(e.target.value)}
                    value={documentTitulo}
                  />
                  <TextField
                    select
                    label="Tipo de Documento"
                    fullWidth
                    value={documentTipoArquivo}
                    onChange={(e) => setDocumentTipoArquivo(e.target.value)}
                  >
                    {Object.keys(EnumTipoArquivo).map((key, index) => (
                      <MenuItem key={index} value={index}>
                        {EnumTipoArquivo[key as keyof typeof EnumTipoArquivo]}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    label="Descrição do documento da proposta"
                    fullWidth
                    multiline
                    rows={5}
                    onChange={(e) => setDocumentDescricao(e.target.value)}
                    value={documentDescricao}
                  />
                  <Stack direction="row" justifyContent={"space-between"}>
                    <div>
                      <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        startIcon={<PublishIcon/>}
                      >
                        Anexar Documento
                        <D.VisuallyHiddenInput
                          type="file"
                          onChange={handleContaChange}
                        />
                      </Button>
                      <p>{file == null ? "Nenhum selecionado" : file.name}</p>
                    </div>
                    <div>
                      <D.ModalSaveButton
                        variant="contained"
                        disabled={!documentSave}
                        onClick={handleAnexarArquivo}
                      >
                        {documentLoading ? (
                          <CircularProgress size={25}/>
                        ) : (
                          <>Salvar</>
                        )}
                      </D.ModalSaveButton>
                    </div>
                  </Stack>
                </D.ModalCard>
                <D.ModalCard>
                  <D.ModalHeader>
                    <h2>Histórico de Arquivos</h2>
                  </D.ModalHeader>

                  <DataGrid
                    localeText={
                      ptBR.components.MuiDataGrid.defaultProps.localeText
                    }
                    columns={columnsModal}
                    rows={rowsModal}
                  />
                </D.ModalCard>
              </>
            )}
          </D.ModalArea>
        </D.ModalContainer>
      </Modal>
    </>
  );
};

export default ParceiroAssociados;
