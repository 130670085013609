import styled from "styled-components";
import {Button, TextField} from "@mui/material";

export const CustomInput = styled(TextField)`
  flex: 1;
  flex-basis: 49%;
`;

export const ContainedButton = styled(Button)<{
  $color?: "blue" | "pink" | "lightpink" | "yellow";
}>`
  background-color: ${({ $color }) => {
  switch ($color) {
    case "blue":
      return "#144FE7";
    case "pink":
      return "#D24293";
    case "lightpink":
      return "#F36A7B";
    case "yellow":
      return "#F6A648";
    default:
      return "#d24293";
  }
}};
  color: white;
  padding: 5px 20px;
  min-height: 40px;
    flex: 1;
    flex-basis: 49%;

  &:hover {
    background-color: ${({ $color }) => {
  switch ($color) {
    case "blue":
      return "#144FE7";
    case "pink":
      return "#a33473";
    case "lightpink":
      return "#c95967";
    case "yellow":
      return "#cf8a3a";
    default:
      return "#a33473";
  }
}};
  }
`;
