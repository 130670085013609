import { ColorType } from "../types/color";

export enum EnumStatusContratoParceiro {
  lead = "Lead",
  aguardandoAssinatura = "Aguardando Assinatura",
  contratoAssinado = "Contrato Assinado",
  aguardandoAssinaturaDistrato = "Aguardando Assinatura Distrato",
  cancelado = "Cancelado",
}

export const StatusContratoParceiroNumberMap: {
  [key: number]: EnumStatusContratoParceiro;
} = {
  0: EnumStatusContratoParceiro.lead,
  1: EnumStatusContratoParceiro.aguardandoAssinatura,
  2: EnumStatusContratoParceiro.contratoAssinado,
  3: EnumStatusContratoParceiro.aguardandoAssinaturaDistrato,
  4: EnumStatusContratoParceiro.cancelado,
};

export const StatusContratoParceiroColorMap: Record<number, ColorType> = {
  0: "yellow",
  1: "blue",
  2: "green",
  3: "red",
  4: "purple",
};
