import { Stack } from "@mui/material";
import styled from "styled-components";
import { Dispositivo } from "./Preview";

export const Container = styled.div<{ $dispositivo: Dispositivo }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Navbar = styled.div<{ $dispositivo: Dispositivo }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: ${(props) => props.theme.corPrimaria};
  padding: ${(props) =>
    props.$dispositivo == "Desktop" ? "10px 60px" : "10px 20px"};

  h1 {
    margin: 0;
  }

  img {
    width: auto;
    height: 100%;
  }
`;

export const Content = styled.div<{
  $bgUrl: string | null;
  $dispositivo: Dispositivo;
}>`
  width: 100%;
  height: ${(props) =>
    props.$dispositivo == "Desktop" ? "calc(100% - 70px)" : "100%"};
  background: ${(props) =>
    props.$bgUrl
      ? `url(${props.$bgUrl}) no-repeat`
      : `${props.theme.corSecundaria}`};
  background-size: cover;
  display: flex;
  flex-direction: ${(props) =>
    props.$dispositivo == "Desktop" ? "row" : "column"};
`;

export const Left = styled.div<{
  $temImagemComoConteudo: boolean;
  $dispositivo: Dispositivo;
}>`
  width: ${(props) => (props.$dispositivo == "Desktop" ? "50%" : "100%")};
  height: 100%;
  padding: ${(props) => (props.$temImagemComoConteudo ? "30px" : "0 50px")};

  display: ${(props) => (props.$temImagemComoConteudo ? "flex" : "block")};
  align-items: center;
  justify-content: center;
  min-height: ${(props) => (props.$temImagemComoConteudo ? "0" : "300px")};
`;

export const ContentImage = styled.img`
  max-width: 100%;
`;

export const Right = styled.div<{
  $dispositivo: Dispositivo;
}>`
  width: ${(props) => (props.$dispositivo === "Desktop" ? "50%" : "100%")};
  height: ${(props) => (props.$dispositivo == "Desktop" ? "100%" : "auto")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormArea = styled.form<{
  $dispositivo: Dispositivo;
}>`
  width: ${(props) => (props.$dispositivo === "Desktop" ? "90%" : "100%")};
  max-height: ${(props) => (props.$dispositivo === "Desktop" ? "90%" : "none")};
  border-radius: ${(props) =>
    props.$dispositivo === "Desktop" ? "10px" : "0"};
  padding: ${(props) =>
    props.$dispositivo === "Desktop" ? "30px 50px" : "30px"};
  background-color: ${(props) => props.theme.corPrimaria};
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
`;

export const FormTitle = styled.h1<{
  $dispositivo: Dispositivo;
}>`
  color: ${(props) => props.theme.corTextoBase};
  text-align: center;
  font-size: ${(props) => (props.$dispositivo === "Desktop" ? "24px" : "20px")};
  font-weight: 500;
  line-height: 100%;
  margin: 0;
`;

export const FormSubtitle = styled.p<{
  $dispositivo: Dispositivo;
}>`
  color: ${(props) => props.theme.corTextoBase};
  text-align: center;
  font-size: ${(props) => (props.$dispositivo === "Desktop" ? "18px" : "14px")};
  font-weight: 300;
  line-height: 100%;
  margin: 0;
`;

export const FWStack = styled(Stack)`
  width: 100%;
`;

export const InputArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    color: ${(props) => props.theme.corTextoBase};
  }
`;

export const VisuallyHiddenInput = styled.input`
  display: none;
`;

export const BillsArea = styled(Stack)<{ $dispositivo: Dispositivo }>`
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    font-weight: 700;
    font-size: ${(props) =>
      props.$dispositivo === "Desktop" ? "20px" : "12px"};
    margin: 0;
    color: #1c1c1c;
    word-break: break-all;
  }
`;

export const Text = styled.p<{
  $dispositivo: Dispositivo;
}>`
  color: ${(props) => props.theme.corTextoBase};
  font-size: ${(props) => (props.$dispositivo === "Desktop" ? "46px" : "20px")};
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
`;

export const MarkedText = styled.span`
  color: ${(props) => props.theme.corTextoDestaque};
`;

export const Wpp = styled.a`
  display: flex;
  align-items: center;
  font-size: 24px !important;
  text-decoration: none;
  cursor: pointer;
  gap: 10px;

  &:hover {
    text-decoration: none;
    transform: scale(102%);
  }

  svg {
    color: ${(props) => props.theme.corTextoBase};
  }
`;

export const WppNumber = styled.h4<{ $dispositivo: Dispositivo }>`
  color: ${(props) => props.theme.corTextoBase};
  margin: 0;
  display: ${(props) => (props.$dispositivo === "Desktop" ? "block" : "none")};
`;

export const DDD = styled.span`
  font-size: 20px;
`;
