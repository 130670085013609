import * as C from "../../../Cliente/Cadastro/style";
import { TextField } from "@mui/material";
import RegisterCard from "../../../../../../components/RegisterCard";
import * as D from "../../../../../../styles/appComponents";
import React, { useReducer } from "react";

enum FormResponsavelOemg {
  RESPONSAVELOCEMG = "RESPONSAVELOCEMG",
  EMAILRESPONSAVELOCEMG = "EMAILRESPONSAVELOCEMG",
  CELULARRESPONSAVELOCEMG = "CELULARRESPONSAVELOCEMG",
}

export interface IFormResponsavelOemg {
  responsavelocemg: string;
  emailresponsavelocemg: string;
  celularresponsavelocemg: string;
}

type PropsResponsavelOemg = {
  onSave: (data: IFormResponsavelOemg) => void;
  onUpdate: (data: IFormResponsavelOemg) => void;
  onChange: (data: IFormResponsavelOemg) => void;
  data?: object | null;
};

export const ResponsavelOemg = (props: PropsResponsavelOemg) => {
  const sendOnSave = (): void => props.onSave(state);
  const sendOnUpdate = (): void => props.onUpdate(state);
  const sendOnChange = (update: IFormResponsavelOemg): void => props.onChange(update);

  const setDataForm = (state: IFormResponsavelOemg, action: {
    type: FormResponsavelOemg;
    payload: string;
  }): IFormResponsavelOemg => {
    const change = { ...state, [action.type.toLowerCase()]: action.payload };
    sendOnChange(change);
    return change;
  };

  const [state, dispatch] = useReducer(setDataForm, {
    responsavelocemg: "",
    emailresponsavelocemg: "",
    celularresponsavelocemg: "",
  });

  const handleInputChange = (field: FormResponsavelOemg) => (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: field, payload: event.target.value });
  };

  return (
    <RegisterCard title="Responsável Oemg">
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Nome"
          required
          fullWidth
          onChange={handleInputChange(FormResponsavelOemg.RESPONSAVELOCEMG)}
        />
        <TextField
          label="Email"
          required
          fullWidth
          onChange={handleInputChange(FormResponsavelOemg.EMAILRESPONSAVELOCEMG)}
        />
        <TextField
          label="Celular"
          required
          fullWidth
          onChange={handleInputChange(FormResponsavelOemg.CELULARRESPONSAVELOCEMG)}
        />
      </C.FWStack>

      {props.data !== null && props.data !== undefined ? (
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
          <D.ContainedButton onClick={sendOnSave}>Salvar</D.ContainedButton>
        </C.FWStack>
      ) : null}
    </RegisterCard>
  );
};
