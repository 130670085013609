import styled from "styled-components";
import { Skeleton } from "@mui/material";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  @media (max-width: 1024px) {
  }
`;

export const SkeletonCard = styled(Skeleton)`
  height: 300px;
  width: 100%;
  border-radius: 20px;

  @media (max-width: 1024px) {
  }
`;
