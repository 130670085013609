import {MenuItem, TextField} from "@mui/material";
import RegisterCard from "../../../../../../../../components/RegisterCard";
import * as D from "../../../../../../../../styles/appComponents";
import {EnumEstadoCivil} from "../../../../../../../../enums/EnumEstadoCivil.enum";
import React, {useEffect, useReducer} from "react";
import {IFormInformacoesTitular} from "../../../../../../../../models/Contratar";
import {EnumTipoGDContrato} from "../../../../../../../../enums/EnumTipoGDContrato";

enum formInformacoesResponsavelLegal {
  NOME = "Nome",
  EMAIL = "EMAIL",
  CELULAR = "CELULAR",
  CPF = "CPF",
  ESTADO_CIVIL = "ESTADO_CIVIL",
  NACIONALIDADE = "NACIONALIDADE",
  PROFISSAO = "PROFISSAO",
}

export interface IInformacoesResponsavelLegal {
  nome: string,
  email: string,
  celular: string,
  cpf: string,
  estado_civil: string,
  nacionalidade: string,
  profissao: string,
}

type PropsInformacoesResponsavelLegal = {
  type: string;
  data?: IInformacoesResponsavelLegal;
  onSave: (data: IInformacoesResponsavelLegal) => void;
  onUpdate: (data: IInformacoesResponsavelLegal) => void;
  onChange: (data: IInformacoesResponsavelLegal) => void;
}

const InformacoesResponsavelLegal = (props: PropsInformacoesResponsavelLegal) => {
  const sendOnSave = (): void => props.onSave(state);
  const sendOnUpdate = (): void => props.onUpdate(state);
  const sendOnChange = (update: IInformacoesResponsavelLegal): void => props.onChange(update);

  const {type, data} = props;

  const setDataForm = (state: IInformacoesResponsavelLegal, action: {
    type: formInformacoesResponsavelLegal;
    payload: string | number;
  }): IInformacoesResponsavelLegal => {
    const change = {...state, [action.type.toLowerCase()]: action.payload};
    sendOnChange(change);
    return change;
  };

  const setData = (data: IFormInformacoesTitular): void => {
    dispatch({type: formInformacoesResponsavelLegal.NOME, payload: data.nome});
    dispatch({type: formInformacoesResponsavelLegal.CPF, payload: data.cpf});
  }

  const [state, dispatch] = useReducer(setDataForm, {
    nome: "",
    email: "",
    celular: "",
    cpf: "",
    estado_civil: "",
    nacionalidade: "",
    profissao: "",
  })

  useEffect((): void => {
    if (data) {
      setData(data);
    }
  }, [data]);

  return (
    <RegisterCard title="Informações do responsável legal">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField label="Nome do responsável legal" required fullWidth value={state.nome}
                   onChange={(e) => dispatch({type: formInformacoesResponsavelLegal.NOME, payload: e.target.value})}/>
        <TextField label="Email" required fullWidth value={state.email}
                   onChange={(e) => dispatch({type: formInformacoesResponsavelLegal.EMAIL, payload: e.target.value})}/>
        <TextField label="Celular" required fullWidth value={state.celular}
                   onChange={(e) => dispatch({
                     type: formInformacoesResponsavelLegal.CELULAR,
                     payload: e.target.value
                   })}/>
        <TextField label="CPF" required fullWidth
                   onChange={(e) => dispatch({type: formInformacoesResponsavelLegal.CPF, payload: e.target.value})}/>
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2}>
        <TextField label="Estado Civil" required fullWidth select defaultValue={""}
                   onChange={(e) => dispatch({
                     type: formInformacoesResponsavelLegal.ESTADO_CIVIL,
                     payload: e.target.value
                   })}>
          {Object.values(EnumEstadoCivil).map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField label="Nacionalidade" required fullWidth
                   onChange={(e) => dispatch({
                     type: formInformacoesResponsavelLegal.NACIONALIDADE,
                     payload: e.target.value
                   })}/>
        <TextField label="Profissão" required fullWidth
                   onChange={(e) => dispatch({
                     type: formInformacoesResponsavelLegal.PROFISSAO,
                     payload: e.target.value
                   })}/>
      </D.FWStack>
      {props.data !== null && props.data !== undefined ? (
        <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
          <D.ContainedButton onClick={sendOnSave}>Salvar</D.ContainedButton>
        </D.FWStack>
      ) : null}
    </RegisterCard>
  );
};

export default InformacoesResponsavelLegal;
