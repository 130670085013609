import { useReducer } from "react";

// Define the actions
type Action<T> =
  | { type: "SET"; payload: T }
  | { type: "UPDATE"; payload: Partial<T> }
  | { type: "RESET" };

// Create a reducer function
function objectReducer<T>(state: T, action: Action<T>): T {
  switch (action.type) {
    case "SET":
      return action.payload;
    case "UPDATE":
      return { ...state, ...action.payload };
    case "RESET":
      return {} as T;
    default:
      return state;
  }
}

// Hook to manage the object state
export function useObjectState<T>(initialState: T) {
  const [state, dispatch] = useReducer(objectReducer<T>, initialState);

  const setObject = (obj: T) => dispatch({ type: "SET", payload: obj });
  const updateObject = (partialObj: Partial<T>) =>
    dispatch({ type: "UPDATE", payload: partialObj });
  const resetObject = () => dispatch({ type: "RESET" });

  return {
    state,
    setObject,
    updateObject,
    resetObject,
  };
}
