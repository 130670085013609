import { IPaginatedList } from "../../models/PaginatedList";
import {
  ICreateGDProposta,
  IFullGDProposta,
  IGDProposta,
  IGDPropostaPaged,
  IGDPropostaRelatoriosFilters,
  IGetCalculoParams,
  IGetCalculoResponse,
  IPDFPropostaResponse,
  IStatusDashboardProposta,
  IUpdateGDProposta,
} from "../../models/GDProposta";
import http from "../common/http-common";

export const GetStats = () => {
  return http.get<IStatusDashboardProposta[]>(`/GDProposta/StatsDashboard`);
};

export const GetPropostas = (
  pageNumber: number,
  pageSize: number,
  search?: string
) => {
  var searchString: string = "";

  if (search) {
    searchString = `&Search=${search}`;
  }

  return http.get<IPaginatedList<IGDPropostaPaged>>(
    `/GDProposta?pageNumber=${pageNumber}&pageSize=${pageSize}${searchString}`
  );
};

export const GetPropostaById = (id: string) => {
  return http.get<IGDProposta>(`/GDProposta/${id}`);
};

export const GetFullPropostaById = (id: string) => {
  return http.get<IFullGDProposta>(`/GDProposta/GetFullGDPropostaById/${id}`);
};

export const UpdateGDProposta = (updateGDProposta: IUpdateGDProposta) => {
  return http.put(`/GDProposta`, updateGDProposta);
};

export const CreateGDProposta = (createGDProposta: ICreateGDProposta) => {
  return http.post<IGDProposta>(`/GDProposta`, createGDProposta);
};

export const GetPropostaPDFWattwise = (id: string) => {
  return http.get<IPDFPropostaResponse>(`/GDProposta/GetDadosToPdf/${id}`);
};

export const GetCalculoGDProposta = (getCalculoData: IGetCalculoParams) => {
  return http.get<IGetCalculoResponse>(
    `/GDProposta/GetCalculoGDProposta?TipoConexao=${getCalculoData.tipoConexao}&ValorConta=${getCalculoData.valorConta}&IluminacaoPublica=${getCalculoData.iluminacaoPublica}&ConsumoMensalkWh=${getCalculoData.consumoMensalkWh}&TarifaDistribuidora=${getCalculoData.tarifaDistribuidora}&PisConfins=${getCalculoData.pisConfins}&PercentualDesconto=${getCalculoData.percentualDesconto}`
  );
};

export const Send2Email = (formData: FormData) => {
  return http.post<void>("/GDProposta/SendEmail", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const Send2Whatsapp = (propostaId: string, numeroCelular: string) => {
  return http.get<string>(
    "/GDProposta/GetLinkEnvioGDProposta?PropostaId=" +
      propostaId +
      "&NumeroWhatsapp=" +
      numeroCelular
  );
};

export const GeraRelatorio = (filters: IGDPropostaRelatoriosFilters) => {
  const queryParams: string[] = [];

  if (filters.status !== undefined) {
    queryParams.push(`EnumStatusGDProposta=${filters.status}`);
  }

  if (filters.captadorId) {
    queryParams.push(`CaptadorId=${filters.captadorId}`);
  }

  if (filters.backofficeId) {
    queryParams.push(`BackofficeId=${filters.backofficeId}`);
  }

  if (filters.dataInicio) {
    queryParams.push(`DataInicio=${filters.dataInicio}`);
  }

  if (filters.dataFim) {
    queryParams.push(`DataFim=${filters.dataFim}`);
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

  return http.get<File>(`/GDProposta/BuscaRelatorioPropostas${queryString}`, {
    responseType: "blob",
  });
};
