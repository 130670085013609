import { TextField } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import RegisterCard from "../../../../../../components/RegisterCard";
import * as C from "../../style";
import { ptBR } from "@mui/x-data-grid/locales";
import * as D from "../../../../../../styles/appComponents";
import { EnumTipoParceiro } from "../../../../../../enums/EnumTipoParceiro.enum";
import { EnumConvenio } from "../../../../../../enums/EnumConvenio.enum";
import { ICaptador } from "../../../../../../models/Captador";

interface IInformacoesParceiroProps {
  captador: ICaptador;
}

//TODO: CORRIGIR ESSA PAGINA INTEIRA

const InformacoesParceiro: React.FC<IInformacoesParceiroProps> = ({
  captador,
}) => {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
    },
    { field: "comissao", headerName: "% da comissão", width: 150 },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    { field: "telefone", headerName: "Telefone", width: 150 },
    {
      field: "status",
      headerName: "Status do contrato",
      flex: 1,
    },
  ];

  return (
    <RegisterCard title="Informações do parceiro">
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          value={Object.values(EnumTipoParceiro)[Number(0)]}
          label="Tipo de parceiro"
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          disabled={
            Object.values(EnumTipoParceiro)[Number(0)] ===
            EnumTipoParceiro.parceiroMaster
          }
          label="Parceiro master"
          inputProps={{ readOnly: true }}
          fullWidth
        />
        <TextField
          value={Object.values(EnumConvenio)[Number(0)]}
          label="Convênio"
          inputProps={{ readOnly: true }}
          fullWidth
        />
        <TextField
          label="Backoffice"
          inputProps={{ readOnly: true }}
          fullWidth
        />
      </C.FWStack>
      {/* <C.BorderFWDiv>
        <C.GridTitleArea>
          <C.Subtitle>Associados</C.Subtitle>
        </C.GridTitleArea>
        <C.GridTitleArea>
          <p>Não há associados.</p>
        </C.GridTitleArea>

        {/*
        {captador.associado.length > 0 ? (
          <D.DefaultTable
            disableColumnResize
            disableRowSelectionOnClick
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={captador.associado}
            columns={columns}
            hideFooter
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
          />
        ) : (
          <C.GridTitleArea>
            <p>Não há associados.</p>
          </C.GridTitleArea>
        )}
        
      </C.BorderFWDiv>*/}
    </RegisterCard>
  );
};

export default InformacoesParceiro;
